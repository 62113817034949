import React, {useState} from 'react';
import {useFormik} from 'formik';
import {setForgottenPassword} from './forgot-password';
import Header from 'components/stateless/header';
import {defaultMenu} from 'components/stateless/header-items';
import InputComponent from 'components/form/input';
import classNames from 'classnames';
import ButtonComponent from 'components/button';
import Spinner from 'react-bootstrap/Spinner';
import {TimesCircleFilled} from 'icons';
import AlertComponent from 'utils/alert';
import {sendAuthorizationRequest} from 'components/login/sign-in';

const defaultEvent = {
    type: '',
    icon: null,
    text: null,
};

const SetForgottenPasswordComponent = () => {
    const [asyncInProgress, setAsyncInProgress] = useState(false);
    const token = new URL(window.location.href).searchParams.get('token') || '';
    const [event, setEvent] = useState(defaultEvent);
    const [recommendation, toggleRecommendation] = useState(false);
    const env = process.env.REACT_APP_NODE_API;
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
    } = useFormik({
        initialValues: setForgottenPassword.schema,
        validationSchema: setForgottenPassword.validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            sendPassword(values);
        },
    });

    const sendPassword = values => {
        values.token = token;
        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        };

        setAsyncInProgress(true);

        fetch(`${env}/api/alliance/user/changePassword`, options)
            .then(response => Promise.all([response.ok, response.json()]))
            .then(([ok, json]) => {
                if (ok) {
                    setAsyncInProgress(false);
                    sendAuthorizationRequest();
                } else {
                    setAsyncInProgress(false);
                    setEvent({
                        type: 'ynq-alert--error',
                        icon: <TimesCircleFilled/>,
                        text: json.errors[0].message,
                    });
                    throw new Error(json.errors[0].message);
                }
            })
            .catch(() => {
                setAsyncInProgress(false);
            });
    };

    const dismiss = () => {
        setEvent({
            type: '',
            icon: null,
            text: null,
        });
    };

    return (
        <div className="ynq-password-forgot__page">
            {/* Header */}
            <Header bk="login" menu={defaultMenu}/>

            <div className="ynq-password-forgot__container">
                <form
                    onSubmit={handleSubmit}
                    className="ynq-password-forgot__form"
                >
                    <div className="ynq-password-forgot__title">
                        Set your password
                    </div>
                    <div className="ynq-password-forgot__subtitle">
                        Please fill in your new password of your Younique
                        account.
                    </div>
                    <div className="ynq-password-setup__row">
                        <InputComponent
                            id="password"
                            name="password"
                            type="password"
                            label="Password"
                            labelClassName="ynq-password-setup__label"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            className={classNames({
                                'ynq-input--error':
                                    errors.password && touched.password,
                            })}
                        />
                    </div>
                    <div className="ynq-password-setup__row">
                        <InputComponent
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            label="Confirm Password"
                            labelClassName="ynq-password-setup__label"
                            value={values.newPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            className={classNames({
                                'ynq-input--error':
                                    errors.newPassword && touched.newPassword,
                            })}
                        />
                    </div>
                    <div className="ynq-password-forgot__actions">
                        <ButtonComponent
                            type="submit"
                            className="ynq-button--primary"
                        >
                            {asyncInProgress ? (
                                <Spinner
                                    animation="border"
                                    variant="secondary"
                                />
                            ) : (
                                'Send'
                            )}
                        </ButtonComponent>
                        <div className="ynq-password-setup__link">
                            <ButtonComponent
                                onClick={() =>
                                    toggleRecommendation(!recommendation)
                                }
                            >
                                What is a good Password?
                            </ButtonComponent>
                        </div>
                    </div>
                    {recommendation ? (
                        <div className="ynq-password-setup__actions">
                            <ul>
                                <li className="ynq-password-setup__list-item">
                                    * a digit should occur at least once
                                </li>
                                <li className="ynq-password-setup__list-item">
                                    * a lower case letter should occur at least
                                    once
                                </li>
                                <li className="ynq-password-setup__list-item">
                                    * an upper case letter should occur at least
                                    once
                                </li>
                                <li className="ynq-password-setup__list-item">
                                    * no whitespace allowed in the entire string
                                </li>
                                <li className="ynq-password-setup__list-item">
                                    * at least 6 characters
                                </li>
                            </ul>
                        </div>
                    ) : null}
                    {event.text ? (
                        <div className="ynq-subscription-profile__alert-section">
                            <AlertComponent
                                className={event.type}
                                icon={event.icon}
                                text={event.text}
                                onAction={dismiss}
                            />
                        </div>
                    ) : null}
                </form>
            </div>
        </div>
    );
};

export default SetForgottenPasswordComponent;
