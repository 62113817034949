const terms = {
    concreteAgreement: [
        'Commission structure is determined by the shareholders of the alliance. Target commission: 3% on reservation value.',
        'Payment Fees for pre-paid rooms only: 2,5%',
        'If a reservation is cancelled via the Younique Platform, no commissions will be applicable.',
        'If a guest extends or shortens the duration of the stay, during his stay, adds or cancels some of the services, initially reserved, the applicable commissions should remain based on the initial value of the reservation.',
        'All other and minor terms are outlined in the distribution agreement.',
    ],
    concreteShareholding: [
        'Onetime investment, by buying shares of the Alliance (company).',
        'Each Hotel, that wants to become a shareholder has to buy 1 (one) share for every 10 rooms of its hotel(s).',
        'Shares need to be bought for all rooms in the property(s) (always rounded to the next ten).',
        'The reference cost per share is 300 Euro (30 Euro per room).',
        'The Share price can fluctuate and will be lower than reference for early investor and a bit higher for late investors.',
        'Sample: Hotel with 95 rooms would need to buy 10 Shares x 300 Euro, which would lead to a onetime investment of 3.000 Euro.',
    ],
    tryAgreement: [
        'Keep entry barrier low and allow hotels to try.',
        'Reduce the commission by 50% or more, compared to industry standard.',
        'Keep administration costs at minimum level',
    ],
    tryDistribution: [
        '5 Euro per Room paid upfront.',
        'Commissions on reservation for 1st year: 0%.',
        'Commission from 2nd Year onwards: 7,5% calculated on the total reservation value of the first year. Pre-payment.',
        'Payment Fees for pre-paid rooms only: 2,5%.',
        'If a reservation is cancelled via the Younique Platform, no commissions will be applicable.',
        'If a guest extends or shortens the duration of the stay, during his stay, adds or cancels some of the services, initially reserved, the applicable commissions should remain based on the initial value of the reservation.',
        'All other and minor terms are outlined in the distribution agreement.',
    ],
    liteAgreement: [
        'Commissions: 15% on reservation value.',
        'Commissions payment: monthly.',
        'If a reservation is cancelled via the Younique Platform, no commissions will be applicable.',
        'If a guest extends or shortens the duration of the stay, during his stay, adds or cancels some of the services, initially reserved, the applicable commissions should remain based on the initial value of the reservation.',
        'All other and minor terms are outlined in the distribution agreement.',
    ],
};

export default terms;
