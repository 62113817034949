const gtag = {
  events: {
    pageView: (name = 'default') => window.gtag(
      'event', 'page_view',
      {
        'send_to': process.env.GA_MEASUREME_ID,
        page_name: name
      })
  }
};

export default gtag;