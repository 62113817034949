export const crumbs = [
	{
		route: 'frontend',
		values: [
			{
				name: 'Careers',
				url: '/careers'
			},
			{
				name: 'Front-end Developer',
				url: '/careers/frontend'
			},
			{
				name: 'Apply',
				url: '/careers/frontend/apply'
			}
		]
	},
	{
		route: 'backend',
		values: [
			{
				name: 'Careers',
				url: '/careers'
			},
			{
				name: 'Back-end Developer',
				url: '/careers/backend'
			},
			{
				name: 'Apply',
				url: '/careers/backend/apply'
			}
		]
	},
	{
		route: 'mobile',
		values: [
			{
				name: 'Careers',
				url: '/careers'
			},
			{
				name: 'Mobile Developer',
				url: '/careers/mobile'
			},
			{
				name: 'Apply',
				url: '/careers/mobile/apply'
			}
		]
	}
];
