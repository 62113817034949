import React, {useState} from 'react';
import Header from 'components/stateless/header';
import {defaultMenu} from 'components/stateless/header-items';
import {CheckFilled, DocumentLayoutLeft} from 'icons';
import terms from 'components/welcome/options/terms';
import ButtonComponent from 'components/button';
import FooterComponent from 'components/footer';
import {FOOTER_ALLIANCE_CATEGORIES} from 'utils/footer-categories';
import Spinner from 'react-bootstrap/Spinner';
import {chooseProgram} from 'components/welcome/options/choose-program-service';
import AlertComponent from '../../../utils/alert';
import {useHistory} from 'react-router-dom';
import './OptionComponent.scss';

const ConcreteOptionComponent = () => {
    let history = useHistory();
    const [asyncInProgress, setAsyncInProgress] = useState(false);
    const [messageSent, sendMessage] = useState(false);
    const title = 'Concrete';

    return (
        <>
            {/* Header */}
            <Header height={600} page={'home'} bk={'home'} menu={defaultMenu}>
                <div
                    className="row mContainer justify-content-start justify-content-sm-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9 p-0">
                    <div className="impressum-content-container p-0">
                        <div className="impressum-item-content px-2 px-sm-0">
                            <span className="impressum-item-title">
                                {title}
                            </span>
                        </div>
                    </div>
                </div>
            </Header>

            <div className="container--large ynq-welcome-option__container">
                <span className="ynq-welcome-option__statement">
                    We have chosen the name Concrete, because of our believe
                    that concrete action is required, to change the distribution
                    landscape, which is putting the entire hotel industry under
                    extreme pressure.
                </span>
                <span className="ynq-welcome-option__description">
                    The aim of the alliance is to build an alternative
                    distribution channel, with the interest of hotels and guests
                    in its DNA and to reduce the footprint of intermediaries.
                    <br/>
                    <br/>
                    The model is extremely simple. Every Hotel makes a small
                    investment and helps to build and grow this distribution
                    channel. The higher the participation, the greater the
                    leverage.
                    <br/>
                    <br/>
                    The benefits for hoteliers are concrete and exceptionally
                    simple. Pay a fraction of the commissions you pay today.
                </span>
            </div>
            <div className="container--large ynq-welcome-option__container">
                <span className="ynq-welcome-option__description-bold">
                    Key terms of the distribution agreement are as follow:
                </span>
                <ul className="ynq-welcome-option__terms">
                    {terms.concreteAgreement.map((item, idx) => (
                        <li
                            key={idx}
                            className="ynq-welcome-option__terms-list"
                        >
                            <div className="ynq-welcome-option__terms-icon">
                                <CheckFilled/>
                            </div>
                            <span className="ynq-welcome-option__terms-list-text">
                                {item}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="container--large ynq-welcome-option__container">
                <span className="ynq-welcome-option__description-bold">
                    Key terms for Shareholding:
                </span>
                <ul className="ynq-welcome-option__terms">
                    {terms.concreteShareholding.map((item, idx) => (
                        <li
                            key={idx}
                            className="ynq-welcome-option__terms-list"
                        >
                            <div className="ynq-welcome-option__terms-icon">
                                <CheckFilled/>
                            </div>
                            <span className="ynq-welcome-option__terms-list-text">
                                {item}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="container--large ynq-welcome-option__container">
                <span className="ynq-welcome-option__description">
                    To keep commissions low, the alliance has no aims to make a
                    profit, just to break even every year. In addition to this,
                    also costs need to be kept very low, by keeping the
                    administration efforts very limited and all shareholders
                    promoting the initiative between peers (viral marketing).
                    <br/>
                    <br/>
                    All funds and revenues collected will be dedicated to the
                    Platform and the marketing of it.
                </span>
            </div>
            <div className="container--large ynq-welcome-option__container">
                <div className="ynq-welcome-option__action">
                    <img
                        src={require('../../../assets/img/platform.png')}
                        alt="img"
                    />
                    <div className="ynq-welcome-option__action-text">
                        <span className="ynq-welcome-option__action-title">
                            Concrete,{`\n`} start to act now!
                        </span>
                        <ButtonComponent
                            title="Sign Up"
                            icon={<DocumentLayoutLeft/>}
                            className="ynq-button--grey-9 ynq-button--reverse"
                            onClick={() =>
                                chooseProgram(
                                    title,
                                    setAsyncInProgress,
                                    sendMessage
                                )
                            }
                        >
                            {asyncInProgress ? (
                                <Spinner
                                    animation="border"
                                    variant="secondary"
                                />
                            ) : (
                                'Sign Up'
                            )}
                        </ButtonComponent>
                    </div>
                </div>
            </div>
            <div className="container--large ynq-welcome-option__alert-container">
                {messageSent ? (
                    <AlertComponent
                        className="ynq-alert--success"
                        btnClassName={''}
                        icon={<CheckFilled/>}
                        text={
                            'An email was sent to your inbox! Please check it for additional details.'
                        }
                        onAction={() => history.push('/')}
                    />
                ) : null}
            </div>

            {/* Footer */}
            <FooterComponent
                target="alliance"
                menu={FOOTER_ALLIANCE_CATEGORIES}
            />
        </>
    );
};

export default ConcreteOptionComponent;
