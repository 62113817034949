import React, {useState} from 'react';
import Header from 'components/stateless/header';
import {defaultMenu} from 'components/stateless/header-items';
import {CheckFilled, DocumentLayoutLeft} from 'icons';
import terms from 'components/welcome/options/terms';
import ButtonComponent from 'components/button';
import FooterComponent from 'components/footer';
import {FOOTER_ALLIANCE_CATEGORIES} from 'utils/footer-categories';
import {chooseProgram} from 'components/welcome/options/choose-program-service';
import Spinner from 'react-bootstrap/Spinner';
import AlertComponent from 'utils/alert';
import {useHistory} from 'react-router-dom';
import './OptionComponent.scss';

const LiteOptionComponent = () => {
    let history = useHistory();
    const [asyncInProgress, setAsyncInProgress] = useState(false);
    const [messageSent, sendMessage] = useState(false);
    const title = 'Lite';

    return (
        <>
            {/* Header */}
            <Header height={600} page={'home'} bk={'home'} menu={defaultMenu}>
                <div
                    className="row mContainer justify-content-start justify-content-sm-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9 p-0">
                    <div className="impressum-content-container p-0">
                        <div className="impressum-item-content px-2 px-sm-0">
                            <span className="impressum-item-title">{title}</span>
                        </div>
                    </div>
                </div>
            </Header>

            <div className="container--large ynq-welcome-option__container">
                <span className="ynq-welcome-option__statement">
                    If you are not yet convinced, this is the traditional
                    approach to online distribution with zero-investment and
                    zero-risk. But also, less “fun”, because you lose the major
                    benefits of the Alliance.
                </span>
                <span className="ynq-welcome-option__description">
                    Anyhow, just try, and you can become Member or Shareholder
                    in any future moment.
                </span>
            </div>
            <div className="container--large ynq-welcome-option__container">
                <span className="ynq-welcome-option__description-bold">
                    Key terms of the distribution agreement are as follow:
                </span>
                <ul className="ynq-welcome-option__terms">
                    {terms.liteAgreement.map((item, idx) => (
                        <li
                            key={idx}
                            className="ynq-welcome-option__terms-list"
                        >
                            <div className="ynq-welcome-option__terms-icon">
                                <CheckFilled/>
                            </div>
                            <span className="ynq-welcome-option__terms-list-text">
                                {item}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="container--large ynq-welcome-option__container">
                <div className="ynq-welcome-option__action">
                    <img
                        src={require('../../../assets/img/platform.png')}
                        alt="img"
                    />
                    <div className="ynq-welcome-option__action-text">
                        <span className="ynq-welcome-option__action-title">
                            Try,{`\n`} start to act now!
                        </span>
                        <ButtonComponent
                            title="Sign Up"
                            icon={<DocumentLayoutLeft/>}
                            className="ynq-button--grey-9 ynq-button--reverse"
                            onClick={() =>
                                chooseProgram(
                                    title,
                                    setAsyncInProgress,
                                    sendMessage
                                )
                            }
                        >
                            {asyncInProgress ? (
                                <Spinner
                                    animation="border"
                                    variant="secondary"
                                />
                            ) : (
                                'Sign Up'
                            )}
                        </ButtonComponent>
                    </div>
                </div>
            </div>
            <div className="container--large ynq-welcome-option__alert-container">
                {messageSent ? (
                    <AlertComponent
                        className="ynq-alert--success"
                        btnClassName={''}
                        icon={<CheckFilled/>}
                        text={
                            'An email was sent to your inbox! Please check it for additional details.'
                        }
                        onAction={() => history.push('/')}
                    />
                ) : null}
            </div>

            {/* Footer */}
            <FooterComponent
                target="alliance"
                menu={FOOTER_ALLIANCE_CATEGORIES}
            />
        </>
    );
};

export default LiteOptionComponent;
