import React, {useState} from 'react';
import Header from 'components/stateless/header';
import {defaultMenu} from 'components/stateless/header-items';
import InputComponent from 'components/form/input';
import classNames from 'classnames';
import ButtonComponent from 'components/button';
import Spinner from 'react-bootstrap/Spinner';
import {useFormik} from 'formik';
import {forgotPasswordForm} from './forgot-password';
import {CheckFilled, TimesCircleFilled} from 'icons';
import './ForgotPassword.scss';
import AlertComponent from 'utils/alert';

const defaultEvent = {
    type: '',
    icon: null,
    text: null,
};

const ForgotPasswordComponent = () => {
    const [asyncInProgress, setAsyncInProgress] = useState(false);
    const [event, setEvent] = useState(defaultEvent);
    const env = process.env.REACT_APP_NODE_API;
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
    } = useFormik({
        initialValues: forgotPasswordForm.schema,
        validationSchema: forgotPasswordForm.validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            sendEmail(values);
        },
    });

    const sendEmail = values => {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        };

        setAsyncInProgress(true);

        fetch(`${env}/api/alliance/user/forgot`, options)
            .then(response => Promise.all([response.ok, response.json()]))
            .then(([ok, json]) => {
                if (ok) {
                    setAsyncInProgress(false);
                    setEvent({
                        type: 'ynq-alert--success',
                        icon: <CheckFilled/>,
                        text:
                            'Password change request sent! Please check inbox for details.',
                    });
                } else {
                    setAsyncInProgress(false);
                    setEvent({
                        type: 'ynq-alert--error',
                        icon: <TimesCircleFilled/>,
                        text: json.errors[0].message,
                    });
                    throw new Error(json.errors[0].message);
                }
            })
            .catch(() => {
                setAsyncInProgress(false);
            });
    };

    const dismiss = () => {
        setEvent({
            type: '',
            icon: null,
            text: null,
        });
    };

    return (
        <div className="ynq-password-forgot__page">
            {/* Header */}
            <Header bk="login" menu={defaultMenu}/>

            <div className="ynq-password-forgot__container">
                <form
                    onSubmit={handleSubmit}
                    className="ynq-password-forgot__form"
                >
                    <div className="ynq-password-forgot__title">
                        Forgot your password?
                    </div>
                    <div className="ynq-password-forgot__subtitle">
                        Please fill in your email address of your Younique
                        account and we´ll send you a link to reset your
                        password.
                    </div>
                    <div className="ynq-password-forgot__row">
                        <InputComponent
                            id="email"
                            name="email"
                            type="text"
                            label="Email Address"
                            labelClassName="ynq-password-forgot__label"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            className={classNames({
                                'ynq-input--error':
                                    errors.email && touched.email,
                            })}
                        />
                    </div>
                    <div className="ynq-password-forgot__actions">
                        <ButtonComponent
                            type="submit"
                            className="ynq-button--primary"
                        >
                            {asyncInProgress ? (
                                <Spinner
                                    animation="border"
                                    variant="secondary"
                                />
                            ) : (
                                'Send'
                            )}
                        </ButtonComponent>
                    </div>
                    {event.text ? (
                        <div className="ynq-subscription-profile__alert-section">
                            <AlertComponent
                                className={event.type}
                                icon={event.icon}
                                text={event.text}
                                btnDisplay={false}
                                onAction={dismiss}
                            />
                        </div>
                    ) : null}
                </form>
            </div>
        </div>
    );
};

export default ForgotPasswordComponent;
