import "./revolution-item.css";
import Row from "react-bootstrap/Row";
import React from "react";

const RevolutionItem = props => {
	return (props.isOdd ?
		<Row className="mt-4 mt-md-4 mt-lg-5 justify-content-center">
			<div className="row cContainer justify-content-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9">
				{props.useImg ?
				<div className={`item-img ${props.imgClass} p-0 col-12 col-sm-9 col-md-6 col-lg-5 col-xl-6`}/>
				: null}
				<div className="content-container p-0 col-11 col-sm-9 col-md-6 col-lg-6 col-xl-6">
					<div className="item-number col-sm-2">{props.itemNumber}</div>
					<div className="item-content col-sm-10">
						<span className="item-title">{props.itemTitle}</span>
						<span className="item-text pr-0">{props.itemContent}</span>
					</div>
				</div>
			</div>
		</Row>
	:
		<Row className="mt-4 mt-md-4 mt-lg-5 justify-content-center">
			<div className="row cContainer justify-content-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9">
				<div className="content-container p-0 col-11 col-sm-9 col-md-6 col-lg-6 col-xl-6 order-md-first order-last">
					<div className="item-number col-sm-2">{props.itemNumber}</div>
					<div className="item-content col-sm-10">
						<span className="item-title">{props.itemTitle}</span>
						<span className="item-text pr-0">{props.itemContent}</span>
					</div>
				</div>
				{props.useImg ?
				<div className={`item-img ${props.imgClass} p-0 col-12 col-sm-9 col-md-6 col-lg-5 col-xl-6 order-md-last order-first`}/>
				: null}
			</div>
		</Row>
	)
};

export default RevolutionItem;
