import * as Yup from 'yup';

const loginForm = {
    schema: {
        email: '',
        password: '',
    },
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .email()
            .required(),
        password: Yup.string().required(),
    }),
};

export default loginForm;
