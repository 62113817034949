import * as React from 'react';

function SvgDocumentLayoutLeft(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M4 16h8a1.333 1.333 0 001.334-1.333v-8A1.333 1.333 0 0012 5.333H4a1.333 1.333 0 00-1.333 1.334v8A1.333 1.333 0 004 16zm1.334-8h5.333v5.333H5.334V8zm12 2.667H28A1.333 1.333 0 0028 8H17.334a1.333 1.333 0 100 2.667zm0 13.333H4a1.333 1.333 0 000 2.667h13.334a1.334 1.334 0 000-2.667zM28 18.667H4a1.333 1.333 0 000 2.666h24a1.333 1.333 0 100-2.666zm0-5.334H17.334a1.333 1.333 0 100 2.667H28a1.333 1.333 0 000-2.667z"
                fill="#504F66"
            />
        </svg>
    );
}

export default SvgDocumentLayoutLeft;
