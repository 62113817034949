import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import RoundedButton from './rounded-button';
import { Link } from 'react-router-dom';
import slides from './carousel-slides';
import './slider.css';

const Slider = () => {
    const [index, set] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            set((index + 1) % 3);
        }, 5000);

        return () => clearInterval(interval);
    });

    const transitionsRight = useTransition(index, item => item, {
        from: {
            opacity: 0,
            transform: 'translate3d(100%,0,0)',
            position: 'absolute',
            width: '100%',
        },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        leave: () => async next => {
            await next({ opacity: 0, transform: 'translate3d(-50%,0,0)' });
        },
        config: { mass: 1, tension: 210, friction: 20 },
    });

    const slideTitle = () => (
        <div className="slider-text-sm">your unique experience</div>
    );

    const slideButton = () => (
        <div className="slider-button-container">
            {/*  // todo: include after connecting stable API <Link to="/get-in-the-loop"> */}
            <Link to="/contact">
                <RoundedButton icon />
            </Link>
            <div className="slider-button-overlay" />
        </div>
    );

    const sliderNav = () => (
        <div className="dot-nav">
            <button
                className={`line ${index === 0 && 'active'}`}
                onClick={() => set(0)}
            />
            <button
                className={`line ${index === 1 && 'active'}`}
                onClick={() => set(1)}
            />
            <button
                className={`line ${index === 2 && 'active'}`}
                onClick={() => set(2)}
            />
        </div>
    );

    return (
        <div className="row mContainer justify-content-start justify-content-sm-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9 p-0">
            {transitionsRight.map(({ item, props, key }) => {
                return (
                    <animated.div
                        key={key}
                        className="row home-page-slide-content"
                        style={{ ...props }}
                    >
                        <div className="col-md-6">
                            {slideTitle()}
                            {slides[item].content}
                            {slideButton()}
                        </div>
                        <div className="col-md-6 home-page-item-img img-9">
                            <img src={slides[item].image} alt="1" />
                        </div>
                    </animated.div>
                );
            })}

            <div className="slider-button-container mobile">
                {/*  // todo: include after connecting stable API <RoundedButton to="/get-in-the-loop"/> */}
                <Link to="/contact">
                    <RoundedButton />
                </Link>
            </div>

            {sliderNav()}
        </div>
    );
};

export default Slider;
