import * as React from 'react';

function SvgLock(props) {
	return (
		<svg width={24} height={24} viewBox="0 0 20 26" fill="none" {...props}>
			<path
				d="M10 14.25a1.25 1.25 0 00-1.25 1.25v3.75a1.25 1.25 0 002.5 0V15.5A1.25 1.25 0 0010 14.25zm6.25-5v-2.5a6.25 6.25 0 00-12.5 0v2.5A3.75 3.75 0 000 13v8.75a3.75 3.75 0 003.75 3.75h12.5A3.75 3.75 0 0020 21.75V13a3.75 3.75 0 00-3.75-3.75zm-10-2.5a3.75 3.75 0 017.5 0v2.5h-7.5v-2.5zm11.25 15A1.25 1.25 0 0116.25 23H3.75a1.25 1.25 0 01-1.25-1.25V13a1.25 1.25 0 011.25-1.25h12.5A1.25 1.25 0 0117.5 13v8.75z"
				fill="#8487A6"
			/>
		</svg>
	);
}

export default SvgLock;
