import React from 'react';
import PropTypes from 'prop-types';
import InputComponent from 'components/form/input';
import SelectComponent from 'components/form/select';
import classNames from 'classnames';

const CommonLegalAddress = ({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                countries,
                                isDisabled,
                            }) => (
    <>
        <div className="ynq-subscription-profile__row">
            <InputComponent
                id="legalEntry.street"
                name="legalEntry.street"
                type="text"
                label="Street"
                labelClassName="ynq-subscription-profile__label"
                value={values.legalEntry.street}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="-"
                isRequired
                disabled={isDisabled}
                maxLength={255}
                className={classNames({
                    'ynq-input--error':
                        errors.legalEntry?.street && touched.legalEntry?.street,
                })}
            />
        </div>
        <div className="ynq-subscription-profile__row-group">
            <div className="ynq-subscription-profile__input-flex-3">
                <InputComponent
                    id="legalEntry.city"
                    name="legalEntry.city"
                    type="text"
                    label="City"
                    labelClassName="ynq-subscription-profile__label"
                    value={values.legalEntry.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="-"
                    isRequired
                    disabled={isDisabled}
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.legalEntry?.city && touched.legalEntry?.city,
                    })}
                />
            </div>
            <div className="ynq-subscription-profile__input-flex-1">
                <InputComponent
                    id="legalEntry.postCode"
                    name="legalEntry.postCode"
                    type="text"
                    label="PostCode"
                    labelClassName="ynq-subscription-profile__label"
                    value={values.legalEntry.postCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="-"
                    isRequired
                    disabled={isDisabled}
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.legalEntry?.postCode &&
                            touched.legalEntry?.postCode,
                    })}
                />
            </div>
        </div>
        <div className="ynq-subscription-profile__row">
            <SelectComponent
                id="legalEntry.country"
                name="legalEntry.country"
                type="text"
                label="Country"
                labelClassName="ynq-subscription-profile__label"
                options={countries}
                value={values.legalEntry.country}
                optionLabel="name"
                optionValue="name"
                onChange={handleChange}
                onBlur={handleBlur}
                isMulti={false}
                placeholder="-"
                isRequired
                isDisabled={isDisabled}
                className={classNames({
                    'ynq-input--error':
                        errors.legalEntry?.country &&
                        touched.legalEntry?.country,
                })}
            />
        </div>
    </>
);

CommonLegalAddress.propTypes = {
    form: PropTypes.object,
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    countries: PropTypes.array,
    isDisabled: PropTypes.bool,
};

export default CommonLegalAddress;
