import * as React from 'react';

function SvgLogomark(props) {
	return (
		<svg width={24} height={24} fill="none" {...props}>
			<path
				d="M23.612 7.845c-.294-1.488-2.745-1.584-2.745-1.584s1.912-1.608 1.103-2.569c-1.03-1.296-3.873-.696-3.873-.696s2.451-1.848.98-2.568c-2.23-1.152-6.643.168-9.144 2.232C4.663 3.452.642 7.893.642 13.27.642 19.2 5.545 24 11.6 24c6.055 0 10.958-4.8 10.958-10.73 0-.984-.147-1.944-.392-2.856.515-1.129 1.593-1.777 1.446-2.569z"
				fill="#6972B4"
			/>
			<path
				d="M16.797 14.35c-.367 4.49-4.094 7.394-8.678 7.106-4.584-.288-7.698-3.577-7.673-8.402C.47 8.541 5.006 4.1 8.143 7.821c5.124-1.704 9.046 1.704 8.654 6.53z"
				fill="#EBEDFF"
			/>
			<path
				d="M11.998 14.782c.798-.016 1.433-.663 1.416-1.445-.016-.782-.677-1.403-1.476-1.387-.799.017-1.433.664-1.416 1.446.016.782.677 1.402 1.476 1.386zM3.167 12.142c.623 0 1.128-.494 1.128-1.104 0-.61-.505-1.105-1.128-1.105-.623 0-1.128.495-1.128 1.105s.505 1.104 1.128 1.104zM8.756 16.439l-4.535-1.225c-.466-.12-.882.288-.735.745.27.84 1.152 1.8 2.378 2.112 1.225.336 2.451-.024 3.138-.6a.61.61 0 00-.246-1.032z"
				fill="#6972B4"
			/>
		</svg>
	);
}

export default SvgLogomark;
