import React from 'react';

const welcomeOptions = [
    {
        img: (
            <img src={require('../../../assets/img/platform.png')} alt="img"/>
        ),
        title: 'Concrete',
        to: '/welcome/concrete',
        description: (
            <>
                <span>
                    This is the best, recommended and definite option to become
                    concrete in changing the actual distribution landscape.
                </span>
                <br/>
                <span>
                    You will become a shareholder of our alliance and get the
                    best deal.
                </span>
                <br/>
                <span>
                    What is the best deal? Onetime Investment of 30 Euro per
                    room. More precisely for every 10 rooms of your property you
                    need to buy 1 share. For example, if your property has 94
                    rooms (always rounded to the next ten), you need to buy 10
                    shares.
                </span>
                <br/>
                <span>
                    Reference price per share is 300 Euro (€. 30 per room). This
                    price can be a slightly lower for early investors and
                    slightly higher for late investors.
                </span>
                <br/>
                <span>
                    With this investment you will pay only 3% commissions on the
                    reservation value.
                </span>
            </>
        ),
    },
    {
        img: (
            <img src={require('../../../assets/img/platform.png')} alt="img"/>
        ),
        title: 'Try',
        to: '/welcome/try',
        description: (
            <>
                <span>
                    This is for those which want to try first but still aim for
                    a great deal.
                </span>
                <br/>
                <span>
                    In this scenario you do NOT need to buy shares of Younique,
                    but you can still sell your rooms on Younique, at a
                    reasonably low cost.
                </span>
                <br/>
                <span>
                    In the first Year, to start you pay 5 Euro (Yes! 5 Euro
                    only) per room. With this pre-payment, you become a Member
                    of the Alliance and for all reservation you will get during
                    the first year, the commission will be zero (Yes, zero!!)
                </span>
                <br/>
                <span>
                    From the second year onwards, you pay 7,5% commission
                    upfront on the total reservation value of the previous year.
                    During the year you will not pay anything, independent how
                    many reservations you get.
                </span>
                <br/>
                <span>
                    Of course, you can upgrade and become a shareholder at any
                    time.
                </span>
            </>
        ),
    },
    {
        img: (
            <img src={require('../../../assets/img/platform.png')} alt="img"/>
        ),
        title: 'Lite',
        to: '/welcome/lite',
        description: (
            <>
                <span>
                    You can go LITE, by just signing a regular distribution
                    agreement with us.
                </span>
                <br/>
                <span>
                    You will lose the benefits of low commissions, but you also
                    take no risk.
                </span>
                <br/>
                <span>No upfront payment or investment is required.</span>
                <br/>
                <span>
                    You provide us with your availability, and we charge you 15%
                    commissions for all reservation we generate.
                </span>
                <br/>
                <span>Can’t be more simple.</span>
            </>
        ),
    },
];

export default welcomeOptions;
