export const FOOTER_ALLIANCE_CATEGORIES = [
    {
        key: 'ynq',
        name: 'Younique',
        className: 'col-sm-12 col-md-4 col-lg-3 col-xl-3',
        child: [
            {
                key: 'ynq-welcome',
                name: 'Welcome',
                url: '/alliance',
                target: 'page',
            },
            {
                key: 'ynq-manifesto',
                name: 'Manifesto',
                url: '/manifesto',
                target: 'page',
            },
            // {
            //     key: 'ynq-revolution',
            //     name: 'Join the Revolution',
            //     url: '/join-the-revolution',
            //     target: 'page',
            // },
            {
                key: 'ynq-contact',
                name: 'Contact Us',
                url: '/contact',
                target: 'page',
            },
            {
                key: 'ynq-careers',
                name: 'Careers',
                url: '/careers',
                target: 'page',
            },
        ],
    },
    {
        key: 'discover',
        name: 'Discover',
        className: 'col-sm-12 col-md-4 col-lg-3 col-xl-3',
        child: [
            {
                key: 'd-soon',
                name: 'Coming Soon...',
                url: '/',
                target: 'page',
            },
        ],
    },
    {
        key: 'support',
        name: 'Support',
        className: 'col-sm-12 col-md-4 col-lg-3 col-xl-3',
        child: [
            {
                key: 's-faq',
                name: 'FAQs',
                url: '/faq',
                target: 'page',
            },
            {
                key: 's-policies',
                name: 'Policies',
                url: '/',
                target: 'page',
            },
        ],
    },
];

export const FOOTER_CUSTOMER_CATEGORIES = [
    {
        key: 'ynq',
        name: 'Younique',
        className: 'col-sm-12 col-md-3 col-lg-2 col-xl-2',
        child: [
            {
                key: 'ynq-contact',
                name: 'Contact us',
                url: '/contact',
                target: 'page',
            },
            {
                key: 'ynq-allaince',
                name: 'Alliance',
                url: '/alliance',
                target: 'page',
            },
            // {
            //     key: 'ynq-revolution',
            //     name: 'Join the Revolution',
            //     url: '/join-the-revolution',
            //     target: 'page',
            // },
            {
                key: 'ynq-careers',
                name: 'Careers',
                url: '/careers',
                target: 'page',
            },
        ],
    },
    {
        key: 'discover',
        name: 'Discover',
        className: 'col-sm-12 col-md-3 col-lg-2 col-xl-2',
        child: [
            {
                key: 'd-soon',
                name: 'Coming soon',
                url: '/',
                target: 'page',
            },
        ],
    },
    {
        key: 'support',
        name: 'Support',
        className: 'col-sm-12 col-md-3 col-lg-3 col-xl-3',
        child: [
            {
                key: 's-faqs',
                name: 'FAQ`s',
                url: '/faq',
                target: 'page',
            },
            {
                key: 's-cookies',
                name: 'Cookies',
                url: '/cookies',
                target: 'page',
            },
            {
                key: 's-contact',
                name: 'Contact Customer Service',
                url: '/',
                target: 'page',
            },
        ],
    },
    // {
    //     key: 'extranet',
    //     name: 'Extranet',
    //     className: 'col-sm-12 col-md-3 col-lg-2 col-xl-2',
    //     child: [
    //         {
    //             key: 'e-careers',
    //             name: 'Extranet',
    //             url: '/subscription',
    //             target: 'page',
    //         },
    //     ],
    // },
];

export const FOOTER_SUBSCRIBER_CATEGORIES = [
    {
        key: 'ynq',
        name: 'Younique',
        className: 'col-sm-12 col-md-4 col-lg-3 col-xl-3',
        child: [
            {
                key: 'ynq-welcome',
                name: 'Welcome',
                url: '/alliance',
                target: 'page',
            },
            {
                key: 'ynq-manifesto',
                name: 'Manifesto',
                url: '/manifesto',
                target: 'page',
            },
            {
                key: 'ynq-revolution',
                name: 'Join the Revolution',
                url: '/join-the-revolution',
                target: 'page',
            },
            {
                key: 'ynq-contact',
                name: 'Contact Us',
                url: '/contact',
                target: 'page',
            },
            {
                key: 'ynq-careers',
                name: 'Careers',
                url: '/careers',
                target: 'page',
            },
        ],
    },
    {
        key: 'discover',
        name: 'Discover',
        className: 'col-sm-12 col-md-4 col-lg-3 col-xl-3',
        child: [
            {
                key: 'd-soon',
                name: 'Coming Soon...',
                url: '/',
                target: 'page',
            },
        ],
    },
    {
        key: 'support',
        name: 'Support',
        className: 'col-sm-12 col-md-4 col-lg-3 col-xl-3',
        child: [
            {
                key: 's-faq',
                name: 'FAQs',
                url: '/faq',
                target: 'modal',
            },
            {
                key: 's-policies',
                name: 'Policies',
                url: '/',
                target: 'page',
            },
        ],
    },
];
