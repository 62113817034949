import React from 'react';
import LinkComponent from 'components/link';

export const getSteps = shareholder => {
    return [
        {
            img: (
                <img
                    src={require('../../../assets/img/revolution.png')}
                    alt="img"
                />
            ),
            number: '01.',
            title: 'Registration.',
            description: (
                <span className="ynq-landing-step__description">
                    You are already registered, but we need some more
                    information about you and your business, before you can
                    become a shareholder of our alliance. Just click{' '}
                    {shareholder && shareholder.companyProfileCreated ? (
                        'here'
                    ) : (
                        <LinkComponent
                            to={'/subscription'}
                            className="ynq-landing-step__link"
                        >
                            here
                        </LinkComponent>
                    )}
                    ”.
                </span>
            ),
            state: true,
        },
        {
            img: (
                <img
                    src={require('../../../assets/img/alliance.png')}
                    alt="img"
                />
            ),
            number: '02.',
            title: 'Be a Member of the Alliance.',
            description: (
                <span className="ynq-landing-step__description">
                    Now that we have all your details, we require two agreements
                    to be signed with you.You will receive an email with the
                    agreements attached.
                </span>
            ),
            state: shareholder && shareholder?.companyProfileCreated,
        },
        {
            img: (
                <img
                    src={require('../../../assets/img/platform.png')}
                    alt="img"
                />
            ),
            number: '03.',
            title: 'The Contract.',
            description: (
                <span className="ynq-landing-step__description">
                    The contract is the “subscription undertaking” of a number
                    of shares, which is simply calculated by the number of rooms
                    of your hotel(s) divided by 10 (and rounded to the next 10).
                    This undertaking will be executed at the next round of
                    capital increase of the company.
                </span>
            ),
            state: false,
        },
        {
            img: (
                <img
                    src={require('../../../assets/img/fair_alternative.png')}
                    alt="img"
                />
            ),
            number: '04.',
            title: 'Shareholder Agreement.',
            description: (
                <span className="ynq-landing-step__description">
                    The Shareholder Agreement is the essence of the alliance and
                    defines the rules of it. The rules have been written to
                    define the objective and rules of collaboration for the
                    alliance, to ensure fairness and that community interest
                    comes always first and every shareholder is treated the same
                    way. To become a shareholder of the alliance, the
                    shareholder agreement, must be accepted and signed.
                </span>
            ),
            state: false,
        },
        {
            img: (
                <img
                    src={require('../../../assets/img/payment.png')}
                    alt="img"
                />
            ),
            number: '05.',
            title: 'Payment.',
            description: (
                <span className="ynq-landing-step__description">
                    This is your last step, to become a shareholder. Please make
                    the payment as indicated in the subscription undertaking.
                    You will however also receive an email with the exact amount
                    and the bank details for your transfer. We’ll notify you as
                    soon as we have received and registered your payment.
                    Welcome to the Alliance!!!
                </span>
            ),
            state: false,
        },
    ];
};
