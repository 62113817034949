import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from './header';
import FooterComponent from '../footer/FooterComponent';
import { defaultMenu } from 'components/stateless/header-items';
import { FOOTER_ALLIANCE_CATEGORIES } from 'utils/footer-categories';
import './cookies.css';

const Cookies = () => {
    // const renewClick = () => {
    // console.log(1);
    // if (window.showCookieBanner) {
    //     // console.log(2);
    //     window.showCookieBanner();
    //     window.scrollTo(0, 0);
    // }
    // };

    return (
        <>
            {/* Header */}
            <Header
                height={460}
                page={'impressum'}
                bk={'impressum'}
                menu={defaultMenu}
            >
                <div className="row mContainer justify-content-start justify-content-sm-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9 p-0">
                    <div className="impressum-content-container align-items-center col-11 col-sm-9 col-md-6 col-lg-6 col-xl-6 p-0">
                        <div className="impressum-item-content px-2 px-sm-0">
                            <h1 className="impressum-item-title">
                                <span>Cookies Policies.</span>
                            </h1>
                        </div>
                    </div>
                    <div className="impressum-item-img col-11 col-sm-9 col-md-6 col-lg-5 col-xl-6 p-0 d-none d-md-block">
                        <div className="img-cookies" />
                    </div>
                </div>
            </Header>

            <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                <Col className="impressum-container de p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                    <Col className="impressum-chapter pb-1 p-lg-0 d-none d-md-block col-xl-2 col-lg-2 col-md-2 col-sm-2 col-10">
                        <div>Cookie policy.</div>
                    </Col>
                    <Col className="impressum-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                        <div className="item-content col-sm-10">
                            <span className="item-title">
                                What is a cookie?
                            </span>
                            <span className="item-text pr-0">
                                <p>
                                    A cookie is a small datafile that is saved
                                    on your computer, tablet or mobile phone. A
                                    cookie is not a program that can contain
                                    harmful programs or viruses.
                                </p>
                            </span>
                        </div>
                    </Col>
                </Col>
            </Row>

            <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                <Col className="impressum-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                    <Col className="impressum-chapter pb-1 p-lg-0 d-none d-md-block col-xl-2 col-lg-2 col-md-2 col-sm-2 col-10" />
                    <Col className="impressum-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                        <div className="item-content col-sm-10">
                            <span className="item-title">
                                How/why the homepage uses cookies
                            </span>
                            <span className="item-text pr-0">
                                Cookies are necessary for the homepage to
                                function. Cookies help us get an overview of
                                your visit to the homepage so that we can
                                continually optimise and adjust the homepage to
                                your requirements and interests. For example,
                                cookies remember what you might have added to a
                                shopping cart, if you have previously visited
                                the page, if you are logged in and what
                                languages and currency you want displayed on the
                                homepage. We also use cookies to target our ads
                                to you on other homepages. On a very general
                                level, cookies are used as part of our services
                                in order to show content that is as relevant as
                                possible to you.
                            </span>
                        </div>
                    </Col>
                </Col>
            </Row>

            <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                <Col className="impressum-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                    <Col className="impressum-chapter pb-1 p-lg-0 d-none d-md-block col-xl-2 col-lg-2 col-md-2 col-sm-2 col-10" />
                    <Col className="impressum-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                        <div className="item-content col-sm-10">
                            <span className="item-title">
                                How long are cookies saved?
                            </span>
                            <span className="item-text pr-0">
                                How long cookies are saved on your device can
                                vary. The time when they are scheduled to expire
                                is calculated from the last date you visited the
                                homepage. When cookies expire, they are
                                automatically deleted. You can view a complete
                                list of cookies below.
                            </span>
                        </div>
                    </Col>
                </Col>
            </Row>

            <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                <Col className="impressum-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                    <Col className="impressum-chapter pb-1 p-lg-0 d-none d-md-block col-xl-2 col-lg-2 col-md-2 col-sm-2 col-10" />
                    <Col className="impressum-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                        <div className="item-content col-sm-10">
                            <span className="item-title">
                                This is how you can reject or delete your
                                cookies
                            </span>
                            <span className="item-text pr-0">
                                You can always reject cookies on your computer,
                                tablet or phone by changing your browser
                                settings. Where these settings can be found
                                depends on the type of browser you are using. If
                                you do change the settings, please be aware that
                                there will be some functions and services that
                                you cannot use because they rely on the homepage
                                being able to remember the choices you have
                                made. You can choose to not receive cookies from
                                Google Analytics{' '}
                                <a
                                    className="linked-text"
                                    href="https://tools.google.com/dlpage/gaoptout"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    here
                                </a>
                                . <br />
                            </span>
                        </div>
                    </Col>
                </Col>
            </Row>

            <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                <Col className="impressum-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                    <Col className="impressum-chapter pb-1 p-lg-0 d-none d-md-block col-xl-2 col-lg-2 col-md-2 col-sm-2 col-10" />
                    <Col className="impressum-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                        <div className="item-content col-sm-10">
                            <span className="item-title">Deleting cookies</span>
                            <span className="item-text pr-0">
                                You can delete cookies that you have previously
                                accepted. If you are using a PC with a recent
                                version of a browser, you can delete your
                                cookies by using these shortcut keys: CTRL +
                                SHIFT + Delete. If the shortcut keys do not work
                                and/or you are using an Apple computer, you must
                                find out what browser you are using and then
                                click on the relevant link:
                            </span>
                            <ul>
                                <li>
                                    <a
                                        className="linked-text"
                                        href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Internet Explorer
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="linked-text"
                                        href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Mozilla Firefox
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="linked-text"
                                        href="https://support.google.com/chrome/answer/95647?hl=en"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Google Chrome
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="linked-text"
                                        href="https://www.opera.com/help/tutorials/security/cookies"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Opera
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="linked-text"
                                        href="https://support.apple.com/en-us/HT201265"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Safari
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="linked-text"
                                        href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Flash cookies
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="linked-text"
                                        href="https://support.apple.com/en-us/HT1677"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Apple
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="linked-text"
                                        href="https://timeread.hubpages.com/hub/How-to-delete-internet-cookies-on-your-Droid-or-any-Android-device"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Android
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="linked-text"
                                        href="https://support.microsoft.com/en-us/help/11696/windows-phone-7"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Windows 7
                                    </a>
                                </li>
                            </ul>

                            <p>
                                Remember: If you are using several different
                                browsers, you must delete the cookies in all of
                                them.
                            </p>
                        </div>
                    </Col>
                </Col>
            </Row>

            <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                <Col className="impressum-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                    <Col className="impressum-chapter pb-1 p-lg-0 d-none d-md-block col-xl-2 col-lg-2 col-md-2 col-sm-2 col-10" />
                    <Col className="impressum-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                        <div className="item-content col-sm-10">
                            <span className="item-title">
                                Do you have any questions?
                            </span>
                            <span className="item-text pr-0">
                                <p>
                                    Should you have any questions or comments in
                                    connection with this information and/or our
                                    processing of personal data, you are welcome
                                    to get in touch with us. The cookie
                                    declaration itself is updated every month
                                    via{' '}
                                    <a
                                        className="linked-text"
                                        href="https://cookieinformation.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Cookie Information
                                    </a>
                                    . If you have any questions regarding the
                                    Cookie Information, you can send an email to
                                    info@cookieinformation.com.
                                </p>
                            </span>
                        </div>
                        {/* <div className="action" onClick={renewClick}>
                            <button onClick={renewClick}>
                                Change Settings
                            </button>
                        </div> */}
                    </Col>
                </Col>
            </Row>

            {/* Footer */}
            <FooterComponent
                target="alliance"
                menu={FOOTER_ALLIANCE_CATEGORIES}
            />
        </>
    );
};

export default Cookies;
