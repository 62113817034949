import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RadioGroupComponent from 'components/form/radio-group';
import InputComponent from 'components/form/input';
import CommonLegalAddress from 'components/subscription/company-profile/CommonLegalAddress';
import SelectionControlComponent from 'components/form/selection-control';
import {validateNumberOfHotels} from 'components/subscription/company-profile/profile-form';

const subscriptionEntity = [
    {label: 'Single Hotel', value: 'HOTEL'},
    {label: 'Hotel Chain', value: 'CHAIN'},
];

const LegalEntity = ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         countries,
                         isDisabled,
                     }) => {
    return (
        <>
            <div className="ynq-subscription-profile__form-section">
                <span className="ynq-subscription-profile__section-title">
                    About the Legal Entity.
                </span>
                <div className="ynq-subscription-profile__row">
                    <RadioGroupComponent
                        id="registrationType"
                        name="registrationType"
                        label="HOTEL CHAIN OR SINGLE HOTEL"
                        labelClassName="ynq-subscription-profile__label"
                        options={subscriptionEntity}
                        isRequired
                        disabled
                        className={'ynq-radio-group--inline'}
                        value={values.registrationType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </div>
                <div className="ynq-subscription-profile__row">
                    <InputComponent
                        id="legalEntry.nameOfLegalEntity"
                        name="legalEntry.nameOfLegalEntity"
                        type="text"
                        label="Name of the Legal Entity"
                        labelClassName="ynq-subscription-profile__label"
                        value={values.legalEntry.nameOfLegalEntity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="-"
                        isRequired
                        disabled={isDisabled}
                        maxLength={255}
                        className={classNames({
                            'ynq-input--error':
                                errors.legalEntry?.nameOfLegalEntity &&
                                touched.legalEntry?.nameOfLegalEntity,
                        })}
                    />
                </div>
                <div className="ynq-subscription-profile__row">
                    <InputComponent
                        id="legalEntry.hotelName"
                        name="legalEntry.hotelName"
                        type="text"
                        label={
                            values.registrationType === 'CHAIN'
                                ? 'HOTEL CHAIN NAME'
                                : 'HOTEL NAME'
                        }
                        labelClassName="ynq-subscription-profile__label"
                        value={values.legalEntry.hotelName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="-"
                        isRequired
                        disabled={isDisabled}
                        maxLength={255}
                        className={classNames({
                            'ynq-input--error':
                                errors.legalEntry?.hotelName &&
                                touched.legalEntry?.hotelName,
                        })}
                    />
                </div>
                {values.registrationType === 'CHAIN' && (
                    <div className="ynq-subscription-profile__row">
                        <InputComponent
                            id="legalEntry.numberOfHotelsInChain"
                            name="legalEntry.numberOfHotelsInChain"
                            type="text"
                            label="NUMBER OF HOTELS IN CHAIN"
                            labelClassName="ynq-subscription-profile__label"
                            value={values.legalEntry.numberOfHotelsInChain}
                            validate={validateNumberOfHotels(
                                values.legalEntry.numberOfHotelsInChain,
                                values.registrationType,
                                errors
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            isRequired
                            disabled={isDisabled}
                            maxLength={3}
                            hint="Value should be >=2 and <=100"
                            className={classNames({
                                'ynq-input--error':
                                    errors.legalEntry?.numberOfHotelsInChain &&
                                    touched.legalEntry?.numberOfHotelsInChain,
                            })}
                        />
                    </div>
                )}
                {values.registrationType === 'HOTEL' && (
                    <div className="ynq-subscription-profile__row">
                        <InputComponent
                            id="legalEntry.tradingName"
                            name="legalEntry.tradingName"
                            type="text"
                            label="HOTEL (TRADING) NAME"
                            labelClassName="ynq-subscription-profile__label"
                            value={values.legalEntry.tradingName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            maxLength={255}
                            className={classNames({
                                'ynq-input--error':
                                    errors.legalEntry?.tradingName &&
                                    touched.legalEntry?.tradingName,
                            })}
                        />
                    </div>
                )}
                {values.registrationType === 'CHAIN' && (
                    <div className="ynq-subscription-profile__row">
                        <InputComponent
                            id="legalEntry.totalNumberOfRooms"
                            name="legalEntry.totalNumberOfRooms"
                            type="text"
                            label="TOTAL number of rooms IN CHAIN"
                            labelClassName="ynq-subscription-profile__label"
                            value={values.legalEntry.totalNumberOfRooms}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            isRequired
                            disabled={isDisabled}
                            maxLength={9}
                            className={classNames({
                                'ynq-input--error':
                                    errors.legalEntry?.totalNumberOfRooms &&
                                    touched.legalEntry?.totalNumberOfRooms,
                            })}
                        />
                    </div>
                )}
                {values.registrationType === 'HOTEL' && (
                    <CommonLegalAddress
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        countries={countries}
                        isDisabled={isDisabled}
                    />
                )}
                <div className="ynq-subscription-profile__row">
                    <InputComponent
                        id="legalEntry.taxId"
                        name="legalEntry.taxId"
                        type="text"
                        label="Tax ID"
                        labelClassName="ynq-subscription-profile__label"
                        value={values.legalEntry.taxId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="-"
                        isRequired
                        disabled={isDisabled}
                        maxLength={255}
                        className={classNames({
                            'ynq-input--error':
                                errors.legalEntry?.taxId &&
                                touched.legalEntry?.taxId,
                        })}
                    />
                </div>
                {values.registrationType === 'HOTEL' && (
                    <div className="ynq-subscription-profile__row">
                        <InputComponent
                            id="legalEntry.totalNumberOfRooms"
                            name="legalEntry.totalNumberOfRooms"
                            type="text"
                            label="number of rooms"
                            labelClassName="ynq-subscription-profile__label"
                            value={values.legalEntry.totalNumberOfRooms}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            isRequired
                            disabled={isDisabled}
                            maxLength={9}
                            className={classNames({
                                'ynq-input--error':
                                    errors.legalEntry?.totalNumberOfRooms &&
                                    touched.legalEntry?.totalNumberOfRooms,
                            })}
                        />
                    </div>
                )}
            </div>
            <div className="ynq-subscription-profile__form-section">
                {values.registrationType === 'CHAIN' && (
                    <>
                        <span className="ynq-subscription-profile__section-title">
                            Address Legal Entity.
                        </span>
                        <CommonLegalAddress
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            countries={countries}
                            isDisabled={isDisabled}
                        />
                    </>
                )}
                {values.registrationType === 'HOTEL' && (
                    <div className="ynq-subscription-profile__row">
                        <SelectionControlComponent
                            id="sameHotelAddress"
                            name="sameHotelAddress"
                            type="checkbox"
                            label="The hotel address is the same as the legal entity address"
                            value={values.sameHotelAddress}
                            checked={values.sameHotelAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                        />
                    </div>
                )}
                <div className="ynq-subscription-profile__row">
                    <SelectionControlComponent
                        id="sameShippingAddress"
                        name="sameShippingAddress"
                        type="checkbox"
                        label="The shipping address is the same as the legal entity"
                        value={values.sameShippingAddress}
                        checked={values.sameShippingAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="-"
                    />
                </div>
            </div>
        </>
    );
};

LegalEntity.propTypes = {
    form: PropTypes.object,
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    countries: PropTypes.array,
    isDisabled: PropTypes.bool,
};

export default LegalEntity;
