import React from 'react';
import { PropTypes } from 'prop-types';

import './Button.css';

export default function Button({
  icon,
  children = '',
  background = '#6972B4',
  type = 'contained',
  onClick = () => {}
}) {

  const Icon = () => icon ? <div className={`icon ${icon}`}></div> : null;

  const classname = type === 'contained' ? 'c_button contained' : 'c_button outlined';

  return (
    <div
      onClick={onClick}
      className={classname}
      style={{
        background
      }}
    >
      <Icon />
      <div className="text">{children}</div>
    </div>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.string,
  background: PropTypes.string,
  icon: PropTypes.oneOf(['google', 'facebook']),
  type: PropTypes.oneOf(['contained', 'outlined'])
}
