import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {CheckCircle, CheckFilled} from 'icons';

const Progress = ({active, steps}) => {
    const classname = idx => {
        return classNames('ynq-subscription__progress-item', {
            'ynq-subscription__progress-item--completed': idx < active,
            'ynq-subscription__progress-item--active': idx === active,
            'ynq-subscription__progress-item--disabled': idx > active,
        });
    };

    const iconx = idx => {
        return idx === active ? (
            <CheckCircle className="ynq-subscription__progress-icon--active"/>
        ) : idx < active || idx === -1 ? (
            <CheckFilled className="ynq-subscription__progress-icon--completed"/>
        ) : (
            <CheckCircle className="ynq-subscription__progress-icon--disabled"/>
        );
    };

    return (
        <div className="ynq-subscription__progress-wrapper">
            <div className="ynq-subscription__progress-item ynq-subscription__progress-item--completed">
                {iconx(-1)}
                Registration
            </div>
            {steps.map((step, idx) => (
                <div key={idx} className={classname(idx)}>
                    {iconx(idx)}
                    {step.name}
                </div>
            ))}
        </div>
    );
};

Progress.propTypes = {
    active: PropTypes.number,
    steps: PropTypes.array,
};

export default Progress;
