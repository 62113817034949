import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Progress from 'components/subscription/stepper/Progress';
import StepperTransition from 'components/subscription/stepper/StepperTransition';
import ProfileComponent from 'components/subscription/company-profile/ProfileComponent';
import ChapterCard from 'components/subscription/stepper/ChapterCard';
import '../SubscriptionComponent.scss';

const StepperComponent = props => {
    const [state, setState] = useState({
        stepActive: 0,
        stepsAmount: 0,
        lastStep: -1,
    });

    useEffect(() => {
        setState({
            stepActive: 0,
            stepsAmount: props.steps && props.steps.length,
        });
    }, [props]);

    const nextStep = () => {
        if (state.stepActive < state.stepsAmount - 1) {
            setState(prevState => {
                return {
                    ...prevState,
                    direction: 'right',
                    lastStep: prevState.stepActive,
                    stepActive: prevState.stepActive + 1,
                };
            });
        }
    };

    // const prevStep = () => {
    //     setState(prevState => {
    //         if (prevState.stepActive > 0) {
    //             return {
    //                 ...prevState,
    //                 direction: 'left',
    //                 lastStep: prevState.stepActive,
    //                 stepActive: prevState.stepActive - 1,
    //             };
    //         }
    //     });
    // };

    const {stepActive, lastStep} = state;
    const steps = [
        {
            step: <ProfileComponent key={0} action={nextStep}/>,
        },
    ];
    const shareholder = JSON.parse(localStorage.getItem('shareholder'));

    return (
        <>
            <div className="container--large ynq-subscription__container">
                <div className="ynq-subscription__progress-container">
                    <Progress active={stepActive} steps={props.steps}/>
                </div>
            </div>
            <div className="container--large ynq-subscription__container">
                <div className="ynq-subscription__title">
                    {shareholder?.firstName}, welcome to
                    {`\n`} Younique!
                </div>
                <div className="ynq-subscription__description">
                    Complete your Younique Company Profile now. Please be aware
                    that all fields marked with{' '}
                    <span className="ynq-subscription__description--highlight">
                        *
                    </span>{' '}
                    are mandatory.
                </div>
            </div>
            <div className="container--large ynq-subscription__container">
                <div className="ynq-subscription__chapter">
                    <ChapterCard/>
                </div>

                <div className="ynq-subscription__content">
                    <StepperTransition
                        stepActive={stepActive}
                        lastStep={lastStep}
                    >
                        {steps.map(item => item.step)}
                    </StepperTransition>
                </div>
            </div>
        </>
    );
};

StepperComponent.propTypes = {
    children: PropTypes.array,
    steps: PropTypes.array,
};

export default StepperComponent;
