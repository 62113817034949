import React from 'react';
import PropTypes from 'prop-types';
import InputComponent from 'components/form/input';
import SelectComponent from 'components/form/select';
import classNames from 'classnames';

const HotelAddress = ({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          countries,
                      }) => (
    <>
        <div className="ynq-subscription-profile__row">
            <InputComponent
                id="hotelAddress.street"
                name="hotelAddress.street"
                type="text"
                label="Street"
                labelClassName="ynq-subscription-profile__label"
                value={values.hotelAddress.street}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="-"
                maxLength={255}
                className={classNames({
                    'ynq-input--error':
                        errors.hotelAddress?.street &&
                        touched.hotelAddress?.street,
                })}
            />
        </div>
        <div className="ynq-subscription-profile__row-group">
            <div className="ynq-subscription-profile__input-flex-3">
                <InputComponent
                    id="hotelAddress.city"
                    name="hotelAddress.city"
                    type="text"
                    label="City"
                    labelClassName="ynq-subscription-profile__label"
                    value={values.hotelAddress.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="-"
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.hotelAddress?.city &&
                            touched.hotelAddress?.city,
                    })}
                />
            </div>
            <div className="ynq-subscription-profile__input-flex-1">
                <InputComponent
                    id="hotelAddress.postalCode"
                    name="hotelAddress.postalCode"
                    type="text"
                    label="PostCode"
                    labelClassName="ynq-subscription-profile__label"
                    value={values.hotelAddress.postalCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="-"
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.hotelAddress?.postalCode &&
                            touched.hotelAddress?.postalCode,
                    })}
                />
            </div>
        </div>
        <div className="ynq-subscription-profile__row">
            <SelectComponent
                id="hotelAddress.country"
                name="hotelAddress.country"
                type="text"
                label="Country"
                labelClassName="ynq-subscription-profile__label"
                options={countries}
                value={values.hotelAddress.country}
                optionLabel="name"
                optionValue="name"
                onChange={handleChange}
                onBlur={handleBlur}
                isMulti={false}
                placeholder="-"
                className={classNames({
                    'ynq-input--error':
                        errors.hotelAddress?.country &&
                        touched.hotelAddress?.country,
                })}
            />
        </div>
    </>
);

HotelAddress.propTypes = {
    form: PropTypes.object,
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    countries: PropTypes.array,
};

export default HotelAddress;
