import React from 'react';
import Header from 'components/stateless/header';
import {defaultMenu} from 'components/stateless/header-items';
import FooterComponent from 'components/footer';
import {FOOTER_ALLIANCE_CATEGORIES} from 'utils/footer-categories';
import {DocumentLayoutLeft} from 'icons';
import {getSteps} from 'components/landing/landing-step/landing-steps';
import LandingStepComponent from 'components/landing/landing-step/LandingStepComponent';
import LinkComponent from 'components/link';
import './LandingComponent.scss';

const LandingComponent = () => {
    const shareholder = JSON.parse(localStorage.getItem('shareholder'));

    return (
        <>
            {/* Header */}
            <Header height={600} page={'home'} bk={'home'} menu={defaultMenu}>
                <div
                    className="row mContainer justify-content-start justify-content-sm-center col-12 col-md-11 col-lg-10 col-xl-9 p-0">
                    <div className="impressum-content-container p-0">
                        <div className="impressum-item-content px-2 px-sm-0">
                            {shareholder &&
                            !shareholder.companyProfileCreated ? (
                                <>
                                    <span className="ynq-landing__header-title">
                                        Become a Member of the Alliance
                                    </span>
                                    <span className="ynq-landing__header-subtitle">
                                        Complete your{' '}
                                        <LinkComponent
                                            to={'/subscription'}
                                            className="ynq-landing__header-link"
                                        >
                                            profile
                                        </LinkComponent>
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span className="ynq-landing__header-title">
                                        Welcome back
                                    </span>
                                    <span className="ynq-landing__header-subtitle">
                                        Subscribe for the{' '}
                                        <LinkComponent
                                            to={'/welcome'}
                                            className="ynq-landing__header-link"
                                        >
                                            alliance
                                        </LinkComponent>
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Header>

            <div className="container--large ynq-landing__container">
                <span className="ynq-landing__title">
                    {shareholder?.firstName}, good to see you.
                </span>
                <span className="ynq-landing__description">
                    We are happy you a here and want to join our alliance. There
                    are just a few steps to be completed. It will not take long,
                    but you will de facto become a shareholder of our alliance,
                    so we have to do things properly. And then, let’s change the
                    game together!
                </span>
                <span className="ynq-landing__statement">
                    Four Steps to become a Member of the Alliance
                </span>
            </div>

            <div className="container--large ynq-landing">
                {/* Landing Steps */}
                <div className="container--large ynq-landing__container">
                    {getSteps(shareholder).map((step, idx) => (
                        <LandingStepComponent
                            key={idx}
                            img={step.img}
                            number={step.number}
                            title={step.title}
                            description={step.description}
                            state={
                                shareholder?.companyProfileCreated && idx === 1
                                    ? true
                                    : step.state
                            }
                        />
                    ))}
                </div>
            </div>

            {/* Apply */}
            <div className="container--large ynq-landing__container">
                <div className="ynq-landing__apply">
                    <span className="ynq-landing__statement">
                        Four steps to go....
                    </span>
                    <div className="ynq-landing__apply-action">
                        {shareholder && shareholder.companyProfileCreated ? (
                            <LinkComponent
                                to={'/welcome'}
                                className="ynq-button ynq-button--grey-9 ynq-button--reverse"
                            >
                                <DocumentLayoutLeft/>
                                Subscribe for the alliance
                            </LinkComponent>
                        ) : (
                            <LinkComponent
                                to={'/subscription'}
                                className="ynq-button ynq-button--grey-9 ynq-button--reverse"
                            >
                                <DocumentLayoutLeft/>
                                Complete your Profile
                            </LinkComponent>
                        )}
                    </div>
                </div>
            </div>

            {/* Manifesto paper row */}
            <div className="container--large ynq-landing__container">
                <div className="ynq-landing__manifesto">
                    <div className="ynq-landing__manifesto-text">
                        <span className="ynq-landing__manifesto-title">
                            Manifesto.
                        </span>
                        <span className="ynq-landing__manifesto-description">
                            Fast forward today, and you find yourself in a
                            corner squeezed between impossible commissions,
                            eroding your margins to the point where you can’t
                            even do well what you do best
                        </span>
                    </div>
                    <div className="ynq-landing__manifesto-paper"/>
                </div>
            </div>

            {/* Action */}
            <div className="container--large ynq-landing__container">
                <div className="ynq-landing__action">
                    <LinkComponent
                        to={'/manifesto'}
                        className="ynq-button ynq-button--grey-9 ynq-button--reverse"
                    >
                        <DocumentLayoutLeft/>
                        Read the Manifesto
                    </LinkComponent>
                </div>
            </div>

            {/* Footer */}
            <FooterComponent
                target="alliance"
                menu={FOOTER_ALLIANCE_CATEGORIES}
            />
        </>
    );
};

export default LandingComponent;
