import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {CheckCircle, CheckFilled} from 'icons';
import './LandingStepComponent.scss';

const LandingStepComponent = ({img, number, title, description, state}) => {
    const iconState = classNames('ynq-landing-step__icon', {
        'ynq-landing-step__icon--complete': state,
        'ynq-landing-step__icon--incomplete': !state,
    });

    return (
        <div className="ynq-landing-step__container">
            {img}
            <div className="ynq-landing-step__item">
                <div className="ynq-landing-step__content">
                    <span className="ynq-landing-step__number">{number}</span>
                    <div className="ynq-landing-step__text">
                        <span className="ynq-landing-step__title">{title}</span>
                        {description}
                    </div>
                </div>
                <div className={iconState}>
                    {state ? <CheckFilled/> : <CheckCircle/>}
                </div>
            </div>
        </div>
    );
};

LandingStepComponent.propTypes = {
    img: PropTypes.any,
    number: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.any,
    state: PropTypes.bool,
};

export default LandingStepComponent;
