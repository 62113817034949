import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Header from 'components/stateless/header';
import {defaultMenu} from 'components/stateless/header-items';
import {useFormik} from 'formik';
import passwordForm from 'components/password-setup/password-form';
import InputComponent from 'components/form/input';
import ButtonComponent from 'components/button';
import './PasswordSetup.scss';
import Spinner from 'react-bootstrap/Spinner';
import classNames from 'classnames';

const PasswordSetupComponent = () => {
    let history = useHistory();
    const urlName = new URL(window.location.href).searchParams.get('name');
    const email =
        new URL(window.location.href).searchParams.get('email') ||
        sessionStorage.getItem('email') ||
        '';
    const name = sessionStorage.getItem('firstname') || urlName;
    const [recommendation, toggleRecommendation] = useState(false);
    const [asyncInProgress, setAsyncInProgress] = useState(false);
    const env = process.env.REACT_APP_NODE_API;
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
    } = useFormik({
        enableReinitialize: true,
        initialValues: passwordForm.schema,
        validationSchema: passwordForm.validationSchema,
        onSubmit: values => {
            // console.log(JSON.stringify(values, null, 2));
            confirmRegistration(values);
        },
    });

    useEffect(() => {
        bindEmail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const bindEmail = () => {
        if (email) {
            values.email = email;
        }
    };

    const confirmRegistration = formData => {
        const options = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(formData),
        };

        setAsyncInProgress(true);

        fetch(`${env}/api/v2/registration/form/confirm`, options)
            .then(response => Promise.all([response.ok, response.json()]))
            .then(([ok, json]) => {
                if (ok) {
                    sessionStorage.setItem(
                        'registrationComplete',
                        JSON.stringify(true)
                    );
                    history.push('/');
                } else {
                    setAsyncInProgress(false);
                    throw new Error(json.errors[0].message);
                }
            })
            .catch(() => {
                setAsyncInProgress(false);
            });
    };

    return (
        <div className="ynq-password-setup__page">
            {/* Header */}
            <Header bk="login" menu={defaultMenu}/>

            <div className="ynq-password-setup__container">
                <form
                    onSubmit={handleSubmit}
                    className="ynq-password-setup__form"
                >
                    <div className="ynq-password-setup__title">
                        Hey {sessionStorage.getItem('firstname') || name}.
                    </div>
                    <div className="ynq-password-setup__subtitle">
                        Please set your Younique Password.
                    </div>
                    <div className="ynq-password-setup__row">
                        <InputComponent
                            id="email"
                            name="email"
                            type="text"
                            label="Email Address"
                            labelClassName="ynq-password-setup__label"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            className={classNames({
                                'ynq-input--error':
                                    errors.email && touched.email,
                            })}
                        />
                        <span className="ynq-password-setup__validation-msg">
                            {touched.email && errors.email
                                ? errors.email
                                : ''}
                        </span>
                    </div>
                    <div className="ynq-password-setup__row">
                        <InputComponent
                            id="password"
                            name="password"
                            type="password"
                            label="Password"
                            labelClassName="ynq-password-setup__label"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            className={classNames({
                                'ynq-input--error':
                                    errors.password && touched.password,
                            })}
                        />
                        <span className="ynq-password-setup__validation-msg">
                            {touched.password && errors.password
                                ? errors.password
                                : ''}
                        </span>
                    </div>
                    <div className="ynq-password-setup__row">
                        <InputComponent
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            label="Confirm Password"
                            labelClassName="ynq-password-setup__label"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            className={classNames({
                                'ynq-input--error':
                                    errors.confirmPassword &&
                                    touched.confirmPassword,
                            })}
                        />
                        <span className="ynq-password-setup__validation-msg">
                            {touched.confirmPassword && errors.confirmPassword
                                ? errors.confirmPassword
                                : ''}
                        </span>
                    </div>
                    <div className="ynq-password-setup__actions">
                        <ButtonComponent
                            type="submit"
                            className="ynq-button--primary"
                        >
                            {asyncInProgress ? (
                                <Spinner
                                    animation="border"
                                    variant="secondary"
                                />
                            ) : (
                                'Set Password'
                            )}
                        </ButtonComponent>
                        <div className="ynq-password-setup__link">
                            <ButtonComponent
                                onClick={() =>
                                    toggleRecommendation(!recommendation)
                                }
                            >
                                What is a good Password?
                            </ButtonComponent>
                        </div>
                    </div>
                    {recommendation ? (
                        <div className="ynq-password-setup__actions">
                            <ul>
                                <li className="ynq-password-setup__list-item">
                                    * a digit should occur at least once
                                </li>
                                <li className="ynq-password-setup__list-item">
                                    * a lower case letter should occur at least
                                    once
                                </li>
                                <li className="ynq-password-setup__list-item">
                                    * an upper case letter should occur at least
                                    once
                                </li>
                                <li className="ynq-password-setup__list-item">
                                    * no whitespace allowed in the entire string
                                </li>
                                <li className="ynq-password-setup__list-item">
                                    * at least 6 characters
                                </li>
                            </ul>
                        </div>
                    ) : null}
                </form>
            </div>
        </div>
    );
};

export default PasswordSetupComponent;
