import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MenuButton from './menu-button';
import Menu from './mobile-menu';
import { NavLink } from 'react-router-dom';
import arrowRight from '@iconify/icons-fe/arrow-right';
import { Icon } from '@iconify/react';
// import Button from 'components/common/button';
// import { useAuthDataContext } from 'context/auth-context';
import FaqComponent from 'components/faq-modal/FaqComponent';
// import { sendAuthorizationRequest } from '../login/sign-in';
// import { dispatchLogout } from '../login/sign-out';
import './header.css';

const Header = ({
    bk,
    height,
    page,
    titlePosition,
    title,
    crumbs,
    children,
    menu,
}) => {
    // const { authData } = useAuthDataContext();
    const [open, setOpen] = useState(false);
    const [activeModal, setModalActive] = useState(false);

    // const login = async () => {
    //     // authService.authorize();
    //     sendAuthorizationRequest();
    // };
    // const logout = async () => {
    //     // authService.logout();
    //     dispatchLogout();
    // };

    const setModalStatus = state => {
        setModalActive(state);
    };

    // const loginButton = authData ? (
    //     <div className="top-link ml-4">
    //         <Button onClick={logout}>Sign out</Button>
    //     </div>
    // ) : (
    //     <div className="top-link ml-4">
    //         <Button onClick={login}>Sign In</Button>
    //     </div>
    // );

    const isPage = (item, idx) => {
        return item.target === 'page' ? (
            <NavLink key={idx} className="top-link ml-4" to={item.to}>
                {item.label}
            </NavLink>
        ) : null;
    };

    const isModal = (item, idx) => {
        return item.target === 'modal' ? (
            <span
                key={idx}
                className="top-link ml-4"
                role="button"
                onClick={() => setModalStatus(true)}
                tabIndex={0}
            >
                {item.label}
            </span>
        ) : null;
    };

    const renderMenu = (
        <div className="top-menu col-xl-4 col-lg-5 col-md-6 col-sm-9 col-12 text-right d-none d-sm-block">
            {menu?.map((item, idx) => {
                return item.target === 'page'
                    ? isPage(item, idx)
                    : isModal(item, idx);
            })}
            {/* //todo: include after connecting stable API  {loginButton} */}
        </div>
    );

    return (
        <>
            {/* Header */}
            <header className={`bk-${bk} mb-lg-5`}>
                <div
                    className={`App-header h-${height} justify-content-center`}
                >
                    <Row className="top-row justify-content-between col-10 offset-1">
                        <Col className="menu-icon d-flex d-md-none p-0">
                            <MenuButton open={open} setOpen={setOpen} />
                        </Col>
                        <NavLink
                            className="logo col-md-1 col-sm-2 col-10 text-center"
                            to="/home"
                        />

                        <Menu open={open} setOpen={setOpen} />

                        {renderMenu}
                    </Row>
                    <div
                        className={`${page}-heading header-row col-12 col-sm-10 offset-sm-1 text-center`}
                    >
                        <h1 className={`align-self-${titlePosition}`}>
                            <p>{title}</p>
                        </h1>
                        {crumbs ? (
                            <div className="crumbs d-none d-md-flex">
                                {crumbs.map((crumb, index) => (
                                    <NavLink
                                        key={crumb.name}
                                        className="crumb-link"
                                        to={crumb.url}
                                    >
                                        {crumb.name}
                                        {index !== crumbs.length - 1 ? (
                                            <Icon
                                                className="crumbsIcon mx-2"
                                                icon={arrowRight}
                                            />
                                        ) : null}
                                    </NavLink>
                                ))}
                            </div>
                        ) : null}
                        {children}
                    </div>
                </div>
            </header>
            <FaqComponent isActive={activeModal} onClose={setModalStatus} />
        </>
    );
};

Header.propTypes = {
    bk: PropTypes.string,
    height: PropTypes.number,
    page: PropTypes.string,
    titlePosition: PropTypes.string,
    title: PropTypes.string,
    crumbs: PropTypes.array,
    children: PropTypes.any,
    menu: PropTypes.array,
};

export default Header;
