import React, {useState} from 'react';
import FaqComponent from 'components/faq-modal/FaqComponent';

const ChapterCard = () => {
    const [activeModal, setModalActive] = useState(false);
    const setModalStatus = state => {
        setModalActive(state);
    };

    return (
        <>
            <div className="ynq-subscription__chapter-card">
                <div className="ynq-subscription__stepper-title">
                    Company profile.
                </div>
                <div className="ynq-subscription__stepper-title__divider"/>

                <div className="ynq-subscription__know-more-card">
                    <div className="ynq-subscription__know-more-icon"/>
                    <div className="ynq-subscription__know-more-text">
                        Want to know more?
                    </div>
                    <span
                        className="ynq-subscription__know-more-link"
                        role="button"
                        tabIndex={0}
                        onClick={() => setModalStatus(true)}
                    >
                        Read the FAQs
                    </span>
                </div>
            </div>
            <FaqComponent isActive={activeModal} onClose={setModalStatus}/>
        </>
    );
};

export default ChapterCard;
