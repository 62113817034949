import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from '../stateless/header';
import gtag from 'utils/gtag';

import FooterComponent from '../footer/FooterComponent';
import {defaultMenu} from 'components/stateless/header-items';
import {FOOTER_ALLIANCE_CATEGORIES} from 'utils/footer-categories';
import 'components/impressum/Impressum.css';

class Impressum extends React.Component {
    constructor(props) {
        super(props);

        gtag.events.pageView('Impressum');
    }

    render() {
        return (
            <>
                {/* Header */}
                <Header
                    height={460}
                    page={'impressum'}
                    bk={'impressum'}
                    menu={defaultMenu}
                >
                    <div
                        className="row mContainer justify-content-start justify-content-sm-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9 p-0">
                        <div
                            className="impressum-content-container align-items-center col-11 col-sm-9 col-md-6 col-lg-6 col-xl-6 p-0">
                            <div className="impressum-item-content px-2 px-sm-0">
                                <h1 className="impressum-item-title">
                                    <span>Impressum.</span>
                                </h1>
                            </div>
                        </div>
                        <div
                            className="impressum-item-img col-11 col-sm-9 col-md-6 col-lg-5 col-xl-6 p-0 d-none d-md-block">
                            <div className="img-impressum"/>
                        </div>
                    </div>
                </Header>

                {/* Contact info Deutch row */}
                <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                    <Col
                        className="impressum-container de p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                        <Col
                            className="impressum-chapter pb-1 p-lg-0 d-none d-md-block col-xl-2 col-lg-2 col-md-2 col-sm-2 col-10">
                            <div>Deutsch.</div>
                        </Col>
                        <Col className="impressum-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                            <div className="item-content col-sm-10">
                                <span className="item-title">Impressum</span>
                                <span className="item-text pr-0">
                                    YOUNIQUE AG
                                    <br/> Wundtstraße 21
                                    <br/> 14059 Berlin
                                </span>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-subtitle">Telefon.</span>
                                <a
                                    href="tel:+49 (0)30 2201 330 70"
                                    className="item-text pr-0"
                                >
                                    +49 (0)30 2201 330 70
                                </a>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-subtitle">Email.</span>
                                <a
                                    href="mailto:"
                                    className="item-text email pr-0"
                                >
                                    hello@younique.org
                                </a>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-subtitle">
                                    Vorstandsvorsitzender.
                                </span>
                                <span className="item-text pr-0">
                                    Manfred Kaiser
                                </span>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-subtitle">
                                    Vorsitzender des Aufsichtsrats.
                                </span>
                                <span className="item-text pr-0">
                                    Kaweh Niroomand{' '}
                                </span>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-subtitle">
                                    Handelsregister.
                                </span>
                                <span className="item-text pr-0">
                                    Amtsgericht Charlottenburg HRB 210315 B{' '}
                                    <br/>
                                    Steuernummer 27/602/51658
                                </span>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-subtitle">
                                    Haftungshinweis.
                                </span>
                                <span className="item-text pr-0">
                                    Die Inhalte dieser Seiten wurden mit größter
                                    Sorgfalt aufbereitet. Für die Richtigkeit,
                                    Vollständigkeit und Aktualität kann di
                                    YOUNIQUE AG jedoch keine Haftung übernehmen.
                                    Die Inhalte unserer Seiten erhalten Links zu
                                    externen Webseiten Dritter, für deren Inhalt
                                    wir keine Gewähr übernehmen. Für den Inhalt
                                    der verlinkten Seiten sind ausschließlich
                                    deren Betreiber verantwortlich.
                                </span>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-subtitle">
                                    Copyright.
                                </span>
                                <span className="item-text pr-0">
                                    Fotos, Logos, Bilder, Grafiken, Textinhalte,
                                    Sound, Animationen, Videos und andere auf
                                    der Website veröffentlichte Informationen
                                    sowie die Komposition dieser
                                    Inhalte/Elemente auf dieser Internetpräsenz
                                    sind urheberrechtlich geschützt.
                                </span>
                                <span className="item-text-light pr-0">
                                    Copyright © 2020, YOUNIQUE AG. Alle Rechte
                                    vorbehalten. Der Inhalt dieser Websites darf
                                    nicht kopiert, verbreitet, verändert oder
                                    Dritten zugänglich gemacht werden. Die
                                    Nutzung des geistigen Eigentums der YOUNIQUE
                                    AG ist nur mit schriftlicher Zustimmung des
                                    inhaltlich Verantwortlichen der YOUNIQUE AG
                                    erlaubt. Jede Verbreitung, Speicherung,
                                    Sendung und Wieder- bzw. Weitergabe der
                                    Inhalte für gewerbliche Zwecke ist ohne
                                    schriftliche Genehmigung der YOUNQUE AG
                                    ausdrücklich untersagt.
                                </span>
                            </div>
                        </Col>
                    </Col>
                </Row>

                {/* Contact info English row */}
                <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                    <Col
                        className="impressum-container en p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                        <Col
                            className="impressum-chapter pb-1 p-lg-0 d-none d-md-block col-xl-2 col-lg-2 col-md-2 col-sm-2 col-10">
                            <div>English.</div>
                        </Col>
                        <Col className="impressum-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                            <div className="item-content col-sm-10">
                                <span className="item-title">Impressum</span>
                                <span className="item-text pr-0">
                                    YOUNIQUE AG
                                    <br/> Wundtstraße 21
                                    <br/> 14509 Berlin
                                </span>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-subtitle">
                                    Telephone.
                                </span>
                                <a
                                    href="tel:+49 (0)30 2201 330 70"
                                    className="item-text pr-0"
                                >
                                    +49 (0)30 2201 330 70
                                </a>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-subtitle">Email.</span>
                                <a
                                    href="mailto:"
                                    className="item-text email pr-0"
                                >
                                    hello@younique.org
                                </a>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-subtitle">
                                    Chairman of the board.
                                </span>
                                <span className="item-text pr-0">
                                    Manfred Kaiser
                                </span>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-subtitle">
                                    President of the supervisory board.
                                </span>
                                <span className="item-text pr-0">
                                    Kaweh Niroomand{' '}
                                </span>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-subtitle">
                                    Commercial Register.
                                </span>
                                <span className="item-text pr-0">
                                    Amtsgericht Charlottenburg HRB 210315 B{' '}
                                    <br/>
                                    TAX ID 27/602/51658
                                </span>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-subtitle">
                                    Disclaimer.
                                </span>
                                <span className="item-text pr-0">
                                    The content of these pages has been prepared
                                    with great care. However, the YOUNIQUE AG
                                    cannot accept any liability for the
                                    correctness, completeness and actuality of
                                    the information. The content of our pages
                                    contains links to external third-party
                                    websites; for the content of those sites
                                    which we cannot guarantee or be liable. The
                                    operators of the linked pages are solely
                                    responsible for their content.
                                </span>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-subtitle">
                                    Copyright.
                                </span>
                                <span className="item-text pr-0">
                                    Photos, logos, images, graphics, text
                                    content, sound, animations, videos and other
                                    information published on the website as well
                                    as the composition of this content /
                                    elements on this website are protected by
                                    copyright.
                                </span>
                                <span className="item-text-light pr-0">
                                    Copyright © 2020, YOUNIQUE AG. All rights
                                    reserved. The content of this website may
                                    not be copied, distributed, changed or made
                                    accessible to third parties. The use of the
                                    intellectual property of YOUNIQUE AG is only
                                    permitted with the written consent of the
                                    person responsible for the content of
                                    YOUNIQUE AG. Any distribution, storage,
                                    broadcast and reproduction or transfer of
                                    the content for commercial purposes is
                                    expressly prohibited without the prior
                                    written consent of YOUNQUE AG.
                                </span>
                            </div>
                        </Col>
                    </Col>
                </Row>

                {/* Footer */}
                <FooterComponent target="alliance" menu={FOOTER_ALLIANCE_CATEGORIES}/>
            </>
        );
    }
}

export default Impressum;
