import React from 'react';
import Header from 'components/stateless/header';
import Button from 'components/common/button';

import {defaultMenu} from 'components/stateless/header-items';
import {sendAuthorizationRequest} from 'components/login/sign-in';
import 'components/login/Login.css';

const Error = () => {
    return (
        <>
            <div className="ynq-password-setup__page">
                {/* Header */}
                <Header bk="login" menu={defaultMenu}/>

                <div className="ynq-password-setup__container">
                    <div className="ynq-password-setup__form">
                        <div className="ynq-password-setup__title">Error.</div>
                        <div className="ynq-password-setup__subtitle">
                            The username or password you entered is not correct.
                        </div>

                        <div className="ynq-password-setup__actions">
                            <Button onClick={() => sendAuthorizationRequest()}>
                                Please try again
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Error;
