import React from 'react';
import PropTypes from 'prop-types';
import InputComponent from 'components/form/input';
import SelectComponent from 'components/form/select';
import classNames from 'classnames';

const CommonShippingAddress = ({
                                   values,
                                   errors,
                                   touched,
                                   handleChange,
                                   handleBlur,
                                   countries,
                               }) => (
    <>
        <div className="ynq-subscription-profile__row">
            <InputComponent
                id="shippingAddress.street"
                name="shippingAddress.street"
                type="text"
                label="Street"
                labelClassName="ynq-subscription-profile__label"
                value={values.shippingAddress.street}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="-"
                maxLength={255}
                className={classNames({
                    'ynq-input--error':
                        errors.shippingAddress?.street &&
                        touched.shippingAddress?.street,
                })}
            />
        </div>
        <div className="ynq-subscription-profile__row-group">
            <div className="ynq-subscription-profile__input-flex-3">
                <InputComponent
                    id="shippingAddress.city"
                    name="shippingAddress.city"
                    type="text"
                    label="City"
                    labelClassName="ynq-subscription-profile__label"
                    value={values.shippingAddress.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="-"
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.shippingAddress?.city &&
                            touched.shippingAddress?.city,
                    })}
                />
            </div>
            <div className="ynq-subscription-profile__input-flex-1">
                <InputComponent
                    id="shippingAddress.postalCode"
                    name="shippingAddress.postalCode"
                    type="text"
                    label="PostCode"
                    labelClassName="ynq-subscription-profile__label"
                    value={values.shippingAddress.postalCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="-"
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.shippingAddress?.postalCode &&
                            touched.shippingAddress?.postalCode,
                    })}
                />
            </div>
        </div>
        <div className="ynq-subscription-profile__row">
            <SelectComponent
                id="shippingAddress.country"
                name="shippingAddress.country"
                type="text"
                label="Country"
                labelClassName="ynq-subscription-profile__label"
                options={countries}
                value={values.shippingAddress.country}
                optionLabel="name"
                optionValue="name"
                onChange={handleChange}
                onBlur={handleBlur}
                isMulti={false}
                placeholder="-"
                className={classNames({
                    'ynq-input--error':
                        errors.shippingAddress?.country &&
                        touched.shippingAddress?.country,
                })}
            />
        </div>
    </>
);

CommonShippingAddress.propTypes = {
    form: PropTypes.object,
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    countries: PropTypes.array,
};

export default CommonShippingAddress;
