import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/Nav';
import { Icon } from '@iconify/react';
import elipsisV from '@iconify/icons-fe/elipsis-v';
import { NavLink } from 'react-router-dom';
import LinkComponent from 'components/link';
import { SOCIAL_MEDIA } from 'utils/footer-social-media';
import 'components/stateless/mobile-menu.scss';

const Menu = ({ open, setOpen }) => {
    let transform = 'transform-hide';

    if (open) {
        transform = 'transform-show';
    }

    const renderLinks = (links, className) =>
        links.map((item, idx) => (
            <li key={`${idx}-social-link`} className={className}>
                <LinkComponent
                    to={item.url}
                    className={className}
                    icon={item.icon}
                >
                    {item.name}
                </LinkComponent>
            </li>
        ));

    return open ? (
        <Nav className={`nav-bar ${transform}`}>
            <div className="top_b">
                <button
                    className="close dot-menu"
                    onClick={() => setOpen(!open)}
                >
                    <Icon icon={elipsisV} />
                </button>
                <NavLink className="mobile-logo" to="/home" />
            </div>
            <div className="items">
                <div className="mobile-item">
                    <NavLink to="/manifesto" activeClassName="active">
                        Manifesto
                    </NavLink>
                </div>
                {/* <div className="mobile-item">
                    <NavLink to="/join-the-revolution">
                        Join the Revolution
                    </NavLink>
                </div> */}
                <div className="mobile-item">
                    <NavLink to="/careers">Careers</NavLink>
                </div>
                <div className="mobile-item">
                    <NavLink to="/contact">Contact Us</NavLink>
                </div>
            </div>
            <div className="mobile-social">
                <ul className="ynq-mobile__social-link">
                    {renderLinks(SOCIAL_MEDIA, 'ynq-mobile__social-icon')}
                </ul>
            </div>
        </Nav>
    ) : null;
};

Menu.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
};

export default Menu;
