import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './accordion.css';

function Accordion(props) {
    const {data = []} = props;

    const [active, setActive] = useState('');

    return (
        <div className="accordion">
            {data.map(item => (
                <div key={item.name} className="accordion-item">
                    <div
                        className={`accordion-body ${
                            item.name === active
                                ? 'accordion-open'
                                : 'accordion-close'
                        }`}
                        onClick={() =>
                            item.name === active
                                ? setActive('')
                                : setActive(item.name)
                        }
                    >
                        <div className="accordion-name">{item.name}</div>
                        <div className="accordion-icon"/>
                    </div>
                    <div
                        className={
                            item.name === active
                                ? 'accordion-text-open'
                                : 'accordion-text-close'
                        }
                    >
                        <div className="accordion-content">{item.content}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

Accordion.propTypes = {
    data: PropTypes.array,
};

export default Accordion;
