import React from 'react';
import Header from 'components/stateless/header';
import {defaultMenu} from 'components/stateless/header-items';
import FooterComponent from 'components/footer';
import {FOOTER_ALLIANCE_CATEGORIES} from 'utils/footer-categories';
import {termsItems} from 'components/terms/terms-items';
import './TermsComponent.scss';

const TermsComponent = () => {
    return (
        <>
            <Header
                height={460}
                page={'impressum'}
                bk={'impressum'}
                menu={defaultMenu}
            >
                <div
                    className="row mContainer justify-content-start justify-content-sm-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9 p-0">
                    <div
                        className="impressum-content-container align-items-center col-11 col-sm-9 col-md-6 col-lg-6 col-xl-6 p-0">
                        <div className="impressum-item-content px-2 px-sm-0">
                            <h1 className="impressum-item-title">
                                <span>Terms & {`\n`}Conditions.</span>
                            </h1>
                        </div>
                    </div>
                    <div
                        className="impressum-item-img col-11 col-sm-9 col-md-6 col-lg-5 col-xl-6 p-0 d-none d-md-block">
                        <div className="ynq-terms__header-svg-figure"/>
                    </div>
                </div>
            </Header>

            <div className="ynq-terms">
                {termsItems.map((item, idx) => (
                    <div
                        key={idx}
                        className="container--large ynq-terms__container"
                    >
                        <div className="ynq-terms__chapter">
                            <span className="ynq-terms__chapter-title">
                                {item.title}
                            </span>
                        </div>
                        <div className="ynq-terms__content">{item.content}</div>
                    </div>
                ))}
            </div>

            {/* Footer */}
            <FooterComponent
                target="alliance"
                menu={FOOTER_ALLIANCE_CATEGORIES}
            />
        </>
    );
};

export default TermsComponent;
