import React from 'react';
import PropTypes from 'prop-types';
import Times from '../../icons/Times';
import './ModalComponent.scss';

const Modal = ({closeModal, children}) => {
    return (
        <div className="ynq-modal__overlay">
            <div className="ynq-modal__container">
                <div className="ynq-modal__header">
                    <Times
                        onClick={closeModal}
                        className="ynq-modal__close-icon"
                    />
                </div>
                <div className="ynq-modal__content"> {children} </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    closeModal: PropTypes.func,
    children: PropTypes.any,
};

export default Modal;
