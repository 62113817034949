import React from 'react';
import PropTypes from 'prop-types';
import {Lock, MapMarker, Mail, Phone, Calendar, Eye, EyeSlash} from 'icons';
import {INPUT_TYPES} from 'utils/types';

const {email, password, search, tel, calendar} = INPUT_TYPES;

const InputButtonComponent = ({
								  toggleVisibility,
								  visible,
								  initialType,
								  readonly,
							  }) => {
	return (
		<>
			{initialType === password ? (
				<button
					type="button"
					onClick={() => toggleVisibility()}
					className="ynq-input__button"
				>
					{visible ? <EyeSlash/> : <Eye/>}
				</button>
            ) : (
				<span className="ynq-input__button">
                    {initialType === email && <Mail/>}
					{initialType === tel && <Phone/>}
					{initialType === calendar && <Calendar/>}
					{initialType === search && <MapMarker/>}
					{readonly && <Lock/>}
                </span>
            )}
        </>
    );
};

InputButtonComponent.propTypes = {
    toggleVisibility: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    initialType: PropTypes.string.isRequired,
    readonly: PropTypes.bool,
};

export default InputButtonComponent;
