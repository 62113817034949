import React from 'react';
import Header from '../stateless/header';
import Accordion from '../stateless/accordion';
import gtag from '../../utils/gtag';

import FooterComponent from '../footer/FooterComponent';
import faqCategories from './faq-categories';
import {useAuthDataContext} from 'context/auth-context';
import {defaultMenu} from 'components/stateless/header-items';
import {FOOTER_ALLIANCE_CATEGORIES} from 'utils/footer-categories';
import './Faq.scss';

const Faq = () => {
    gtag.events.pageView('Faq');
    const {authData} = useAuthDataContext();
    const renderCategories = () => {
        return faqCategories.map((category, idx) => {
            return (
                <div
                    key={idx}
                    className="row pt-4 pt-sm-5 mb-5 justify-content-center about-the-project"
                >
                    <div className="col-10 col-sm-9 col-md-10 col-lg-8 col-xl-8 chapter p-0">
                        <p>{category.name}</p>
                    </div>
                    <div className="col-10 col-sm-9 col-md-10 col-lg-6 col-xl-6 chapter p-0">
                        <Accordion data={category.items}/>
                    </div>
                </div>
            );
        });
    };

    const renderContactInfo = () => (
        <div className="row pt-4 pt-sm-5 mb-5 justify-content-center about-the-project">
            <div className="ynq-faq-contact p-0">
                <span className="ynq-faq-contact-heading">
                    Did we miss your question?
                </span>
                <span className="ynq-faq-contact-text">
                    Please call or send us an email at{' '}
                    <a href="mailto:">hello@younique.org</a>. We{`'`}re happy to
                    answer any question you may have about patrenering with{' '}
                    <span className="ynq-faq-contact-highlight">Younique</span>.
                </span>
            </div>
        </div>
    );

    return (
        <>
            {/* Header */}
            <Header
                height={authData ? 0 : 600} // TODO change header implementation
                page={'faq'}
                bk={authData ? 'faq-company' : 'faq'}
                menu={defaultMenu}
            >
                <div
                    className="row mContainer justify-content-start justify-content-sm-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9 p-0">
                    <div
                        className="impressum-content-container align-items-center col-11 col-sm-9 col-md-6 col-lg-6 col-xl-6 p-0">
                        <div className="impressum-item-content px-2 px-sm-0">
                            <h1 className="impressum-item-title">
                                <span>
                                    Frequently{`\n`}Asked{`\n`}Questions.
                                </span>
                            </h1>
                        </div>
                    </div>
                </div>
            </Header>

            {renderCategories()}

            {renderContactInfo()}

            {/* Footer */}
            <FooterComponent
                target="alliance"
                menu={FOOTER_ALLIANCE_CATEGORIES}
            />
        </>
    );
};

export default Faq;
