import * as React from "react";

function SvgTimesCircleFilled(props) {
  return (
    <svg
      width={18}
      height={18}
      style={{
        msTransform: "rotate(360deg)",
        WebkitTransform: "rotate(360deg)",
      }}
      viewBox="0 0 1536 1536"
      transform="rotate(360)"
      {...props}
    >
      <path
        d="M1149 994q0-26-19-45L949 768l181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19L768 587 587 406q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5T1153.5 1433 768 1536t-385.5-103T103 1153.5 0 768t103-385.5T382.5 103 768 0t385.5 103T1433 382.5 1536 768z"
        fill="#626262"
      />
    </svg>
  );
}

export default SvgTimesCircleFilled;
