import React from 'react';
import PropTypes from 'prop-types';
import {animated, Transition} from 'react-spring/renderprops.cjs';

const animateFromLeft = {
    from: {
        opacity: 0,
        transform: `translate3d(-100%, 0px, 0px)`,
    },
    enter: {
        opacity: 1,
        transform: 'translate3d(0px, 0px, 0px)',
    },
    leave: {
        opacity: 0,
        transform: `translate3d(50%, 0px, 0px)`,
    },
};

const animateFromRight = {
    from: {
        opacity: 0,
        transform: `translate3d(100%, 0px, 0px)`,
    },
    enter: {
        opacity: 1,
        transform: 'translate3d(0px, 0px, 0px)',
    },
    leave: {
        opacity: 0,
        transform: `translate3d(-50%, 0px, 0px)`,
    },
};

const StepperTransition = ({stepActive, lastStep, children}) => {
    return (
        <Transition
            native
            items={stepActive}
            from={item => {
                return item > lastStep
                    ? animateFromRight.from
                    : animateFromLeft.from;
            }}
            enter={item =>
                item > lastStep ? animateFromRight.enter : animateFromLeft.enter
            }
            leave={item =>
                item > lastStep ? animateFromRight.leave : animateFromLeft.leave
            }
        >
            {/* eslint-disable-next-line react/display-name */}
            {index => props => (
                <animated.div className="ynq-subscription__step" style={props}>
                    {children[index]}
                </animated.div>
            )}
        </Transition>
    );
};

StepperTransition.propTypes = {
    stepActive: PropTypes.number,
    lastStep: PropTypes.number,
    children: PropTypes.any,
};

export default StepperTransition;
