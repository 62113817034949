import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputButtonComponent from './InputButtonComponent';
import {INPUT_TYPES} from 'utils/types';
import {SelectArrow} from 'icons';
import './Input.scss';

const {number, email, password, search, tel, text, calendar} = INPUT_TYPES;

const InputComponent = ({
                            id,
                            label,
                            type,
                            name,
                            value,
                            placeholder,
                            readonly,
                            required,
                            isRequired,
                            minLength,
                            maxLength,
                            pattern,
                            disabled,
                            onChange,
                            onClick,
                            onBlur,
                            className,
                            reference,
                            selectInput,
                            labelClassName,
                            hint,
                        }) => {
    const initialType = type;
    const [visible, changeVisibility] = useState(false);
    const [inputType, changeValue] = useState(type || text);

    const togglePasswordVisibility = () => {
        changeVisibility(!visible);
        changeValue(visible ? password : text);
    };

    const renderButton = () => (
        <InputButtonComponent
            toggleVisibility={togglePasswordVisibility}
            visible={visible}
            initialType={initialType}
            readonly={readonly}
        />
    );

    const inputClasses = classNames('ynq-input', className, {
        'ynq-input--icon-after':
            type === email || type === password || readonly,
        'ynq-input--icon-before':
            type === tel || type === search || type === calendar,
    });

    return (
        <>
            <label htmlFor={id} className={labelClassName}>
                {label}{' '}
                {isRequired && (
                    <span className="ynq-input__required-asterisk">*</span>
                )}
            </label>
            <div className={inputClasses}>
                <input
                    id={id}
                    name={name}
                    value={value}
                    type={inputType}
                    placeholder={placeholder}
                    readOnly={readonly}
                    disabled={disabled}
                    onChange={onChange}
                    onClick={onClick}
                    onBlur={onBlur}
                    required={required}
                    minLength={minLength}
                    maxLength={maxLength}
                    pattern={pattern}
                    ref={reference}
                />
                {(type !== text && type !== number) || readonly
                    ? renderButton()
                    : null}
                {selectInput && (
                    <SelectArrow className="ynq-input__select-arrow"/>
                )}
            </div>
            {hint && <span className="ynq-input__hint">{hint}</span>}
        </>
    );
};

InputComponent.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.any,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    pattern: PropTypes.instanceOf(RegExp),
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    isRequired: PropTypes.bool,
    selectInput: PropTypes.bool,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf([
        number,
        email,
        password,
        search,
        tel,
        text,
        calendar,
    ]).isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    className: PropTypes.string,
    reference: PropTypes.any,
    labelClassName: PropTypes.string,
    hint: PropTypes.string,
};

export default InputComponent;
