import * as React from 'react';

function SvgInstagram(props) {
	return (
		<svg width={24} height={24} viewBox="0 0 23 23" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.496 5.666a5.837 5.837 0 000 11.672 5.834 5.834 0 005.835-5.836 5.834 5.834 0 00-5.835-5.836zm0 9.62a3.788 3.788 0 01-3.788-3.788 3.788 3.788 0 117.575 0 3.788 3.788 0 01-3.787 3.788z"
				fill="#fff"
			/>
			<path
				d="M18.923 5.432a1.362 1.362 0 11-2.725 0 1.362 1.362 0 012.725 0z"
				fill="#fff"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.496.136C8.41.136 8.023.15 6.81.204 5.602.258 4.777.452 4.055.732a5.537 5.537 0 00-2.011 1.312A5.636 5.636 0 00.732 4.056c-.28.721-.474 1.547-.528 2.76C.15 8.025.136 8.413.136 11.498c0 3.085.014 3.473.068 4.686.054 1.209.248 2.034.528 2.76.288.749.68 1.38 1.312 2.012a5.636 5.636 0 002.011 1.312c.722.28 1.547.474 2.76.528 1.213.054 1.596.068 4.685.068 3.089 0 3.472-.014 4.685-.068 1.209-.054 2.034-.248 2.76-.528a5.537 5.537 0 002.011-1.312 5.636 5.636 0 001.313-2.012c.28-.721.473-1.547.527-2.76.054-1.213.068-1.597.068-4.686 0-3.09-.014-3.473-.068-4.686-.054-1.21-.248-2.035-.527-2.76a5.538 5.538 0 00-1.313-2.012 5.635 5.635 0 00-2.01-1.313c-.722-.28-1.548-.473-2.76-.528-1.218-.05-1.606-.063-4.69-.063zm0 2.048c3.034 0 3.39.014 4.59.068 1.11.05 1.71.234 2.11.392.533.208.911.451 1.308.848.397.397.645.776.848 1.308.153.401.343 1.001.392 2.11.055 1.2.068 1.557.068 4.592 0 3.036-.014 3.392-.068 4.592-.05 1.11-.234 1.71-.392 2.11-.207.533-.45.912-.848 1.308a3.47 3.47 0 01-1.308.848c-.4.154-1 .343-2.11.393-1.2.054-1.556.067-4.59.067-3.035 0-3.391-.013-4.59-.067-1.11-.05-1.71-.235-2.111-.393a3.525 3.525 0 01-1.308-.848 3.471 3.471 0 01-.848-1.308c-.153-.4-.343-1-.392-2.11-.054-1.2-.068-1.556-.068-4.592 0-3.035.014-3.391.068-4.591.05-1.11.234-1.71.392-2.111.208-.532.451-.911.848-1.308a3.47 3.47 0 011.308-.848c.401-.153 1-.343 2.11-.392 1.2-.059 1.56-.068 4.59-.068z"
				fill="#fff"
			/>
		</svg>
	);
}

export default SvgInstagram;
