import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {getSessionParameter, isValidSession} from 'components/login/session';
import {dispatchLogout} from 'components/login/sign-out';

const noop = () => {
};

export const AuthDataContext = React.createContext({
    authData: null,
    onLogin: noop,
    onLogout: noop,
    currentUser: noop,
});

const AuthContext = props => {
    let history = useHistory();
    const localState = JSON.parse(localStorage.getItem('isAuthenticated'));
    const [authData, setAuthData] = useState(localState || null);
    const env = process.env.REACT_APP_NODE_API;

    useEffect(() => {
        if (isValidSession()) {
            getCurrentUser();
        } else {
            localStorage.setItem('isAuthenticated', JSON.stringify(false));
            localStorage.removeItem('shareholder');
            setAuthData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCurrentUser = redirect => {
        const token = getSessionParameter('ACCESS_TOKEN');
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        fetch(`${env}/api/alliance/user/me`, options)
            .then(response => {
                return Promise.all([
                    response.ok,
                    response.json(),
                    response.status,
                ]);
            })
            .then(([ok, json, status]) => {
                if (status === 401) {
                    dispatchLogout();

                    return;
                }
                if (ok) {
                    localStorage.setItem('shareholder', JSON.stringify(json));
                }

                if (redirect || !ok) history.push('/');
            })
            .catch(() => {
                history.push('/');
            });
    };

    const onLogin = authData => {
        localStorage.setItem('isAuthenticated', JSON.stringify(authData));
        setAuthData(authData);
        getCurrentUser(true);
    };

    const onLogout = () => {
        localStorage.setItem('isAuthenticated', JSON.stringify(false));
        localStorage.removeItem('shareholder');
        localStorage.removeItem('prevLocationPath');
        setAuthData(false);
    };

    const currentUser = () => {
        getCurrentUser();
    };

    return (
        <AuthDataContext.Provider
            value={{authData, onLogin, onLogout, currentUser}}
            {...props}
        />
    );
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthContext;
