import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useFormik} from 'formik';
import ButtonComponent from 'components/button';
import CommonShippingAddress from 'components/subscription/company-profile/CommonShippingAddress';
import HotelAddress from 'components/subscription/company-profile/HotelAddress';
import CommonLegalRepresentative from 'components/subscription/company-profile/CommonLegalRepresentative';
import CommonContactDetails from 'components/subscription/company-profile/CommonContactDetails';
import profileForm from 'components/subscription/company-profile/profile-form';
import LegalEntity from 'components/subscription/company-profile/LegalEntity';
import {useHistory} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import {useAuthDataContext} from 'context/auth-context';
import {getSessionParameter} from 'components/login/session';
import AlertComponent from 'utils/alert';
import {CheckFilled, TimesCircleFilled} from 'icons';
import {
    bindRegistrationData,
    preSubmit,
} from './profile-form-utils';
import './ProfileComponent.scss';

const defaultEvent = {
    type: '',
    icon: null,
    text: null,
};

const ProfileComponent = ({action}) => {
    let history = useHistory();
    const shareholder = JSON.parse(localStorage.getItem('shareholder'));
    const [event, setEvent] = useState(defaultEvent);
    const [countries, setCountries] = useState([]);
    const [roles, setRoles] = useState([]);
    const {authData, currentUser} = useAuthDataContext();
    const [asyncInProgress, setAsyncInProgress] = useState(false);
    const env = process.env.REACT_APP_NODE_API;
    let {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
        setValues,
    } = useFormik({
        enableReinitialize: true,
        initialValues: profileForm.schema,
        validationSchema: profileForm.validationSchema,
        onSubmit: values => {
            shareholder?.companyProfileCreated
                ? updateProfile(values)
                : createProfile(values);
        },
    });

    useEffect(() => {
        fetchCountries();
        fetchRoles();
        if (shareholder && shareholder.companyProfileCreated) {
            getCompanyProfile();
        } else {
            bindRegistrationData(authData, shareholder, values);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const buttonState = classNames({
        'ynq-button--primary': Object.keys(errors).length === 0,
        'ynq-button--error': Object.keys(errors).length !== 0,
    });

    const fetchCountries = () => {
        fetch(`${env}/api/v1/meta/countries`)
            .then(response => response.json())
            .then(json => {
                setCountries(json);
            });
    };

    const fetchRoles = () => {
        fetch(`${env}/api/v1/registration/form/company/roles`)
            .then(response => response.json())
            .then(json => {
                setRoles(json);
            });
    };

    const dismiss = () => {
        setEvent({
            type: '',
            icon: null,
            text: null,
        });
    };

    const mapProfileData = (authData, profile, values) => {
        if (authData && profile && values) {
            const {
                registrationType,
                legalEntry,
                hotelAddress,
                shippingAddress,
                contactDetails,
                legalRepresentative,
            } = profile;

            values.registrationType = registrationType;
            values.legalRepresentative = {
                ...values.legalRepresentative,
                ...legalRepresentative,
            };
            values.legalEntry = {
                ...values.legalEntry,
                ...legalEntry,
            };
            values.sameShippingAddress = true;
            values.sameHotelAddress = true;
            values.shippingAddress = {
                ...values.shippingAddress,
                ...shippingAddress,
            };
            values.hotelAddress = {
                ...values.hotelAddress,
                ...hotelAddress,
            };
            values.contactDetails = {
                ...values.contactDetails,
                ...contactDetails,
            };
            setValues(values);
        }
    };

    const getCompanyProfile = () => {
        const token = getSessionParameter('ACCESS_TOKEN');
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };

        fetch(`${env}/api/company/form/get`, options)
            .then(response => Promise.all([response.ok, response.json()]))
            .then(([ok, json]) => {
                if (ok) {
                    mapProfileData(authData, json, values);
                }
            })
            .catch(() => {
                setAsyncInProgress(false);
            });
    };

    const createProfile = formData => {
        const data = preSubmit(formData);
        const token = getSessionParameter('ACCESS_TOKEN');
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };

        setAsyncInProgress(true);

        fetch(`${env}/api/company/form/create`, options)
            .then(response => Promise.all([response.ok, response.json()]))
            .then(([ok, json]) => {
                if (ok) {
                    setAsyncInProgress(false);
                    setEvent({
                        type: 'ynq-alert--success',
                        icon: <CheckFilled/>,
                        text: 'Company Profile registered successfully!',
                    });
                    currentUser();
                    history.push('/welcome');
                } else {
                    setAsyncInProgress(false);
                    setEvent({
                        type: 'ynq-alert--error',
                        icon: <TimesCircleFilled/>,
                        text: json.errors[0].message,
                    });
                    throw new Error(json.errors[0].message);
                }
            })
            .catch(() => {
                setAsyncInProgress(false);
            });
    };

    const updateProfile = formData => {
        const data = preSubmit(formData);
        const token = getSessionParameter('ACCESS_TOKEN');
        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };

        setAsyncInProgress(true);

        fetch(`${env}/api/company/form/update`, options)
            .then(response => Promise.all([response.ok, response.json()]))
            .then(([ok, json]) => {
                if (ok) {
                    setAsyncInProgress(false);
                    setEvent({
                        type: 'ynq-alert--success',
                        icon: <CheckFilled/>,
                        text: 'Company Profile updated successfully!',
                    });
                    history.push('/');
                } else {
                    setAsyncInProgress(false);
                    setEvent({
                        type: 'ynq-alert--error',
                        icon: <TimesCircleFilled/>,
                        text: json.errors[0].message,
                    });
                    throw new Error(json.errors[0].message);
                }
            })
            .catch(() => {
                setAsyncInProgress(false);
            });
    };

    return (
        <>
            <form key={'someId'} onSubmit={handleSubmit}>
                <LegalEntity
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    countries={countries}
                    isDisabled={!!shareholder?.companyProfileCreated}
                />
                {!values.sameHotelAddress &&
                values.registrationType === 'HOTEL' && (
                    <div className="ynq-subscription-profile__form-section">
                            <span className="ynq-subscription-profile__section-title">
                                Hotel Address.
                            </span>
                        <HotelAddress
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            countries={countries}
                        />
                    </div>
                )}
                {!values.sameShippingAddress && (
                    <div className="ynq-subscription-profile__form-section">
                        <span className="ynq-subscription-profile__section-title">
                            {values.registrationType === 'CHAIN' &&
                            `Shipping Address Legal Entity.`}
                            {values.registrationType === 'HOTEL' &&
                            `Shipping Address.`}
                        </span>
                        <CommonShippingAddress
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            countries={countries}
                        />
                    </div>
                )}

                <div className="ynq-subscription-profile__form-section">
                    <span className="ynq-subscription-profile__section-title">
                        {values.registrationType === 'CHAIN' &&
                        `Chain Main Contact Details.`}
                        {values.registrationType === 'HOTEL' &&
                        `Hotel Contact Details.`}
                    </span>
                    <CommonContactDetails
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        isDisabled={!!shareholder?.companyProfileCreated}
                    />
                </div>

                <div className="ynq-subscription-profile__form-section">
                    <span className="ynq-subscription-profile__section-title">
                        About the Legal Representative.
                    </span>
                    <CommonLegalRepresentative
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        roles={roles}
                        isDisabled={!!shareholder?.companyProfileCreated}
                    />
                </div>

                {Object.keys(errors).length !== 0 && (
                    <div className="ynq-subscription-profile__error-message">
                        Please check the mandatory fields. It looks as if you
                        missed one or two.
                    </div>
                )}

                <div className="ynq-subscription-profile__next-action">
                    <ButtonComponent type="submit" className={buttonState}>
                        {asyncInProgress ? (
                            <Spinner animation="border" variant="secondary"/>
                        ) : (
                            'Continue'
                        )}
                    </ButtonComponent>
                </div>
                {event.text ? (
                    <div className="ynq-subscription-profile__alert-section">
                        <AlertComponent
                            className={event.type}
                            icon={event.icon}
                            text={event.text}
                            onAction={dismiss}
                        />
                    </div>
                ) : null}
            </form>
        </>
    );
};

ProfileComponent.propTypes = {
    action: PropTypes.func,
};

export default ProfileComponent;
