import * as Yup from 'yup';

const passwordForm = {
    schema: {
        email: sessionStorage.getItem('email') || '',
        password: '',
        confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .email()
            .required('Required field'),
        password: Yup.string()
            .matches(
                /([^\s]+){6,255}$/,
                'Please check "What is a good password recommendation"'
            )
            .required('Required field'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match!')
            .required('Required field'),
    }),
};

export default passwordForm;
