import * as React from 'react';

function SvgEye(props) {
	return (
		<svg width={24} height={24} viewBox="2 2 28 28" fill="none" {...props}>
			<path
				d="M29.227 15.467C26.533 9.213 21.467 5.333 16 5.333c-5.467 0-10.533 3.88-13.227 10.134a1.334 1.334 0 000 1.066C5.467 22.787 10.533 26.667 16 26.667c5.467 0 10.533-3.88 13.227-10.134a1.334 1.334 0 000-1.066zM16 24c-4.227 0-8.227-3.053-10.533-8C7.773 11.053 11.773 8 16 8c4.227 0 8.227 3.053 10.533 8-2.306 4.947-6.306 8-10.533 8zm0-13.333a5.333 5.333 0 100 10.666 5.333 5.333 0 000-10.666zm0 8a2.667 2.667 0 110-5.334 2.667 2.667 0 010 5.334z"
				fill="#504F66"
			/>
		</svg>
	);
}

export default SvgEye;
