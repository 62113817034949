import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FooterComponent from '../footer/FooterComponent';
import Header from 'components/stateless/header';
import gtag from 'utils/gtag';

import MembershipBanner from 'components/stateless/membership-banner';
import {subscriptionMenu} from 'components/stateless/header-items';
import {FOOTER_CUSTOMER_CATEGORIES} from 'utils/footer-categories';
import 'components/manifesto-b2b/ManifestoB2B.css';

class ManifestoB2B extends React.Component {
    constructor(props) {
        super(props);

        gtag.events.pageView('ManifestoB2B');
    }

    render() {
        return (
            <>
                {/* Header */}
                <Header
                    height={600}
                    page={'general'}
                    bk={'manifesto b2b'}
                    alliance
                    menu={subscriptionMenu}
                >
                    <div
                        className="row mContainer justify-content-start justify-content-sm-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9 p-0">
                        <div className="manifesto-content-container col-11 col-sm-9 col-md-6 col-lg-6 col-xl-6 p-0">
                            <div className="manifesto-item-content px-2 px-sm-0">
                                <h1 className="manifesto-item-title">
                                    <span>Manifesto.</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </Header>

                {/* Content */}
                <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                    <Col
                        className="manifesto-container p-md-0 d-md-flex justify-content-center justify-content-md-between">
                        <Col
                            className="manifesto-chapter pb-1 p-md-0"
                            xs={10}
                            sm={9}
                            md={10}
                            lg={5}
                            xl={6}
                        >
                            <div>This is what we stand for</div>
                        </Col>
                        <Col
                            className="manifesto-text p-md-0"
                            xs={10}
                            sm={9}
                            md={10}
                            lg={5}
                            xl={6}
                        >
                            Younique is a whole new concept of booking
                            accommodation. It might even lead to a new way to
                            use and conceive the Internet. Today, the online
                            world is dominated by large technology companies,
                            which take significant shares from many different
                            businesses in the form of intermediary commissions,
                            advertising fees or many other kinds of royalties.
                            These costs can go up to 30% of the value of a
                            transaction. Such fees are often unstainable for
                            businesses and ultimately increase the price for the
                            consumer. We, at Younique, want to change this. We
                            are determined to change things in our beautiful
                            hotel and accommodation industry, expecting other
                            industries will follow suit soon. What’s in it for
                            the customer? Here is what you, as a traveller, are
                            used to: you go online to look for a hotel and you
                            find virtually endless choice. Most likely you are
                            on the website of a large technology company, being
                            it an online travel agent, a Metasearch site or the
                            virtually only “search” engine - Google. You
                            probably like the way how these systems work,
                            because you are used to them, and those companies
                            have done their best to get you “hooked”. The
                            question is, do they have your best interest at
                            heart, or just their own? Are you getting the hotel
                            which fits best for you… and at the best rate? So,
                            what makes us different? We are an ambitious
                            alliance of hoteliers, which came together to change
                            the status quo, and to bring some fairness and
                            sustainability back into our business. We want you,
                            the traveller and our guest, to be again in the
                            spotlight and the center of the attention. We want
                            to shorten the distance between guest and hotel by
                            removing intermediaries, which bring little value
                            but significant costs and complexity into the
                            process. We want you to rest assured that whatever
                            you choose to book is going to meet not only your
                            standards, but your expectations, your tastes, your
                            preferences, your unique needs? This is our
                            commitment. At Younique, we make sure that you are
                            always provided a personalized range of options. We
                            use the preferences you share with us and your
                            travel history to give you the best fit for your
                            accommodation. Our results are solely based on your
                            preferences and not defined by commercial interests
                            - we do not put the hotels who pay most on top of
                            the list, but have no other factor playing into our
                            search results than your preferences. We want to be
                            closer to you and that you feel relaxed about the
                            booking process, and we are committed to take as
                            much stress as possible away from your trip
                            organizing phase. We know you love to travel. We
                            want to make it even more fun to organize, by
                            removing everything that is not relevant to you. We
                            even want to go further and share our savings, so
                            that you ultimately also pay less. Our loyalty
                            program is designed to provide you with best rates
                            and extra services, you will not get on other
                            channels. Again, you and your experience are back on
                            center stage. Personal, personalized, fair and
                            sustainable: these are the values and the core of
                            our alliance and the way we were born and are
                            organized. <br/> <br/> Discover Younique, a whole
                            new concept of booking accommodation.
                        </Col>
                    </Col>
                </Row>

                {/* Footer */}
                <FooterComponent target="customer" menu={FOOTER_CUSTOMER_CATEGORIES}>
                    <MembershipBanner/>
                </FooterComponent>
            </>
        );
    }
}

export default ManifestoB2B;
