import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useAuthDataContext} from 'context/auth-context';
import {sendAuthorizationRequest} from 'components/login/sign-in';

const PrivateRoute = ({component: Component, ...otherProps}) => {
    const {authData} = useAuthDataContext();

    return (
        <Route
            exact
            {...otherProps}
            render={props =>
                authData ? (
                    <Component {...props} />
                ) : otherProps.redirectTo ? (
                    <Redirect to={otherProps.redirectTo}/>
                ) : (
                    sendAuthorizationRequest()
                )
            }
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
};
export default PrivateRoute;
