import React from "react";
import FooterComponent from '../footer/FooterComponent';
import Header from "components/stateless/header";
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col';
import {items} from 'components/careers/career-items';
import RevolutionItem from 'components/stateless/revolution-item';
import {NavLink} from 'react-router-dom';
import {Icon} from '@iconify/react';
import arrowBottom from '@iconify/icons-bytesize/arrow-bottom';
import {jobs} from 'components/job-details/job-details';
import {HashLink as Link} from 'react-router-hash-link';
import gtag from 'utils/gtag';

import {defaultMenu} from 'components/stateless/header-items';
import {FOOTER_ALLIANCE_CATEGORIES} from 'utils/footer-categories';
import 'components/careers/Careers.css';

class Careers extends React.Component {
	constructor(props) {
		super(props);

		gtag.events.pageView('Careers');
	}

	render() {
		return (
			<>
				{/* Header */}
				<Header
					title={`Our Younique Principles,\nValues & Culture.`}
					height={600}
					page={'home'}
					bk={'career'}
					titlePosition={'start'}
					menu={defaultMenu}
				>
					<div className="careers-anchor d-none d-md-block">
						<Link to="#positions">See all the openings<Icon className="anchorIcon"
																		icon={arrowBottom}/></Link>
					</div>
				</Header>

				{/* Contact info row */}
				<Row className="pt-4 pt-md-4 pt-lg-5 m-0">
					<Col className="c-img col-sm-11 col-md-6 col-11"/>
					<Col className="c-content col-lg-6 col-md-6 col-12 mt-lg-5 pt-lg-5">
						{items.map(item =>
							<RevolutionItem key={item.itemNumber}
											itemNumber={item.itemNumber}
											itemTitle={item.itemTitle}
											isOdd={item.isOdd}
											useImg={item.useImg}
											itemContent={item.itemContent}/>)}
					</Col>
				</Row>

				<Row className="middle-img mb-md-3 mb-lg-5">
					<Col className="middle-text-container d-flex justify-content-center align-items-center">
						<div className="middle-text mx-3">
							The future belongs to those who believe in the beauty of their dreams
							<p>— Eleanor Roosevelt</p>
						</div>
					</Col>
				</Row>

				<Row id="positions" className="px-2 pt-4 pt-md-2 pt-lg-5 justify-content-center px-sm-5 m-0">
					<Col className="career-container p-0 p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
						<Col className="career-chapter pb-1 p-lg-0 col-xl-2 col-lg-2 col-md-10 col-sm-10 col-10">
							<div>Open positions.</div>
						</Col>
						<Col className="career-text p-lg-0 p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
							{jobs.map(job =>
								<NavLink key={job.route} to={`/careers/${job.route}`}>
									<div className="job">
										<div className="job-location">
											<Icon className="buttonIcon" icon={job.icon}/>
											{job.isRemote ? 'Remote' : job.location}
										</div>
										<div className="job-time-base">{job.timeBase}</div>
										<div className="job-title">{job.title}</div>
									</div>
								</NavLink>
							)}
						</Col>
					</Col>
				</Row>

				{/* Footer */}
				<FooterComponent target="alliance" menu={FOOTER_ALLIANCE_CATEGORIES}/>
			</>
		)
	}
}

export default Careers;
