import React, {useState} from 'react';
import Header from 'components/stateless/header';

import {defaultMenu} from 'components/stateless/header-items';
import 'components/login/Login.css';
import InputComponent from 'components/form/input';
import classNames from 'classnames';
import {useFormik} from 'formik';
import ButtonComponent from 'components/button';
import Spinner from 'react-bootstrap/Spinner';
import resetForm from 'components/reset-pasword/reset-form';
import {TimesCircleFilled} from 'icons';
import AlertComponent from 'utils/alert';
import {getSessionParameter} from 'components/login/session';
import {dispatchLogout} from 'components/login/sign-out';

const defaultEvent = {
    type: '',
    icon: null,
    text: null,
};

const ResetPassword = () => {
    const [asyncInProgress, setAsyncInProgress] = useState(false);
    const [event, setEvent] = useState(defaultEvent);
    const [recommendation, toggleRecommendation] = useState(false);
    const env = process.env.REACT_APP_NODE_API;
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
    } = useFormik({
        initialValues: resetForm.schema,
        validationSchema: resetForm.validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            // console.log(JSON.stringify(values, null, 2));
            reset(values);
        },
    });

    const reset = values => {
        const token = getSessionParameter('ACCESS_TOKEN');
        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values),
        };

        setAsyncInProgress(true);

        fetch(`${env}/api/alliance/user/changePasswordByUser`, options)
            .then(response => Promise.all([response.ok, response.json()]))
            .then(([ok, json]) => {
                if (ok) {
                    setAsyncInProgress(false);
                    dispatchLogout();
                } else {
                    setAsyncInProgress(false);
                    setEvent({
                        type: 'ynq-alert--error',
                        icon: <TimesCircleFilled/>,
                        text: json.errors[0].message,
                    });
                    throw new Error(json.errors[0].message);
                }
            })
            .catch(() => {
                setAsyncInProgress(false);
            });
    };

    const dismiss = () => {
        setEvent({
            type: '',
            icon: null,
            text: null,
        });
    };

    return (
        <div className="ynq-password-setup__page">
            {/* Header */}
            <Header bk="login" menu={defaultMenu}/>

            <div className="ynq-password-setup__container">
                <form
                    className="ynq-password-setup__form"
                    onSubmit={handleSubmit}
                >
                    <div className="ynq-password-setup__title">
                        Reset Password
                    </div>

                    <div className="ynq-password-setup__row">
                        <InputComponent
                            id="oldPassword"
                            name="oldPassword"
                            type="password"
                            label="Old Password"
                            labelClassName="ynq-password-setup__label"
                            value={values.oldPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            className={classNames({
                                'ynq-input--error':
                                    errors.oldPassword && touched.oldPassword,
                            })}
                        />
                    </div>
                    <div className="ynq-password-setup__row">
                        <InputComponent
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            label="New Password"
                            labelClassName="ynq-password-setup__label"
                            value={values.newPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            className={classNames({
                                'ynq-input--error':
                                    errors.newPassword && touched.newPassword,
                            })}
                        />
                    </div>
                    <div className="ynq-password-setup__row">
                        <InputComponent
                            id="confirmNewPassword"
                            name="confirmNewPassword"
                            type="password"
                            label="confirm new Password"
                            labelClassName="ynq-password-setup__label"
                            value={values.confirmNewPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            className={classNames({
                                'ynq-input--error':
                                    errors.confirmNewPassword &&
                                    touched.confirmNewPassword,
                            })}
                        />
                    </div>

                    <div className="ynq-password-setup__actions">
                        <ButtonComponent
                            type="submit"
                            className="ynq-button--primary"
                        >
                            {asyncInProgress ? (
                                <Spinner
                                    animation="border"
                                    variant="secondary"
                                />
                            ) : (
                                'Reset'
                            )}
                        </ButtonComponent>
                        <div className="ynq-password-setup__link">
                            <ButtonComponent
                                onClick={() =>
                                    toggleRecommendation(!recommendation)
                                }
                            >
                                What is a good Password?
                            </ButtonComponent>
                        </div>
                    </div>
                    {recommendation ? (
                        <div className="ynq-password-setup__actions">
                            <ul>
                                <li className="ynq-password-setup__list-item">
                                    * a digit should occur at least once
                                </li>
                                <li className="ynq-password-setup__list-item">
                                    * a lower case letter should occur at least
                                    once
                                </li>
                                <li className="ynq-password-setup__list-item">
                                    * an upper case letter should occur at least
                                    once
                                </li>
                                <li className="ynq-password-setup__list-item">
                                    * no whitespace allowed in the entire string
                                </li>
                                <li className="ynq-password-setup__list-item">
                                    * at least 6 characters
                                </li>
                            </ul>
                        </div>
                    ) : null}
                    {event.text ? (
                        <div className="ynq-subscription-profile__alert-section">
                            <AlertComponent
                                className={event.type}
                                icon={event.icon}
                                text={event.text}
                                btnPosition={'outer'}
                                onAction={dismiss}
                            />
                        </div>
                    ) : null}
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
