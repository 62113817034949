export const defaultMenu = [
    {
        label: 'Manifesto',
        to: '/manifesto',
        target: 'page',
    },
    // {
    //     label: 'Join the Revolution',
    //     to: '/join-the-revolution',
    //     target: 'page',
    // },
    {
        label: 'FAQs',
        to: '/faq',
        target: 'page',
    },
    {
        label: 'Careers',
        to: '/careers',
        target: 'page',
    },
    {
        label: 'Contact Us',
        to: '/contact',
        target: 'page',
    },
];

export const subscriptionMenu = [
    {
        label: 'Manifesto',
        to: '/manifesto-b2b',
        target: 'page',
    },
    {
        label: 'FAQs',
        to: '/faq',
        target: 'modal',
    },
];

export const registrationMenu = [
    {
        label: 'Manifesto',
        to: '/manifesto',
        target: 'page',
    },
    // {
    //     label: 'Join the Revolution',
    //     to: '/join-the-revolution',
    //     target: 'page',
    // },
    {
        label: 'FAQs',
        to: '/faq',
        target: 'modal',
    },
    {
        label: 'Careers',
        to: '/careers',
        target: 'page',
    },
    {
        label: 'Contact Us',
        to: '/contact',
        target: 'page',
    },
];
