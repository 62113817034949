import React from 'react';
import PropTypes from 'prop-types';
import Select, {components} from 'react-select';
import SelectArrow from 'icons/SelectArrow';
import './Select.scss';

const SelectComponent = ({
                             id,
                             name,
                             options,
                             isMulti,
                             placeholder,
                             onChange,
                             onBlur,
                             isRequired,
                             error,
                             touched,
                             value,
                             label,
                             labelClassName,
                             optionLabel,
                             optionValue,
                             isDisabled,
                         }) => {
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <SelectArrow/>
            </components.DropdownIndicator>
        );
    };

    const patchedOnChange = value => {
        onChange({target: {name: name, value: value}});
    };

    const onBlurHandler = value => {
        onBlur({target: {value: value, name: name}});
    };

    const valueFromId = (opts, id) => {
        return opts.find(o => o.name === id);
    };

    return (
        <div className="ynq-select-wrapper">
            <label htmlFor={id} className={labelClassName}>
                {label}{' '}
                {isRequired && (
                    <span className="ynq-select__required-asterisk">*</span>
                )}
            </label>
            <Select
                id={id}
                name={name}
                getOptionLabel={option => option[optionLabel]}
                getOptionValue={option => option[optionValue]}
                className="ynq-select"
                classNamePrefix="ynq-select"
                components={{DropdownIndicator}}
                placeholder={placeholder}
                options={options}
                isMulti={isMulti}
                isDisabled={isDisabled}
                onChange={patchedOnChange}
                onBlur={onBlurHandler}
                value={valueFromId(options, value)}
            />
            {!!error && touched && (
                <div className="ynq-inputs-message--error">{error}</div>
            )}
        </div>
    );
};

SelectComponent.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    isRequired: PropTypes.bool,
    error: PropTypes.any,
    touched: PropTypes.any,
    value: PropTypes.any,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    optionLabel: PropTypes.string,
    optionValue: PropTypes.string,
    isDisabled: PropTypes.bool,
};

export default SelectComponent;
