import React from 'react';
import Header from '../stateless/header';
import {privacyItems} from './privacy-items';
import FooterComponent from '../footer/FooterComponent';
import {defaultMenu} from 'components/stateless/header-items';
import {FOOTER_ALLIANCE_CATEGORIES} from 'utils/footer-categories';
import './PrivacyComponent.scss';

const PrivacyComponent = () => {
    return (
        <>
            {/* Header */}
            <Header
                height={600}
                page={'impressum'}
                bk={'data-privacy'}
                menu={defaultMenu}
            >
                <div
                    className="row mContainer justify-content-start justify-content-sm-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9 p-0">
                    <div
                        className="impressum-content-container align-items-center col-11 col-sm-9 col-md-6 col-lg-6 col-xl-6 p-0">
                        <div className="impressum-item-content px-2 px-sm-0">
                            <h1 className="impressum-item-title">
                                <span>Data {`\n`}Privacy.</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </Header>

            <div className="ynq-privacy">
                {privacyItems.map((item, idx) => (
                    <div
                        key={idx}
                        className="container--large ynq-privacy__container"
                    >
                        <div
                            className={`ynq-privacy__chapter ${
                                idx > 0 ? '' : 'ynq-privacy__chapter--no-border'
                            }`}
                        >
                            <span className="ynq-privacy__chapter-title">
                                {item.title}
                            </span>
                        </div>
                        <div className="ynq-privacy__content">
                            {item.content}
                        </div>
                    </div>
                ))}
            </div>

            {/* Footer */}
            <FooterComponent
                target="alliance"
                menu={FOOTER_ALLIANCE_CATEGORIES}
            />
        </>
    );
};

export default PrivacyComponent;
