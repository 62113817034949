import * as React from 'react';

function SvgLogomarkTransparent(props) {
	return (
		<svg width={230} height={244} fill="none" {...props}>
			<g opacity={0.2}>
				<path
					d="M226.286 79.759c-2.819-15.131-26.313-16.107-26.313-16.107s18.325-16.351 10.572-26.113c-9.867-13.178-37.119-7.077-37.119-7.077s23.493-18.791 9.397-26.113c-21.379-11.714-63.668 1.708-87.631 22.696C44.681 35.1 6.152 80.247 6.152 134.913 6.151 195.191 53.137 244 111.167 244c58.029 0 105.016-48.809 105.016-109.087 0-10.006-1.41-19.768-3.759-29.042 4.933-11.47 15.271-18.059 13.861-26.112z"
					fill="#6972B4"
				/>
				<path
					d="M160.974 145.895c-3.524 45.636-39.234 75.165-83.167 72.236-43.933-2.928-73.77-36.362-73.535-85.415.235-45.88 43.698-91.028 73.77-53.201 49.101-17.328 86.691 17.327 82.932 66.38z"
					fill="#030224"
				/>
				<path
					d="M114.979 150.288c7.653-.164 13.73-6.742 13.573-14.693-.158-7.95-6.491-14.262-14.144-14.099-7.654.164-13.731 6.742-13.573 14.693.157 7.95 6.49 14.262 14.144 14.099zM30.35 123.442c5.968 0 10.807-5.026 10.807-11.226 0-6.199-4.839-11.226-10.807-11.226-5.969 0-10.807 5.027-10.807 11.226 0 6.2 4.838 11.226 10.807 11.226zM83.915 167.126L40.452 154.68c-4.464-1.22-8.458 2.929-7.048 7.565 2.584 8.542 11.042 18.304 22.789 21.476 11.746 3.417 23.493-.244 30.071-6.101 3.29-3.172 2.115-9.029-2.349-10.494z"
					fill="#6972B4"
				/>
			</g>
		</svg>
	);
}

export default SvgLogomarkTransparent;
