import * as React from 'react';

function SvgMail(props) {
	return (
		<svg width={24} height={24} fill="none" {...props}>
			<path
				d="M19 4H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V7a3 3 0 00-3-3zm-.41 2l-5.88 5.88a1.002 1.002 0 01-1.42 0L5.41 6h13.18zM20 17a1 1 0 01-1 1H5a1 1 0 01-1-1V7.41l5.88 5.88a3 3 0 004.24 0L20 7.41V17z"
				fill="#6972B4"
			/>
		</svg>
	);
}

export default SvgMail;
