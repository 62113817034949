import React from 'react';
import TextInput from './text-input';
import ReactSelect from './react-select';
import ReactCheckbox from './checkbox';
import TextArea from './textarea';
import DragAndDrop from './drag-and-drop';
import TextField from '@material-ui/core/TextField';
import Captcha from './captcha';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {makeStyles} from '@material-ui/core/styles';
import RadioGroupComponent from '../components/form/radio-group';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormLabel-asterisk': {
            color: 'red !important',
        },
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}));

const FormControl = props => {
    // eslint-disable-next-line no-unused-vars
    const getControlType = item => {
        switch (item.type) {
            case 'text':
                return !item.hidden ? (
                    <TextInput
                        name={item.name}
                        placeholder={item.placeholder}
                        value={item.value}
                        onChange={props.onClick}
                        label={item.label}
                        touched={
                            item.touched ? item.touched.toString() : undefined
                        }
                        valid={item.valid ? item.valid.toString() : undefined}
                        inputProps={{
                            maxLength: item.validationRules.maxLength,
                        }}
                    />
                ) : null;
            case 'react-select':
                return !item.hidden ? (
                    <ReactSelect
                        name={item.name}
                        optionLabel={option => option[item.optionLabel]}
                        optionValue={option => option[item.optionValue]}
                        options={props.dataset[item.optionSelector]}
                        value={item.value}
                        onChange={props.onClick}
                        label={item.label}
                        placeholder={item.placeholder}
                        touched={
                            item.touched ? item.touched.toString() : undefined
                        }
                        valid={item.valid ? item.valid.toString() : undefined}
                    />
                ) : null;
            case 'text-area':
                return !item.hidden ? (
                    <TextArea
                        name={item.name}
                        placeholder={item.placeholder}
                        rows={item.rows}
                        value={item.value}
                        onChange={props.onClick}
                        label={item.label}
                        touched={
                            item.touched ? item.touched.toString() : undefined
                        }
                        valid={item.valid ? item.valid.toString() : undefined}
                        inputProps={{
                            maxLength: item.validationRules.maxLength,
                        }}
                    />
                ) : null;
            case 'file':
                return !item.hidden ? (
                    <DragAndDrop
                        onDrop={props.onDrop}
                        accept={props.accept}
                        minSize={props.minSize}
                        maxSize={props.maxSize}
                        file={props.file}
                    />
                ) : null;
            default:
                return null;
        }
    };

    const getMaterialControlType = (item, styles) => {
        switch (item.type) {
            case 'text':
                return !item.hidden ? (
                    <>
                        <TextField
                            name={item.name}
                            margin="normal"
                            fullWidth
                            required={item.required}
                            error={item.touched && !item.valid}
                            classes={{root: styles.root}}
                            placeholder={item.placeholder}
                            value={item.value}
                            onChange={props.onClick}
                            label={item.label}
                            touched={
                                item.touched
                                    ? item.touched.toString()
                                    : undefined
                            }
                            valid={
                                item.valid ? item.valid.toString() : undefined
                            }
                            inputProps={{
                                maxLength: item.validationRules.maxLength,
                            }}
                        />
                        {item.hint ? (
                            <span style={{fontSize: '12px'}}>
                                {item.hint}
                            </span>
                        ) : null}
                    </>
                ) : null;
            case 'react-select':
                return !item.hidden ? (
                    <Autocomplete
                        options={props.dataset[item.optionSelector]}
                        getOptionLabel={option =>
                            option[item.optionLabel]
                                ? option[item.optionLabel]
                                : ''
                        }
                        getOptionSelected={(option, value) =>
                            value.value === option.value
                        }
                        classes={{option: styles.option}}
                        value={item.value}
                        onChange={(event, newValue) => {
                            props.onClick({
                                target: {name: item.name, value: newValue},
                            });
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                margin="normal"
                                required={item.required}
                                classes={{root: styles.root}}
                                error={item.touched && !item.valid}
                                label={item.label}
                                placeholder={item.placeholder}
                                touched={
                                    item.touched
                                        ? item.touched.toString()
                                        : undefined
                                }
                                valid={
                                    item.valid
                                        ? item.valid.toString()
                                        : undefined
                                }
                            />
                        )}
                    />
                ) : null;
            case 'text-area':
                return !item.hidden ? (
                    <TextField
                        name={item.name}
                        margin="normal"
                        fullWidth
                        multiline={true}
                        rows={item.rows}
                        rowsMax={item.rows}
                        required={item.required}
                        error={item.touched && !item.valid}
                        classes={{root: styles.root}}
                        placeholder={item.placeholder}
                        value={item.value}
                        onChange={props.onClick}
                        label={item.label}
                        touched={
                            item.touched ? item.touched.toString() : undefined
                        }
                        valid={item.valid ? item.valid.toString() : undefined}
                        inputProps={{
                            maxLength: item.validationRules.maxLength,
                        }}
                    />
                ) : null;
            case 'file':
                return !item.hidden ? (
                    <DragAndDrop
                        onDrop={props.onDrop}
                        accept={item.accept}
                        minSize={item.minSize}
                        maxSize={item.maxSize}
                        file={props.file}
                    />
                ) : null;
            case 'element':
                return !item.hidden ? item.value : null;
            case 'checkbox':
                return !item.hidden ? (
                    <ReactCheckbox
                        name={item.name}
                        label={item.label}
                        checked={item.value}
                        onChange={props.onClick}
                    />
                ) : null;
            case 'radio':
                return !item.hidden ? (
                    <RadioGroupComponent
                        options={item.options}
                        name={item.name}
                        label={item.label}
                        onChange={props.onClick}
                        value={item.value}
                        checked={item.defaultValue}
                        touched={
                            item.touched ? item.touched.toString() : undefined
                        }
                        valid={item.valid ? item.valid.toString() : undefined}
                    />
                ) : null;
            case 'captcha':
                return !item.hidden ? (
                    <>
                        <div style={{fontSize: 14, color: 'red'}}>
                            {item.touched && !item.valid
                                ? 'Re-Captcha verification is required'
                                : ''}
                        </div>
                        <Captcha
                            rf={props.rf}
                            name={item.name}
                            onChange={props.onClick}
                            value={item.value}
                            valid={
                                item.valid ? item.valid.toString() : undefined
                            }
                        />
                    </>
                ) : null;
            default:
                return null;
        }
    };

    const styles = useStyles();

    return (
        <>
            {props.controls.map(item => (
                <div
                    key={item.name}
                    className={`col-sm-${item.gridSizeClass} pt-2 adjust-input`}
                >
                    {getMaterialControlType(item, styles)}
                    {/*{getControlType(item, styles)} TODO use it for bootstrap forms */}
                    {item.valid}
                </div>
            ))}
        </>
    );
};

export default FormControl;
