import * as React from 'react';

function SvgRadioChecked(props) {
	return (
		<svg width={16} height={16} viewBox="0 0 24 24" fill="none" {...props}>
			<circle cx={12} cy={12} r={12} fill="#6972B4"/>
			<circle cx={12} cy={12} r={2.571} fill="#fff"/>
		</svg>
	);
}

export default SvgRadioChecked;
