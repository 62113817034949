export const items = [
	{
		itemNumber: "01.",
		itemTitle: "Revolution.",
		itemContent: "A revolution in the hotel industry, a new player that wants to change the rules of the game from the inside.",
		useImg: true,
		itemImg: "img-1",
		isOdd: true
	},
	{
		itemNumber: "02.",
		itemTitle: "Alliance.",
		itemContent: "An alliance of hoteliers who come together to create their own alternative to today’s OTA’s. An alliance with the aim to serve its members and not to make profits.",
		useImg: true,
		itemImg: "img-2",
		isOdd: false
	},
	{
		itemNumber: "03.",
		itemTitle: "Platform.",
		itemContent: "A platform that customers will love, where they can search, find and book a vast choice of hotels for a wide range of taste and pockets. Highly customized…",
		useImg: true,
		itemImg: "img-3",
		isOdd: true
	},
	{
		itemNumber: "04.",
		itemTitle: "Fair Alternative.",
		itemContent: "A fair alternative to the status quo. Current OTA’s have got you in the corner. On Younique, every hotel and every shareholder is treated the same way…",
		useImg: true,
		itemImg: "img-4",
		isOdd: false
	},
	{
		itemNumber: "05.",
		itemTitle: "Control your Future.",
		itemContent: "A way to regain control of your future. The hotels as an industry are paying billions of dollars to the OTA’s and Google, which they invest in marketing to gain…",
		useImg: true,
		itemImg: "img-5",
		isOdd: true
	},
	{
		itemNumber: "06.",
		itemTitle: "A Brave Move.",
		itemContent: "A brave move. Younique is for hotel owners and operators who are tired of the status quo. Who feel the pain and want to change things. It’s impossible…",
		useImg: true,
		itemImg: "img-6",
		isOdd: false
	},
	{
		itemNumber: "07.",
		itemTitle: "Disruptive.",
		itemContent: "A disruptive yet very sensible idea which can change the world.",
		useImg: true,
		itemImg: "img-7",
		isOdd: true
	}
];
