import adjustCircle from '@iconify/icons-uil/adjust-circle';
import buildingIcon from '@iconify/icons-uil/building';
import userCircle from '@iconify/icons-uil/user-circle';

export const formControls = [
    {
        name: 'Legal information',
        icon: adjustCircle,
        controls: [
            {
                type: 'radio',
                id: 'registrationType',
                name: 'registrationType',
                value: 'CHAIN',
                defaultValue: 'CHAIN',
                label: 'Hotel Chain or Single Hotel',
                options: [
                    {label: 'Single Hotel', value: 'HOTEL'},
                    {label: 'Hotel Chain', value: 'CHAIN'},
                ],
                placeholder: '--',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: true,
                validationRules: {
                    isRequired: true,
                },
            },
            {
                type: 'text',
                name: 'hotelOrChainName',
                value: '',
                label: 'Name of the Hotel or Hotel Chain',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: true,
                validationRules: {
                    minLength: 1,
                    maxLength: 255,
                    isRequired: true,
                },
            },
            {
                type: 'text',
                name: 'legalName',
                value: '',
                label: 'Name of the Legal Entity',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: true,
                validationRules: {
                    minLength: 1,
                    maxLength: 255,
                    isRequired: true,
                },
            },
            {
                type: 'text',
                name: 'numberOfHotels',
                value: 0,
                defaultValue: 0,
                label: 'Number of Hotels',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: true,
                required: true,
                validationRules: {
                    minValue: 1,
                    maxValue: 100,
                    isRequired: true,
                    isNumber: true,
                },
                hint: 'Value should be <=100',
            },
        ],
    },
    {
        name: 'Address of the Legal Entity',
        icon: buildingIcon,
        controls: [
            {
                type: 'react-select',
                name: 'country',
                value: '',
                label: 'Country',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: true,
                validationRules: {
                    isRequired: true,
                },
                options: [],
                optionLabel: 'name',
                optionValue: 'code',
                optionSelector: 'countryList',
            },
            {
                type: 'text',
                name: 'street',
                value: '',
                label: 'Street',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: true,
                validationRules: {
                    minLength: 1,
                    maxLength: 255,
                    isRequired: true,
                },
            },
            {
                type: 'text',
                name: 'city',
                value: '',
                label: 'City',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: true,
                validationRules: {
                    minLength: 1,
                    maxLength: 255,
                    isCity: true,
                    isRequired: true,
                },
            },
            {
                type: 'text',
                name: 'postCode',
                value: '',
                label: 'Postcode',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: true,
                validationRules: {
                    minLength: 1,
                    maxLength: 255,
                    isRequired: true,
                },
            },
        ],
    },
    {
        name: 'Your Infos',
        icon: userCircle,
        controls: [
            {
                type: 'text',
                name: 'firstName',
                value: '',
                label: 'First Name',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: true,
                validationRules: {
                    minLength: 1,
                    maxLength: 255,
                    isRequired: true,
                },
            },
            {
                type: 'text',
                name: 'lastName',
                value: '',
                label: 'Last Name',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: true,
                validationRules: {
                    minLength: 1,
                    maxLength: 255,
                    isRequired: true,
                },
            },
            {
                type: 'text',
                name: 'email',
                value: '',
                label: 'Email Address',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: true,
                validationRules: {
                    maxLength: 318,
                    isRequired: true,
                    isEmail: true,
                },
            },
            {
                type: 'react-select',
                name: 'role',
                value: '',
                label: 'Role in the Company',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: true,
                validationRules: {
                    isRequired: true,
                },
                options: [],
                optionLabel: 'name',
                optionValue: 'name',
                optionSelector: 'roleList',
            },
            {
                type: 'text',
                name: 'other',
                value: '',
                label: 'Your Role in the Company',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: true,
                required: true,
                validationRules: {
                    minLength: 1,
                    maxLength: 255,
                    isRequired: true,
                },
            },
            {
                type: 'captcha',
                name: 'g-recaptcha-response',
                hidden: false,
                value: '',
                required: true,
                validationRules: {
                    minLength: 1,
                    isRequired: true,
                },
                valid: false,
            },
        ],
    },
];
