const Validate = (value, rules) => {
    let isValid = true;

    for (let rule in rules) {
        switch (rule) {
            case 'minLength':
                isValid = isValid && minLengthValidator(value, rules[rule]);
                break;
            case 'maxLength':
                isValid = isValid && maxLengthValidator(value, rules[rule]);
                break;
            case 'minValue':
                isValid = isValid && minValueValidator(value, rules[rule]);
                break;
            case 'maxValue':
                isValid = isValid && maxValueValidator(value, rules[rule]);
                break;
            case 'isRequired':
                isValid = isValid && requiredValidator(value);
                break;
            case 'isEmail':
                isValid = isValid && emailValidator(value);
                break;
            case 'isWebsite':
                isValid = isValid && websiteValidator(value);
                break;
            case 'isPhone':
                isValid = isValid && phoneValidator(value);
                break;
            case 'isNumber':
                isValid = isValid && isNumberValidator(value);
                break;
            case 'isStrictString':
                isValid = isValid && isStrictStringValidator(value);
                break;
            case 'isCity':
                isValid = isValid && isCity(value);
                break;
            default:
                isValid = true;
        }
    }

    return isValid;
};

const minLengthValidator = (value, minLength) => {
    return value.length >= minLength;
};

const maxLengthValidator = (value, maxLength) => {
    return value.length <= maxLength;
};

const minValueValidator = (value, minLength) => {
    return value >= minLength;
};

const maxValueValidator = (value, maxLength) => {
    return value <= maxLength;
};

const requiredValidator = value => {
    return value instanceof Object || typeof value === 'boolean' || typeof value === 'number'
		? true
		: isValid(value)
			? value.trim() !== ''
			: false;
};

const emailValidator = value => {
    // eslint-disable-next-line no-useless-escape
    // var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var re = /(^[\w-.]{1,64})@([\w-]{1,253})\.+[\w-]{2,4}$/;

    return value ? re.test(String(value).toLowerCase()) : true;
};

const websiteValidator = value => {
    // eslint-disable-next-line no-useless-escape
    var re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

	return value ? re.test(String(value).toLowerCase()) : true;
};

const phoneValidator = value => {
	// eslint-disable-next-line no-useless-escape
    var re = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/;

    return value ? re.test(String(value).toLowerCase()) : true;
};

const isNumberValidator = value => {
    var re = /[0-9*]$/;

    return value ? re.test(value) : true;
};

const isStrictStringValidator = value => {
    var re = /^[a-zA-Z][a-zA-Z\s]*$/;

    return value ? re.test(value) : true;
};

const isCity = value => {
    var re = /^([^0-9]*)$/;

    return value ? re.test(value) : true;
};

const isValid = value => {
    return value !== null && value !== undefined;
};

export default Validate;
