import React from 'react';

export const privacyItems = [
    {
        title: '',
        content: (
            <>
                <span className="ynq-privacy__chapter-label--lg">
                    Datenschutzerklärung der YOUNIQUE AG
                </span>
                <span className="ynq-privacy__chapter-text">
                    Verantwortliche Stelle für die Verarbeitung persönlicher
                    Daten
                </span>
                <span className="ynq-privacy__chapter-text">
                    <span className="ynq-privacy__chapter-text--highlight">
                        YOUNIQUE AG
                    </span>
                    , mit Sitz in Wundtstraße 21, 14509 Berlin, Deutschland
                    (nachfolgend „Younique“ genannt) obliegt die Verantwortung
                    bezüglich der Verarbeitung Ihrer persönlichen Daten auf
                    seinen Webseiten und mobilen Apps.
                </span>
                <span className="ynq-privacy__chapter-label">
                    Im Einzelnen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Verantwortliche Stelle für die Erhebung, Verarbeitung und
                    Nutzung Ihrer personenbezogenen Daten im Sinne des
                    Bundesdatenschutzgesetzes ist die Younique AG, Wundtstraße
                    21, 14509 Berlin, Deutschland (nachfolgend „Younique“
                    genannt).
                </span>
                <span className="ynq-privacy__chapter-text">
                    Sofern Sie der Erhebung, Verarbeitung oder Nutzung Ihrer
                    Daten durch Younique nach Maßgabe dieser
                    Datenschutzbestimmungen insgesamt oder für einzelne
                    Maßnahmen widersprechen wollen, können Sie Ihren Widerspruch
                    per E-Mail, oder Brief an folgende Kontaktdaten senden:
                </span>
                <span className="ynq-privacy__chapter-text">
                    <span className="ynq-privacy__chapter-text--highlight">
                        YOUNIQUE AG
                    </span>
                    <br/>
                    Wundtstraße 21
                    <br/>
                    14509 Berlin
                </span>
                <span className="ynq-privacy__chapter-text">
                    <span className="ynq-privacy__chapter-text--highlight">
                        E-Mail:{' '}
                    </span>
                    <a
                        href="mailto:datenschutz@younique.org"
                        className="ynq-privacy__chapter-link--highlight"
                    >
                        datenschutz@younique.org
                    </a>
                </span>
                <span className="ynq-privacy__chapter-text">
                    In Sachen Datenschutz sind wir immer für Sie da. Unseren
                    Beauftragten für den Datenschutz erreichen Sie unter der
                    E-Mail- Adresse
                    <br/>
                    <a
                        href="mailto:datenschutz@younique.org"
                        className="ynq-privacy__chapter-link--highlight"
                    >
                        datenschutz@younique.org
                    </a>
                </span>
                <span className="ynq-privacy__chapter-text">
                    <span className="ynq-privacy__chapter-text--highlight">
                        Das Wichtigste:{' '}
                    </span>
                    Datenschutz ist Vertrauenssache und Ihr Vertrauen ist uns
                    wichtig. Wir respektieren Ihre Privats- und
                    Persönlichkeitssphäre. Entsprechend ist der Schutz und die
                    gesetzeskonforme Verarbeitung Ihrer personenbezogenen Daten
                    für uns wichtig. Damit Sie sich bei dem Besuch unserer
                    Webseiten sicher fühlen, beachten wir bei der Verarbeitung
                    Ihrer personenbezogenen Daten streng die gesetzlichen
                    Bestimmungen und möchten Sie hier über die dort
                    stattfindende Datenverarbeitung informieren.
                </span>
                <span className="ynq-privacy__chapter-text">
                    In diesem Dokument erfahren Sie mehr darüber, wie wir Ihre
                    persönlichen Daten verwenden und verarbeiten. Wir sind
                    überzeugt, dass die Informationen gut lesbar, verständlich
                    und transparent geschrieben sind, so dass Sie verstehen, was
                    wir meinen. Des Weiteren erfahren Sie, wie Sie sich mit uns
                    in Verbindung setzen können, wenn Sie Fragen zu Ihren
                    persönlichen Daten haben.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Bitte lesen Sie auch unsere Bestimmungen zu Cookies, in
                    denen wir Sie darüber informieren, wie Younique Cookies und
                    ähnliche Technologien verwendet. Die Bestimmungen zu Cookies
                    finden Sie in der Footer Navigation am Ende unserer
                    Webseite.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Younique ist ein Online-Service zur Buchung von Hotels auf
                    der eigenen Webseite und über mobile Apps, aber auch auf
                    Partnerwebseiten. Die nachfolgende Datenschutzerklärung
                    erläutert Ihnen, welche Daten auf unseren Plattformen
                    erhoben werden und welche Daten wir wie verarbeiten und
                    nutzen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Möglicherweise ändern sich unsere Datenschutzbestimmungen
                    von Zeit zu Zeit. Entsprechend empfehlen wir Ihnen, diese
                    Seite ab und zu zu besuchen, damit Sie immer über den
                    neusten Stand auf dem Laufenden sind.
                </span>
            </>
        ),
    },
    {
        title:
            'Welche persönlichen Daten und Informationen werden von Younique auf welcher Rechtsgrundlage gesammelt?',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Ohne Information können wir Ihnen nicht helfen, die perfekte
                    Unterkunft für Sie zu finden. Bei der Nutzung unseres
                    Service bitten wir Sie um bestimmte Informationen. Dabei
                    handelt es sich um grundlegende Informationen wie Ihren
                    Namen, Ihre Kontaktangaben, die Namen der mit Ihnen
                    reisenden Gäste, Ihre Präferenzen und Ihre
                    Zahlungsinformationen. Diese Informationen sind erforderlich
                    zur Durchführung der jeweiligen Vermittlungsaufträge i.S.d.
                    Art. 6 Abs. 1 lit. b Alt. 1 DS-GVO. Wir weisen hiermit
                    jedoch ausdrücklich darauf hin, dass Sie zur Bereitstellung
                    von personenbezogenen Daten an uns nicht gesetzlich
                    verpflichtet sind – sofern Sie dies unterlassen, kann dies
                    jedoch Einfluss auf das durch uns bereitstellbare Angebot an
                    Dienstleistungen haben. So werden Sie z.B. Verständnis dafür
                    haben, dass wir ohne die Angabe des gewünschten Zielorts
                    keine Hotelbuchungen durchführen können.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Unsere Geschäftsphilosophie basiert darauf Ihnen einen
                    möglichst persönlichen Service anzubieten, um Hotels und
                    Zimmer zu finden, die so nah wie möglich Ihren Wünschen und
                    Präferenzen entsprechen. In diesem Kontext werden wir Sie
                    bitten uns weitere Informationen zu eben diesen Präferenzen
                    zu liefern. Diese Informationen liefern Sie freiwillig und
                    können Sie jederzeit ändern und auch löschen. Diese
                    Informationen werden wir ausschließlich dazu verwenden, um
                    einen möglichst maßgeschneiderten Service anzubieten. Mit
                    demselben Ziel können diese Information, aber ausschließlich
                    mit Ihrer Zustimmung, auch an Hotels, die Sie buchen,
                    weitergeleitet werden.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Zusätzlich sammeln wir Informationen von Ihrem Computer.
                    Hierbei kann es sich um Ihre IP-Adresse, den von Ihnen
                    genutzten Browser und Ihre Spracheinstellungen handeln. Es
                    kann auch vorkommen, dass wir Informationen über Sie von
                    Dritten erhalten oder automatisch andere Arten von
                    Informationen sammeln. Sofern uns diesbezüglich eine
                    Einwilligung Ihrerseits vorliegt, stützt sich die
                    Datenverarbeitung auf Art. 6 Abs. 1 lit. a DS-GVO; sofern
                    diese Maßnahmen aus technischen Gründen zur Sicherstellung
                    der Funktionsfähigkeit unserer Webseiten und Dienste
                    erforderlich sind, auf Art. 6 Abs. 1 lit. f DS-GVO. Unser
                    Interesse an der Sicherheit unserer Angebote ist von
                    essentieller Bedeutung, da bei unzureichender IT-Sicherheit
                    der Betrieb selbiger als solcher – wie auch Ihre
                    personenbezogenen Daten – akut gefährdet wäre. Wir stellen
                    zudem sicher, dass Ihre Interessen hierbei durch angemessene
                    Maßnahmen – etwa Anonymisierung oder Pseudonymisierung –
                    gewahrt werden. Schließlich weisen wir darauf hin, dass eine
                    automatisierte Entscheidungsfindung, etwa im Rahmen einer
                    automatischen Profilbildung, nicht erfolgt.
                </span>
            </>
        ),
    },
    {
        title: 'Persönliche Informationen, die wir von Ihnen erhalten.',
        content: (
            <>
                <span className="ynq-privacy__chapter-label">
                    Im Einzelnen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Younique sammelt und verarbeitet Informationen, die Sie uns
                    geben. Wenn Sie eine Buchung durchführen, werden Sie
                    (zumindest) um die Angaben Ihres Namens und Ihrer
                    E-Mail-Adresse gebeten. Möglicherweise bitten wir auch um
                    die Angabe Ihres Wohnsitzes, Ihrer Telefonnummer, der
                    Zahlungsinformationen, der Namen der mit Ihnen reisenden
                    Gäste und eventuelle Präferenzen, die Sie bezüglich Ihres
                    Aufenthalts haben könnten.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wenn Sie sich mit unserem Team vom Kundenservice in
                    Verbindung setzen oder auf andere Weise (beispielsweise über
                    soziale Medien, oder die Kommunikation mit der von Ihnen
                    über unsere Webseite gebuchten Unterkunft) in Kontakt mit
                    uns treten, sammeln wir auch über diese Vorgänge
                    Informationen über Sie. Nach dem Aufenthalt erhalten Sie
                    möglicherweise eine Anfrage, eine Gästebewertung abzugeben,
                    um zukünftigen Gästen zu helfen, genau die Unterkunft zu
                    finden, die sie wünschen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Sie stellen uns auch anderweitig Informationen zur
                    Verfügung. Wenn Sie zum Beispiel mit Ihrem mobilen Gerät
                    surfen, können Sie es Younique ermöglichen, auf Ihren
                    aktuellen Standort oder Ihre Kontaktdetails zuzugreifen -
                    dies unterstützt uns dabei, Ihnen den bestmöglichen Service
                    zu bieten. Sie können auch ein Nutzerkonto anlegen, um Ihre
                    persönlichen Einstellungen abzuspeichern, vorhergehende
                    Buchungen anzusehen oder künftige Buchungen zu planen und
                    verwalten.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Möglicherweise nehmen Sie an Empfehlungsprogrammen oder
                    Treueprogrammen teil, was wiederum bedeutet, dass Sie uns
                    persönliche Information zur Verfügung stellen. Zusätzlich
                    können Sie uns Feedback geben oder uns bitten, Sie bei der
                    Nutzung der Younique Buchungservices zu unterstützen.
                </span>
            </>
        ),
    },
    {
        title:
            'Persönliche Informationen, die wir von Ihnen über andere erhalten.',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Natürlich buchen Sie möglicherweise nicht nur für sich
                    selbst, sondern reisen mit anderen Gästen, über die Sie bei
                    der Buchung Angaben machen oder Sie führen möglicherweise
                    eine Buchung für jemand anderen durch. In einigen Fällen
                    können Sie Younique dazu nutzen, mit Anderen Informationen
                    zu teilen. Zum Beispiel, wenn Sie das gewünschte Hotel
                    teilen oder an einem Empfehlungsprogramm teilnehmen. Alle
                    diese Daten werden jedoch nur genauso verarbeitet wie dort
                    angegeben. Weitere Informationen hierzu finden Sie auf den
                    entsprechenden Seiten.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wir müssen Sie jedoch darauf hinweisen, dass es Ihrer
                    Verantwortung obliegt, dass die Personen, über die Sie
                    persönliche Informationen übermitteln, sich dieses Vorgangs
                    bewusst sind und akzeptiert haben, wie Younique deren
                    Informationen verwendet wird.
                </span>
            </>
        ),
    },
    {
        title: 'Persönliche Informationen, die wir automatisch sammeln.',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wenn Sie unsere Webseiten oder Apps besuchen, und auch wenn
                    Sie dabei nicht buchen, verarbeiten wir automatisch folgende
                    Daten von Ihnen: IP-Adresse, Datum und Zeit Ihres Zugriffs
                    auf unsere Services, Hardware, Software oder Browsertyp,
                    Betriebssystem Ihres Computers, Applikationsversion und
                    Spracheinstellungen sowie Informationen über Klicks und
                    welche Seiten Ihnen angezeigt wurden.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Bei Nutzung eines Mobilgerätes, werden gegebenenfalls
                    folgende Daten erhoben: Art des Mobilgerätes und dessen
                    gerätespezifische Einstellungen und Eigenschaften sowie
                    Ihren geografischen Standort, Information über App-Abstürze
                    und andere Systemaktivitäten. Wenn Sie eine Hotelbuchung
                    abschließen, dann erfasst unser System, von welcher Webseite
                    und/oder App Sie diese Buchung getätigt haben oder von
                    welchen anderen Webseiten Sie Ihre Hotelbuchung auf Younique
                    durchgeführt haben.
                </span>
            </>
        ),
    },
    {
        title:
            'Persönliche Informationen, die wir von anderen Quellen erhalten.',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wir erhalten allerdings nicht nur die Daten, die Sie uns
                    geben - möglicherweise erhalten wir auch Informationen von
                    anderen Quellen über Sie. Hierzu zählen Geschäftspartner und
                    andere unabhängige dritte Parteien und alle Informationen
                    von diesen können mit den Informationen, die Sie uns geben,
                    zusammengeführt werden. Sie finden es vielleicht merkwürdig,
                    dass wir Informationen über Sie von unseren
                    Geschäftspartnern erhalten? Das sollte Ihnen keine Sorgen
                    bereiten und lässt sich einfach erklären.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Der Hotelbuchungsservice von Younique steht Ihnen nicht nur
                    auf Younique und den Younique Apps zur Verfügung, sondern
                    wird auch auf Partnerseiten als integrierter Bestandteil
                    deren Onlineservice angeboten. Wenn Sie diese nutzen, geben
                    Sie Ihre Buchungsdetails an unsere Geschäftspartner weiter,
                    die diese Informationen wiederum an uns weitergeben. Auch
                    integrieren wir die Services anderer Anbieter, um die
                    Zahlungsabwicklung zwischen dem Bucher und der Unterkunft
                    durchzuführen. Die Anbieter teilen Zahlungsinformationen, so
                    dass wir Ihre Buchung abwickeln können und sicherstellen,
                    dass der Zahlungsprozess so reibungslos wie möglich für Sie
                    abläuft.
                </span>
                <span className="ynq-privacy__chapter-label--md">
                    Warum sammelt und verwendet Younique Ihre persönlichen
                    Daten?
                </span>
                <span className="ynq-privacy__chapter-text">
                    Der Grund, aus dem wir Sie um Ihre persönlichen Angaben
                    bitten, ist praktischer Natur, nämlich zur Verwaltung und
                    Betreuung Ihrer Buchungen und um sicherzustellen, dass Sie
                    den bestmöglichen Service erhalten. Natürlich schützen wir
                    Ihre persönlichen Daten und nutzen diese, um mit Ihnen
                    Kontakt aufzunehmen oder um Sie über die neusten Angebote zu
                    informieren.
                </span>
                <span className="ynq-privacy__chapter-label">
                    Im Einzelnen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wir nutzen die über Sie gesammelten Informationen für
                    verschiedene Zwecke. Ihre persönlichen Daten können auf
                    folgende Arten und Weisen genutzt werden:
                </span>
            </>
        ),
    },
    {
        title: 'Buchungen',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Zunächst einmal und hauptsächlich nutzen wir Ihre
                    persönlichen Daten zur Verwaltung und Vervollständigung
                    Ihrer Online -Buchung - dies ist wichtig, damit wir unserem
                    Unternehmenszweck nachkommen können. Wir können Ihnen auch
                    andere Produkte aus dem Bereich Reisen und Services anderer
                    Anbieter anbieten. Dies geschieht über unseren Onlineservice
                    und falls Sie sich dazu entschließen, diesen zu nutzen,
                    verwenden wir Ihre Informationen, um Ihre Bestellung zu
                    bearbeiten und zu vervollständigen, genauso wie wir es tun
                    würden, wenn Sie ausschließlich eine Buchung durchgeführt
                    hätten.
                </span>
            </>
        ),
    },
    {
        title: 'Kundenservice',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wir bieten Ihnen einen Kundenservice und stehen Ihnen
                    ständig zur Verfügung. Wenn Sie Ihre Details mit unseren
                    Service-Mitarbeitern teilen, geben Sie uns die Möglichkeit,
                    Ihnen zu antworten, wenn dies erforderlich ist. Dazu gehört
                    die Beantwortung jeglicher Fragen, die Sie bezüglich Ihrer
                    Buchung haben könnten - oder auch die Beantwortung anderer
                    Fragen.
                </span>
            </>
        ),
    },
    {
        title: 'Be Younique Kunden-Konto',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Sie können ein Nutzerkonto auf unseren Webseiten oder über
                    unsere Apps erstellen. Wir nutzen diese Informationen zur
                    Verwaltung dieses Kontos und Sie können so verschiedene
                    nützliche Funktionen nutzen. Sie können zum Beispiel Ihre
                    Buchungen oder andere Bestellungen verwalten, von besonderen
                    Angeboten und Services Gebrauch machen, auf einfache Weise
                    Buchungen für die Zukunft durchführen oder Ihre persönlichen
                    Einstellungen verwalten. Zur Verwaltung Ihrer persönlichen
                    Einstellungen zählen die Erstellung einer Favoriten Liste,
                    die Anzeige von Hotelbuchungen, die Sie getätigt haben, Ihre
                    Buchungsvorlagen und andere reiserelevante Informationen,
                    die Sie uns gegeben haben. Wir können so auch jegliche
                    Bewertungen, die Sie abgegeben haben, einsehen. Wenn Sie es
                    wünschen, können Sie Ihrem Konto weitere Nutzerinformationen
                    und Präferenzen hinzufügen oder auch Informationen zu
                    Treueprogrammen oder Zahlungsdaten.
                </span>
            </>
        ),
    },
    {
        title: 'Marketing',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wir nutzen Ihre Informationen ebenfalls zu Marketingzwecken.
                    Diese Aktivitäten beinhalten:
                </span>
                <span className="ynq-privacy__chapter-text">
                    Die Verwendung Ihrer Kontaktinformationen zum Versenden von
                    reisebezogenen Produkten und Services, wenn Sie über uns
                    eine Buchung durchführen oder ein Nutzerkonto erstellen. Sie
                    können sich von diesen Marketingnachrichten jederzeit
                    schnell und einfach abmelden - hierfür klicken Sie ganz
                    einfach auf den Link {`"`}Abbestellen{`"`}, der in jedem
                    enthalten ist.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Basierend auf den Informationen, die Sie mit uns teilen,
                    sehen Sie personalisierte Angebote auf der Younique
                    Webseite, in mobilen Apps oder auf den Webseiten/Apps
                    Dritter (einschließlich Webseiten sozialer Medien). Dies
                    können Angebote sein, die direkt auf der Webseite von
                    Younique zu buchen sind oder auch Angebote von
                    Drittanbietern sowie Produkte oder Services, von denen wir
                    annehmen, dass sie für Sie interessant sein könnten.
                </span>
            </>
        ),
    },
    {
        title: 'Sonstige Kommunikation',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Es kann vorkommen, dass wir Sie per E-Mail, Post, Telefon
                    oder SMS kontaktieren, je nachdem, welche
                    Kontaktinformationen Sie mit uns geteilt haben.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Außerdem verarbeiten wir die Kommunikation, die Sie mit uns
                    geteilt haben. Dafür kann es folgende Gründe geben:
                </span>
                <span className="ynq-privacy__chapter-text">
                    Die Beantwortung und Bearbeitung sämtlicher Anfragen, die
                    Sie oder Ihre gebuchte Unterkunft gestellt haben. Younique
                    bietet Gästen und Unterkunftspartnern darüber hinaus diverse
                    Möglichkeiten, Informationen, Anfragen und Kommentare im
                    Hinblick auf Unterkünfte und bestehende Buchungen, die über
                    Younique erfolgen, auszutauschen. Wenn Sie hierüber mehr
                    erfahren möchten, finden Sie ein bisschen weiter unten den
                    Abschnitt {`"`}Wie verwendet Younique
                    Kommunikationsvorgänge, die Sie und die von Ihnen gebuchte
                    Unterkunft an Younique senden?{`"`}
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wenn Sie eine Buchung nicht abgeschlossen haben, senden wir
                    Ihnen gegebenenfalls eine E-Mail zur Erinnerung, Ihre
                    Buchung fortzusetzen. Wir glauben, dass Ihnen dieser
                    zusätzliche Service hilft, eine Buchung fortzusetzen, ohne
                    die Unterkunft erneut zu suchen oder die Buchungsangaben
                    erneut auszufüllen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wenn Sie unsere Services nutzen, senden wir Ihnen womöglich
                    einen Fragebogen oder eine Einladung zur Abgabe einer
                    Bewertung über Ihre Erfahrung mit Younique.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wir senden Ihnen gegebenenfalls auch andere
                    buchungsrelevante Daten, wie z.B. die Kontaktdaten von
                    Younique, falls Sie während Ihrer Reise unsere Unterstützung
                    benötigen oder auch Informationen, die für die Planung Ihrer
                    Reise hilfreich sein können, um das Beste aus Ihrem
                    Aufenthalt zu machen. Gegebenenfalls senden wir Ihnen auch
                    Informationen zu Ihren bevorstehenden Buchungen, die Sie
                    über Younique bereits getätigt haben.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Auch wenn Sie keine bevorstehende Buchung habe, senden wir
                    Ihnen möglicherweise anderweitige Nachrichten
                    administrativer Natur, darunter auch Sicherheitshinweise.
                </span>
            </>
        ),
    },
    {
        title: 'Marktforschung',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wir bitten unsere Kunden gelegentlich, an Umfragen
                    teilzunehmen. Alle zusätzlichen persönlichen Angaben im
                    Rahmen dieser Erhebung werden nur mit Ihrer Zustimmung
                    genutzt.
                </span>
            </>
        ),
    },
    {
        title: 'Gewährleitung eines sicheren und zuverlässigen Services',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Um ein vertrauenswürdiges Umfeld für Sie, andere Reisende,
                    die Geschäftspartner von Younique und unsere
                    Unterkunftspartner zu schaffen, nutzen wir gegebenenfalls
                    persönliche Daten zur Aufdeckung und Prävention von Betrug
                    und anderen illegalen bzw. unerwünschten Aktivitäten. Wir
                    können zudem persönliche Daten für Gefährdungsbeurteilungen
                    und Sicherheitszwecke - einschließlich der Authentifizierung
                    von Nutzern und Buchungen - nutzen. Daher kann es vorkommen,
                    dass wir bestimmte Buchungen vorübergehend zurückstellen
                    müssen.
                </span>
            </>
        ),
    },
    {
        title: 'Verbesserung unseres Services',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wir nutzen persönliche Daten auch für analytische Zwecke.
                    Dies dient nicht nur der Verbesserung unserer Services und
                    der Optimierung der Nutzererfahrung, sondern kann auch für
                    Testzwecke, zur Problembehebung und zur Verbesserung der
                    Funktionalitäten und Qualität unseres Online-Reiseservices
                    erfolgen. Hier setzen wir es uns zum Ziel, unsere
                    Online-Plattform zu optimieren und auf Ihre Bedürfnisse
                    zuzuschneiden und so unsere Webseiten und Apps noch
                    nutzerfreundlicher und ansprechender zu gestalten.
                </span>
            </>
        ),
    },
    {
        title: 'Gästebewertungen und andere Reiseziel relevante Informationen',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Während Ihres Aufenthalts und nach Ihrem Aufenthalt in einer
                    über uns gebuchten Unterkunft werden sie möglicherweise
                    eingeladen, eine Gästebewertung zu hinterlegen. Wir können
                    auch Gästen, die mit Ihnen oder in Ihrem Namen reisen,
                    ermöglichen, eine Gästebewertung zu schreiben. Die
                    entsprechende Einladung kann Sie um Informationen zur
                    Unterkunft und anderen Dienstleistungen/Services, die Sie
                    über Younique gebucht haben, bitten. Als Kontoinhaber können
                    Sie entscheiden ob Sie die Bewertung mit Ihrem Namen oder
                    anonym abgeben möchten. Mit der Übermittlung einer
                    Gästebewertung erklären Sie sich damit einverstanden, dass
                    die Bewertung z.B. auf der Informationsseite der Unterkunft
                    auf unseren Webseiten, in unseren mobilen Apps, auf
                    Social-Media-Kanälen und -Apps oder auf der Webseite der
                    jeweiligen Unterkunft bzw. der Webseite des
                    Geschäftspartners angezeigt werden kann. So werden andere
                    Reisende über die Qualität der Unterkunft, in der Sie
                    übernachtet haben oder andere Dienstleistungen und Services,
                    die Sie in Anspruch genommen haben, informiert.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wenn Sie angeben, ob Sie eine bestimmte Gästebewertung
                    hilfreich finden, berücksichtigen wir diese Information
                    zusammen mit dem Feedback anderer Nutzer, um die
                    Gästebewertung zu sortieren und zu priorisieren. Wir
                    verwenden unter Umständen die Informationen in Ihren Listen
                    oder andere reisezielbezogene Daten, die Sie mit uns teilen,
                    in anonymer Form, sofern Sie diese Angaben nicht in Ihrem
                    Profil hinterlegt haben. Hierdurch unterstützen wir andere
                    Reisende bei der Suche nach dem richtigen Reiseziel und der
                    Unterkunft, die ihren Bedürfnissen am besten entspricht.
                </span>
            </>
        ),
    },
    {
        title: 'Qualitäts - management',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wenn Sie den Younique Kundenservice anrufen, kann das
                    Telefonat für Trainingszwecke oder zum Qualitätsmanagement
                    mitgehört oder aufgezeichnet werden. Aufgezeichnete Anrufe
                    werden für eine begrenzte Zeit gespeichert und automatisch
                    gelöscht, sofern Younique kein berechtigtes Interesse daran
                    hat, solche Aufzeichnung für einen längeren Zeitraum (wie
                    nach billigem Ermessen erforderlich) aufzubewahren, etwa im
                    Rahmen der Aufdeckung von betrügerischen Aktivitäten.
                    Sämtliche Anrufaufzeichnungen werden gemäß dieser
                    Datenschutzbestimmungen bearbeitet.
                </span>
            </>
        ),
    },
    {
        title: 'Juristische Zwecke',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    In bestimmten Fällen können wir Ihre Daten zur Bearbeitung
                    und Lösung von rechtlichen Konflikten verwenden oder im
                    Rahmen von Untersuchungen und zur Einhaltung von
                    Compliance-Richtlinien auf diese zurückgreifen. Wir können
                    sie auch nutzen, um die Nutzungsbestimmungen des
                    Online-Buchungsservices von Younique nach zumutbarem
                    Ermessen durchzusetzen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wir stützen uns auf die folgenden rechtlichen Grundlagen, um
                    Ihre Daten wie beschrieben zu verarbeiten. Es findet
                    insbesondere Art.6 DSGVO entsprechende Berücksichtigung.
                </span>
            </>
        ),
    },
    {
        title: 'Vertragserfüllung',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Die Nutzung Ihrer Daten kann erforderlich sein, um den
                    Vertrag, den Sie mit uns eingehen, umzusetzen. Wenn Sie
                    beispielsweise unseren Service nutzen, um eine
                    Online-Buchung zu tätigen, werden wir auf Ihre Daten
                    zurückgreifen, um unserer Pflicht nachzukommen, die Buchung
                    gemäß unserer gemeinsamen Vereinbarung abzuschließen und
                    durchzuführen.
                </span>
            </>
        ),
    },
    {
        title: 'Legitime Interessen',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wir können Ihre Daten für unsere legitimen
                    Geschäftsinteressen nutzen. Hierdurch können wir Ihnen
                    möglichst personalisierte Inhalte auf der Webseite & Apps,
                    in den E-Mails und in den Newslettern zur Verfügung stellen,
                    oder unsere Produkte und Services sowie die Inhalte auf
                    unserer Webseite optimieren und bewerben; zudem kann dies
                    administrativen oder juristischen Zwecken dienen oder auch
                    der Aufdeckung von betrügerischen Aktivitäten.
                </span>
            </>
        ),
    },
    {
        title: 'Zustimmung',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wir setzen unter Umständen Ihre Zustimmung voraus, Ihre
                    persönlichen Daten für bestimmte Direktmarketingzwecke
                    verwenden zu dürfen. Sie können Ihre Zustimmung jederzeit
                    widerrufen, indem Sie uns über die Adressen am Ende dieser
                    Datenschutzerklärung kontaktieren.
                </span>
                <span className="ynq-privacy__chapter-label--md">
                    Wie lange speichert Younique Ihre persönlichen Daten?
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wir halten Ihre Daten nur solange vor, wie der
                    datenschutzrechtliche Zweck für deren Speicherung
                    fortbesteht. Dies bedeutet etwa für die Durchführung von
                    Hotelbuchungen, dass Ihre Daten nach Abschluss des
                    Buchungsvorgangs bzw. Ihres Aufenthalts gelöscht werden –
                    natürlich nur, soweit diese Daten nicht gesetzlich
                    zwingenden Aufbewahrungsfristen (etwa steuerrechtlicher
                    Natur) unterliegen. In letzterem Fall bewahren wir die Daten
                    bis zum Ablauf der maßgeblichen Aufbewahrungsfristen auf.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Die Informationen über Ihren Computer, die zur Wahrung der
                    IT-Sicherheit erforderlich sind (vgl. oben), werden
                    ebenfalls stets nur so lange vorgehalten, wie dies der
                    vorbenannte Zweck gebietet – wenn also Nutzungsdaten nur für
                    die jeweilige Session benötigt werden, werden diese
                    automatisch entfernt, sobald Sie Ihren Browser schließen
                    bzw. unsere Webseiten verlassen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Sofern Sie uns eine Einwilligung zur darüber hinausgehenden
                    Datenverarbeitung erteilt haben (vgl. oben), speichern wir
                    Ihre Daten grundsätzlich, bis Sie selbige widerrufen.
                    Hierfür kann ebenfalls die E-Mail-Adresse{' '}
                    <a
                        href="mailto:datenschutz@younique.org"
                        className="ynq-privacy__chapter-link--highlight"
                    >
                        datenschutz@younique.org
                    </a>{' '}
                    verwendet werden.
                </span>
                <span className="ynq-privacy__chapter-label--md">
                    Wie teilt Younique Ihre Daten mit Dritten?
                </span>
                <span className="ynq-privacy__chapter-text">
                    In die Younique Services sind verschiedene Dritte auf
                    unterschiedliche Weise und aus verschiedenen Gründen
                    eingebaut. Der hauptsächliche Zweck besteht darin, Ihre
                    Buchungsdaten der von Ihnen gebuchten Unterkunft zu
                    übermitteln, um Ihre Buchung abzuschließen. Es gibt noch
                    weitere Parteien, die einen Teil Ihrer Daten erhalten
                    könnten. Hierbei handelt es sich um Parteien, die Younique
                    in verschiedenen Aufgaben unterstützen, Dienstleister,
                    Finanzinstitutionen, Werbefirmen oder in einigen Fällen
                    Behörden. Ein Transfer Ihrer personenbezogenen Daten in
                    Gebiete außerhalb der Europäischen Union bzw. des
                    Europäischen Wirtschaftsraums unterbleibt.
                </span>
            </>
        ),
    },
    {
        title: 'Die von Ihnen gebuchte Unterkunft',
        content: (
            <>
                <span className="ynq-privacy__chapter-label">
                    Im Einzelnen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Dieser Punkt ist unerlässlich für unsere Arbeit. Um Ihre
                    Buchung abzuschließen, übermitteln wir die relevanten
                    Buchungsdaten an die von Ihnen gebuchte Unterkunft. Dies
                    können Information wie Ihr Name, Ihre Kontaktdaten, Ihre
                    Zahlungsangaben, Ihre besonderen Anfragen aus der Buchung,
                    Ihre Präferenzen sowie die Namen der mit Ihnen reisenden
                    Gäste sein. Wenn Sie eine Frage zu Ihrer Buchung haben,
                    kontaktieren wir gegebenenfalls den Unterkunftsbetreiber und
                    bitten ihn, sich darum zu kümmern. Sofern keine Zahlung
                    während des Buchungsvorgangs über die Younique Webseite oder
                    Apps erfolgt, werden wir Ihre Kreditkartendetails an die
                    gebuchte Unterkunft zur weiteren Bearbeitung weiterleiten
                    (vorausgesetzt, Sie haben uns diese Daten während des
                    Buchungsvorgangs zur Verfügung gestellt). Im Fall von
                    buchungsbezogenen Streitigkeiten können wir dem
                    Unterkunftsbetreiber je nach Bedarf Daten zum
                    Buchungsvorgang übermitteln. Dies beinhaltet womöglich eine
                    Kopie Ihrer Buchungsbestätigung, die als Beleg für den
                    tatsächlichen Buchungsabschluss dient.
                </span>
            </>
        ),
    },
    {
        title: 'Drittdienstleiter',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wir greifen gegebenenfalls auf Dienstleister zurück, um Ihre
                    persönlichen Daten in unserem Namen zu verarbeiten. Dies
                    kann diversen Zwecken dienen, wie zum Beispiel der
                    Versendung von E-Mails mit oder ohne Werbeinhalten. Alle
                    Drittdienstleister unterliegen
                    Vertraulichkeitsvereinbarungen und dürfen Ihre persönlichen
                    Daten ausschließlich zu den zuvor genannten Zwecken
                    verarbeiten.
                </span>
            </>
        ),
    },
    {
        title: 'Zahlungsanbieter und (anderweitige) Finanzinstitutionen',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wenn eine Rückbuchungsforderung für Ihre Buchung von Ihnen
                    oder dem Inhaber der Kreditkarte, die für die Buchung
                    verwendet wurde, eingereicht wird, kann es vorkommen, dass
                    wir bestimmte Buchungsdetails mit dem Zahlungsanbieter und
                    der jeweiligen Finanzinstitution teilen müssen, um die
                    Rückbuchungsforderung zu bearbeiten. Diese Details können
                    eine Kopie Ihrer Buchungsbestätigung oder die IP-Adresse,
                    die für Ihre Buchung verwendet wurde, umfassen. Zudem können
                    wir Informationen mit den jeweiligen Finanzinstitutionen
                    teilen, wenn dies unserer Ansicht nach unabdingbar ist, um
                    betrügerische Aktivitäten aufzudecken oder zu verhindern.
                </span>
            </>
        ),
    },
    {
        title: 'Zuständige Behörden',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wir legen persönliche Daten zur Rechtsdurchsetzung offen,
                    wenn dies vom Gesetz verlangt wird oder dringend zur
                    Verhinderung, Aufdeckung oder Strafverfolgung von
                    kriminellen Handlungen oder Betrug nötig ist. Darüber hinaus
                    können wir gegebenenfalls persönliche Daten mit den
                    zuständigen Behörden teilen, um unsere Rechte und
                    Unterkünfte oder die Rechte und Unterkünfte unserer
                    Geschäftspartner zu schützen.
                </span>
            </>
        ),
    },
    {
        title: 'Geschäftspartner',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wir arbeiten mit Geschäftspartnern auf der ganzen Welt
                    zusammen. Einige dieser Geschäftspartner sind damit
                    beauftragt, unsere Dienstleistungen zu vermarkten bzw. zu
                    bewerben und unseren Geschäftspartnern bei der Vermarktung
                    und Bewerbung ihrer Reiseservices zu helfen. Dies kann
                    bedeuten, dass deren Services auf unserer Website und
                    unseren Apps integriert werden; dass sie die Möglichkeit
                    haben, Ihnen auf unserer Seite ein individuelles Angebot
                    anzuzeigen; oder dass der Online-Buchungsservice von
                    Younique auf deren Webseite und/oder Apps integriert wird.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wenn Sie eine Buchung über die Webseiten oder Apps unserer
                    Geschäftspartner tätigen, werden bestimmte persönliche
                    Angaben, die Sie ihnen übermitteln, an uns weitergeleitet.
                    Wenn der Geschäftspartner einen Kundenservice bietet, wird
                    Younique buchungsrelevante Daten mit den Geschäftspartner
                    (je nach Notwendigkeit) teilen, um Ihnen den bestmöglichen,
                    angemessenen Service zur Verfügung zu stellen. Wenn Sie eine
                    Buchung über die Webseiten unserer Geschäftspartner
                    durchführen, können die Geschäftspartner bestimmte
                    Komponenten Ihrer persönlichen Daten, die für die jeweilige
                    Buchung relevant sind, erhalten. Dies dient deren internen
                    Zwecken (einschließlich Analysezwecken) und, sofern von
                    Ihnen angefragt, der Anwendung von Marketingaktionen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wenn Sie eine Buchung auf der Webseite eines
                    Geschäftspartners durchführen, lesen Sie bitte dessen
                    Datenschutzbestimmungen, um zu erfahren, auf welche Art und
                    Weise die Geschäftspartner Ihre persönlichen Daten
                    verarbeiten können. Wenn wir Ihnen anderweitige
                    reisebezogene Produkte und/oder Services anbieten, werden
                    Ihre Daten mit den anbietenden Geschäftspartnern unter
                    Umständen geteilt, um Ihren Auftrag zu bearbeiten oder
                    umzusetzen. Zur Aufdeckung und Verhinderung von Betrug
                    können wir, wenn dies unabdingbar ist, Information über
                    unsere Nutzer mit Geschäftspartnern teilen.
                </span>
                <span className="ynq-privacy__chapter-label--md">
                    Wie nutzt Younique mobile Geräte?
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wir bieten kostenfreie Apps, über die wir ebenfalls
                    persönliche Daten sammeln und verarbeiten. Dies geschieht
                    auf ähnliche Weise wie auf unseren Webseiten, sie
                    ermöglichen außerdem eine Standortbestimmung.
                </span>
                <span className="ynq-privacy__chapter-label">
                    Im Einzelnen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wir bieten kostenlose Apps für verschiedene mobile Geräte
                    und Versionen unserer normalen Webseiten, die für
                    Mobilgeräte angepasst wurden, an. Diese Apps und mobilen
                    Webseiten sammeln und verarbeiten Ihre persönlichen Daten
                    auf einer sehr ähnlichen Weise wie unsere Webseiten. Sie
                    ermöglichen Ihnen außerdem eine Standortbestimmung, um nahe
                    gelegene Unterkünfte zu finden. Mit Ihrer Zustimmung senden
                    wir Ihnen Push-Benachrichtigungen mit Information zu Ihrer
                    Buchung. Sie können uns Zugriff auf Ihre Standortdaten
                    gewähren, damit wir die von Ihnen gewünschten Services
                    bereitstellen können. Bitte lesen Sie die Anleitung Ihres
                    mobilen Gerätes, um zu erfahren, wie die Einstellungen
                    geändert und die Datenweitergabe sowie der Erhalt von
                    Push-Benachrichtigungen aktiviert werden können.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Younique macht von Cross-Device-Tracking Gebrauch, um unsere
                    Dienste und Marketingaktivitäten zu optimieren. Dies kann
                    mit oder ohne Nutzung von Cookies erfolgen. Wenn Sie mehr
                    über Cookies und vergleichbare technische Mittel erfahren
                    möchten, lesen Sie bitte nachstehend unsere Bestimmungen zu
                    Cookies. Anhand des Cross-Device-Trackings kann Younique das
                    Nutzungsverhalten auf mehreren Geräten verfolgen und darf
                    Daten eines bestimmten Browsers oder mobilen Gerätes mit
                    einem anderen Computer oder Gerät, der mit dem Computer oder
                    Gerät, von dem die Daten abgerufen wurden, verbunden ist,
                    zusammenlegen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Um die Inhalte des Younique Newsletters zu optimieren,
                    erfasst Younique die Suchanfragen und Buchungen, die über
                    verschiedene Computer und Geräte getätigt werden, während
                    Sie in Ihrem Benutzerkonto auf jedem dieser Computer oder
                    Geräte angemeldet sind. Wenn Sie nicht wünschen, das
                    Younique solche Daten für die Erstellung von Newslettern
                    oder die Optimierung der Inhalte unserer Webseiten und/oder
                    Apps verwendet, können Sie sich vom Gerät oder Computer
                    abmelden oder Ihr Abonnement des Younique - Newsletters
                    beenden.
                </span>
                <span className="ynq-privacy__chapter-label--md">
                    Welchen Gebrauch macht Younique von sozialen Medien?
                </span>
                <span className="ynq-privacy__chapter-text">
                    Die Nutzung sozialer Medien wird in die Services von
                    Younique auf unterschiedliche Weise integriert, so dass wir
                    an der Sammlung Ihrer persönlichen Daten beteiligt sind oder
                    der Anbieter der sozialen Medien direkt einige Ihrer
                    Informationen erhält. Wenn Sie mehr darüber erfahren
                    möchten, wie diese Informationen genutzt und ausgetauscht
                    werden, lesen Sie bitte weiter.
                </span>
                <span className="ynq-privacy__chapter-label">
                    Im Einzelnen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Unser Internetauftritt verwendet Social Plugins ({`"`}
                    Plugins{`"`}) von verschiedenen sozialen Netzwerken. Mit
                    Hilfe dieser Hilfe dieser Plugins können Sie beispielsweise
                    Inhalte teilen oder Produkte weiter empfehlen. Wenn diese
                    Plugins sind, baut Ihr Browser mit den Servern des
                    jeweiligen sozialen Netzwerks eine direkte Verbindung auf,
                    sobald Sie eine Webseite unsers Internetauftritts aufrufen.
                    Der Inhalt des Plugins wird vom sozialen Netzwerk direkt an
                    Ihren Browser übermittelt und von diesem in die Webseite
                    eingebunden.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Durch die Einbindung des Plugins erhält das soziale Netzwerk
                    die Information, dass Sie die entsprechende Seite unseres
                    Internetauftritts aufgerufen haben. Sind Sie bei dem
                    sozialen Netzwerk eingeloggt, kann dieses den Besuch Ihrem
                    Konto zuordnen. Wenn Sie mit den Plugins interagieren, zum
                    Beispiel Facebook {`"`}Gefällt mir{`"`} Button betätigen
                    oder einen Kommentar abgeben, wird die entsprechende
                    Information von Ihrem Browser direkt an das soziale Netzwerk
                    übermittelt und dort gespeichert.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Zweck und Umfang der Datenerhebung und die weitere
                    Verarbeitung und Nutzung der Daten durch soziale Netzwerke
                    sowie Ihre diesbezüglichen Rechte und
                    Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre
                    entnehmen Sie bitte den Datenschutzhinweisen der jeweiligen
                    Netzwerke bzw. Webseiten. Die Links hierzu findefn Sie
                    weiter unten.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Auch wenn Sie bei den sozialen Netzwerken nicht angemeldet
                    sind, können von Webseiten mit aktiven Social Plugins Daten
                    an die Netzwerke gesendet werden. Durch einen aktiven Plugin
                    wird bei jedem Aufruf der Webseite ein Cookie mit einer
                    Kennung gesetzt. Da Ihr Browser dieses Cookie bei jeder
                    Verbindung mit einem Netzwerk-Server ungefragt mitschickt,
                    könnte das Netzwerk damit prinzipiell ein Profil erstellen,
                    welche Webseiten der zu der Kennung gehörende Anwender
                    aufgerufen hat. Und es wäre dann auch durchaus möglich,
                    diese Kennung später - beim späteren Anmelden bei dem
                    sozialen Netzwerk - auch wieder einer Person zuzuordnen.
                </span>
                <span className="ynq-privacy__chapter-label--md">
                    Auf unseren Webseiten und/oder Apps nutzen wir folgende
                    Plugins: Facebook, Twitter & Youtube
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wenn Sie nicht möchten, dass soziale Netzwerke über aktive
                    Plugins Daten über Sie sammeln, können Sie die Social
                    Plugins einfach mit einem Klick in Ihren Browser-Einstellung
                    die Funktion {`"`}Cookies von Drittanbietern blockieren{`"`}{' '}
                    Dann sendet der Browser bei eingebetteten Inhalten anderer
                    Anbieter keine Cookies an den Server. Mit dieser Einstellung
                    funktionieren allerdings außer den Plugins unter Umständen
                    auch andere seitenübergreifende Funktionen nicht mehr.
                </span>
            </>
        ),
    },
    {
        title: 'Facebook',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wir verwenden Plugins des sozialen Netzwerkes facebook.com,
                    welches von der Facebook Inc., 1601 S. California Ave, Palo
                    Alto, CA 94304, USA betrieben wird ({`"`}Facebook{`"`}). Den
                    Link zur Datenschutzerklärung von Facebook finden Sie hier:{' '}
                    <a
                        href="https://facebook.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ynq-privacy__chapter-link--highlight"
                    >
                        Datenschutzhinweise Facebook
                    </a>
                    .
                </span>
            </>
        ),
    },
    {
        title: 'Twitter',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wir verwenden Plugins des sozialen Netzwerkes Twitter,
                    welches von der Twitter Inc., 795 Folsom Str., Suite 600,
                    San Francisco, CA 94107, USA betrieben wird ({`"`}Twitter
                    {`"`}). Den Link zur Datenschutzerklärung von Twitter finden
                    Sie hier:{' '}
                    <a
                        href="https://twitter.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ynq-privacy__chapter-link--highlight"
                    >
                        Datenschutzhinweise Twitter
                    </a>
                    .
                </span>
            </>
        ),
    },
    {
        title: 'Youtube',
        content: (
            <>
                <span className="ynq-privacy__chapter-text">
                    Wir verwenden Plugins der sozialen Netzwerke Google+ &
                    Youtube, welche von der Google Inc., 1600 Amphitheatre
                    Parkway, Mountain View, CA 94043, USA betrieben wird ({`"`}
                    Google{`"`}). Den Link zur Datenschutzerklärung von Google
                    finden Sie hier:{' '}
                    <a
                        href="https://youtube.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ynq-privacy__chapter-link--highlight"
                    >
                        Datenschutzhinweise Google
                    </a>
                    .
                </span>
                <span className="ynq-privacy__chapter-label--md">
                    Welche Sicherheitsmaßnahmen ergreift Younique, um Ihre
                    persönlichen Daten zu schützen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Gemäß der europäischen Datenschutzgesetze ergreifen wir
                    angemessene Maßnahmen, um den Schutz Ihrer persönlichen
                    Daten vor Missbrauch und unberechtigtem Zugriff zu
                    gewährleisten. Ihre Angaben zu Ihrer Kreditkarte werden
                    nicht in vollständiger Form gespeichert, sondern sind
                    verschlüsselt und werden nur für einen limitierten Zeitraum
                    gespeichert.
                </span>
                <span className="ynq-privacy__chapter-label">
                    Im Einzelnen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Nach europäischem Datenschutzgesetz wenden wir angemessene
                    Prozeduren an, um den Schutz Ihrer persönlichen Daten vor
                    Missbrauch und unberechtigtem Zugriff zu gewährleisten.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Um Ihre persönlichen Daten zu schützen, nutzen wir
                    entsprechende Geschäftssysteme und -abläufe. Zudem wenden
                    wir modernste Sicherheitsverfahren sowie technische
                    Beschränkungen und Zugangsbeschränkungen an, wenn unsere
                    Server auf Ihre persönlichen Daten zugreifen und sie
                    verwenden. Nur befugte Mitarbeiter haben für die sachgemäße
                    Erfüllung ihrer Aufgaben Zugang zu Ihren persönlichen Daten.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Sofern Ihre Kreditkartendetails im Rahmen einer Buchung
                    übermittelt werden müssen, werden diese nach Abschluss Ihrer
                    Buchung für einen limitierten Zeitraum gespeichert. Nach
                    Ablauf dieser Zeit werden sie entweder (vollständig oder
                    teilweise) unwiderruflich gelöscht oder zur Betrugserkennung
                    mit beschränktem Zugriff in unserem System hinterlegt. Sie
                    können Ihre Kreditkartendetails auch in Ihrem Benutzerkonto
                    speichern. Wenn Sie sich dafür entscheiden, werden Ihre
                    Kreditkartendetails als Hash-Code gespeichert, mit Ausnahme
                    der letzten vier Ziffern Ihrer Kreditkartennummer. So wissen
                    Sie, welche Karte Sie gerade für die Bezahlung verwenden.
                </span>
                <span className="ynq-privacy__chapter-label--md">
                    Wie geht Younique mit persönlichen Daten von Kindern um?
                </span>
                <span className="ynq-privacy__chapter-text">
                    Younique ist ein Service, dessen Nutzung ab einem Alter von
                    18 Jahren erlaubt ist. Wir verarbeiten ausschließlich
                    Information über Kinder im Einverständnis mit den Eltern
                    oder deren gesetzlichen Vertretern.
                </span>
                <span className="ynq-privacy__chapter-label">
                    Im Einzelnen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Die Services von Younique sind nicht für Kinder unter 18
                    Jahre gedacht. Unsere Dienstleistungen dürfen nur mit dem
                    gültigen Einverständnis eines Elternteils oder eines
                    Vormundes in Anspruch genommen werden. Sollten wir Daten von
                    Kindern unter 18 Jahren übermittelt bekommen, behalten wir
                    uns vor, diese zu löschen. In bestimmten Fällen, die sich
                    auf eine Buchung oder den Erwerb anderer reisebezogener
                    Leistungen beziehen, oder unter außergewöhnlichen Umständen
                    (etwa Funktionen, die sich an Familien richten), ist
                    Younique berechtigt, Daten von Kindern ausschließlich mit
                    dem Einverständnis des Elternteils zu sammeln und nutzen.
                </span>
                <span className="ynq-privacy__chapter-label--md">
                    Welche Rechte haben Sie bezüglich der persönlichen Daten,
                    die Sie Younique übermittelt haben?
                </span>
                <span className="ynq-privacy__chapter-text">
                    Sie haben jederzeit das Recht, Auskunft über Ihre
                    persönlichen Daten, die wir über Sie gesammelt haben, zu
                    verlangen. Sie können eine Übersicht Ihrer persönlichen
                    Daten anfordern, indem Sie uns eine Anfrage über die Website
                    https://gdpr.younique.org senden. Bitte geben Sie die dort
                    abgefragten Informationen an, damit wir Ihr
                    Auskunftsverlangen bestmöglich bearbeiten können.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Bitte beachten Sie, dass wir die Daten, die Sie in ein
                    Kontaktformular eintragen, wenn sie ein solches verwenden,
                    zu den Zwecken der Bearbeitung Ihrer Anfrage erheben,
                    verarbeiten und speichern.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Darüber hinaus haben Sie ein Recht auf Berichtigung etwaiger
                    unrichtiger personenbezogener Daten bzw. ggf. sogar deren
                    Löschung („Recht auf Vergessenwerden“), sofern die
                    Verarbeitung selbiger nicht (länger) rechtmäßig ist.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Unter bestimmten Voraussetzungen, etwa einer Streitigkeit
                    über die Rechtmäßigkeit der Verarbeitung, besteht zudem ein
                    Recht auf Einschränkung der Datenverarbeitung. Weiterhin
                    haben Sie das Recht, die Überlassung der Sie betreffenden
                    personenbezogenen Daten in einem strukturierten, gängigen
                    und maschinenlesbaren Format zu verlangen. Außerdem sind Sie
                    u.U. berechtigt, einer etwaigen Datenverarbeitung auf
                    Grundlage von Art. 6 Abs. 1 lit. e, f sowie zu Zwecken der
                    Direktwerbung jederzeit zu widersprechen. Sofern Sie uns
                    gegenüber Ihre Einwilligung in die weitergehende
                    Verarbeitung erteilt haben (vgl. oben), können Sie diese
                    grundsätzlich jederzeit – etwa per Nachricht an die oben
                    angegebene E-Mail-Adresse – widerrufen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Schließlich sind Sie dazu berechtigt, sich bei der
                    zuständigen Datenschutzaufsichtsbehörde über etwaige
                    Unstimmigkeiten zu beschweren.
                </span>
                <span className="ynq-privacy__chapter-label">
                    Im Einzelnen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Sie haben jederzeit das Recht, Ihre bei uns gespeicherten
                    persönlichen Daten einzusehen. Um eine Übersicht Ihrer
                    persönlichen Daten zu erhalten, senden Sie uns bitte eine
                    Anfrage über die Website: https://gdpr.younique.org. Bitte
                    geben Sie in der Betreffzeile der E-Mail {`"`}Abfrage der
                    persönlichen Daten{`"`} an, damit wir Ihr Auskunftsverlangen
                    bestmöglich bearbeiten können.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Sie können uns auch kontaktieren, wenn Sie glauben, dass die
                    persönlichen Daten, die wir zu Ihrer Person gespeichert
                    haben, fehlerhaft sind, oder wenn Sie glauben, dass wir
                    nicht mehr berechtigt sind, Ihre persönlichen Informationen
                    oder den vorliegenden Datenschutzbestimmungen haben. Bitte
                    senden Sie uns dazu eine Anfrage über die Website
                    https://gdpr.younique.org. Bitte geben Sie die dort
                    abgefragten Informationen an, damit wir Ihr
                    Auskunftsverlangen bestmöglich bearbeiten können. Wir werden
                    Ihre Anfrage gemäß der anwendbaren europäischen
                    Datenschutzgesetze bearbeiten.
                </span>
                <span className="ynq-privacy__chapter-label--md">
                    Bestimmung zu Cookies
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wir nutzen in unserem Internetauftritt sogenannte Cookies
                    und andere Tracking-Technologien, die auf unseren Webseiten
                    und in unseren Apps auf vielfältige Art und Weise zum
                    Einsatz kommen. Sie sorgen etwa dafür, dass die Younique
                    Webseiten funktionieren, sie analysieren den Traffic oder
                    sie dienen Werbezwecken. Diese Technologien werden entweder
                    von uns direkt oder von unseren Geschäftspartnern,
                    einschließlich Drittdienstleistern und Werbetreibenden, die
                    mit uns zusammenarbeiten, genutzt.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Cookies sind kleine Dateien, die auf Ihrem Gerät gespeichert
                    werden und durch die bestimmte Informationen an uns
                    übertragen werden. Der Einsatz von Cookies dient dazu, um
                    Ihnen die Nutzung bestimmter Funktionen zu ermöglichen und
                    unser Angebot insgesamt nutzerfreundlicher zu gestalten.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Einige der von uns verwendeten Cookies werden nach dem Ende
                    der Browser-Sitzung, also nach Schließen Ihres Browsers,
                    wieder gelöscht (sogenannte Sitzungs- oder Session-Cookies).
                    Andere Cookies verbleiben auch nach dem Ende der
                    Browser-Sitzung, also nach Schließen Ihres Browsers, auf
                    Ihrem Gerät und ermöglichen uns oder unseren
                    Partnerunternehmen (Cookies von Drittanbietern), Sie bei
                    Ihrem nächsten Besuch auf unserem Internetauftritt
                    wiederzuerkennen (sogenannte Persistent Cookies).
                </span>
                <span className="ynq-privacy__chapter-text">
                    Einige der von uns verwendeten Cookies sind technisch
                    erforderlich, um Ihnen die Nutzung bestimmter Funktionen zu
                    ermöglichen. Dies ist beispielsweise im Hinblick auf die
                    Speicherung von Eingaben im Zusammenhang mit der Nutzung der
                    Warenkorb-Funktion der Fall. Die Verarbeitung Ihrer
                    personenbezogenen Daten erfolgt insoweit auf der Grundlage
                    von Artikel 6 Abs. 1 Buchstabe b) DS-GVO zur Durchführung
                    vorvertraglicher Maßnahmen, die auf Ihre Anfrage als
                    betroffene Person erfolgen oder auf der Grundlage von
                    Artikel 6 Abs. 1 Buchstabe b) DS-GVO für die Erfüllung eines
                    Vertrages, dessen Vertragspartei Sie sind oder auf der
                    Grundlage von Artikel 6 Abs. 1 Buchstabe f) DS-GVO zur
                    Wahrung unseres berechtigten Interesses an der
                    Zurverfügungstellung möglichst benutzerfreundlicher
                    Funktionen. Soweit wir oder unsere Partnerunternehmen
                    Cookies für Zwecke der Reichweitenmessung oder für
                    Marketingszwecke nutzen, können Sie detaillierte
                    Informationen hierzu gegebenenfalls den entsprechenden
                    weiterführenden Hinweisen im Rahmen dieser
                    Datenschutzerklärung entnehmen.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Sie können die Speicherung der Cookies durch eine
                    entsprechende Einstellung Ihrer Browser-Software verhindern.
                    Bitte sehen Sie hierzu gegebenenfalls in der Programmhilfe
                    zu dem von Ihnen genutzten Browser nach, auf welche Weise
                    die entsprechende Einstellung erfolgen kann. Wir weisen Sie
                    jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
                    nicht sämtliche Funktionen unseres Internetauftritts
                    vollumfänglich werden nutzen können. Beispielhaft verweisen
                    wir auf die Informationen zu den folgenden gängigen Browsern
                    :{' '}
                    <a
                        href="https://www.google.com/chrome/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ynq-privacy__chapter-link--highlight"
                    >
                        Chrome
                    </a>
                    ,{' '}
                    <a
                        href="https://www.mozilla.org"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ynq-privacy__chapter-link--highlight"
                    >
                        Firefox
                    </a>
                    ,{' '}
                    <a
                        href="https://www.opera.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ynq-privacy__chapter-link--highlight"
                    >
                        Opera
                    </a>
                    ,{' '}
                    <a
                        href="https://support.microsoft.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ynq-privacy__chapter-link--highlight"
                    >
                        Internet Explorer
                    </a>
                    ,{' '}
                    <a
                        href="https://www.microsoft.com/en-us/edge"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ynq-privacy__chapter-link--highlight"
                    >
                        Microsoft Edge
                    </a>{' '}
                    und{' '}
                    <a
                        href="https://www.apple.com/safari/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ynq-privacy__chapter-link--highlight"
                    >
                        Safari
                    </a>
                    .
                </span>
                <span className="ynq-privacy__chapter-label--md">
                    Google Maps
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wir binden auf unserer Webseite zur besseren
                    Veranschaulichung eine Karte ein, die von der Google Inc.,
                    1600 Amphitheatre Parkway, Mountain View, CA 94043, USA über
                    die Google Maps API bereitgestellt wird. Wenn Sie sich die
                    Karte anzeigen lassen, wird eine Verbindung zum Google
                    Server hergestellt, bei der unter anderem die IP-Adresse an
                    Google übertragen wird. Google kann die Nutzungen der Google
                    Maps Funktion durch den Webseitenbesucher auswerten.
                    Weiterhin besteht für Google die Möglichkeit, Cookies zu
                    schreiben und zu lesen. Bei diesen Cookies kann es sich um
                    Google Nutzer Cookies handeln, die direkt mit Ihrer Person
                    verbunden sind. Die Rechtsgrundlage für die Einbindung von
                    Google Maps ist unser vorgenanntes berechtigtes Interesse
                    nach Art. 6 Abs. 1 lit. DS-GVO.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wenn Sie mehr über die Cookies, ihre Nutzung und Ihre
                    Möglichkeiten diesbezüglich erfahren möchten, können Sie
                    dies in unseren Bestimmungen zu Cookies nachlesen. Diese
                    erreichen Sie über die Footer Navigation am Ende der
                    Webseite.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Weitere Informationen zu Google Maps können Sie den
                    Datenschutzbedingungen und den Nutzungsbedingungen von
                    Google entnehmen:{' '}
                    <a
                        href="https://policies.google.com/privacy?hl=de"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ynq-privacy__chapter-link--highlight"
                    >
                        https://policies.google.com/privacy?hl=de
                    </a>
                </span>
                <span className="ynq-privacy__chapter-label--md">
                    Nutzung von Google Analytics
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wir verwenden Google Analytics, um die Website-Nutzung zu
                    analysieren. Die daraus gewonnenen Daten werden genutzt, um
                    unsere Website sowie Werbemaßnahmen zu optimieren.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Google Analytics ist ein Webanalysedienst, der von Google
                    Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043,
                    United States) betrieben und bereitgestellt wird. Google
                    verarbeitet die Daten zur Website-Nutzung in unserem Auftrag
                    und verpflichtet sich vertraglich zu Maßnahmen, um die
                    Vertraulichkeit der verarbeiteten Daten zu gewährleisten.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Während Ihres Website-Besuchs werden u.a. folgende Daten
                    aufgezeichnet:
                </span>
                <span className="ynq-privacy__chapter-text">
                    <ul className="ynq-privacy__chapter-list">
                        <li>• Aufgerufene Seiten</li>
                        <li>
                            • Bestellungen inkl. des Umsatzes und der bestellten
                            Services und Produkte
                        </li>
                        <li>
                            • Die Erreichung von {`"`}Website-Zielen{`"`} (z.B.
                            Kontaktanfragen und Newsletter-Anmeldungen)
                        </li>
                        <li>
                            • Ihr Verhalten auf den Seiten (beispielsweise
                            Klicks, Scroll-Verhalten und Verweildauer)
                        </li>
                        <li>• Ihr ungefährer Standort (Land und Stadt)</li>
                        <li>
                            • Ihre IP-Adresse (in gekürzter Form, sodass keine
                            eindeutige Zuordnung möglich ist)
                        </li>
                        <li>
                            • Technische Informationen wie Browser,
                            Internetanbieter, Endgerät und Bildschirmauflösung
                        </li>
                        <li>
                            • Herkunftsquelle Ihres Besuchs (d.h. über welche
                            Website bzw. über welches Werbemittel Sie zu uns
                            gekommen sind)
                        </li>
                    </ul>
                </span>
                <span className="ynq-privacy__chapter-text">
                    Diese Daten werden an einen Server von Google in den USA
                    übertragen. Google beachtet dabei die
                    Datenschutzbestimmungen des „EU-US Privacy
                    Shield“-Abkommens.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Google Analytics speichert Cookies in Ihrem Webbrowser für
                    die Dauer von zwei Jahren seit Ihrem letzten Besuch. Diese
                    Cookies enthalten eine zufallsgenerierte User-ID, mit der
                    Sie bei zukünftigen Website-Besuchen wiedererkannt werden
                    können.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Die aufgezeichneten Daten werden zusammen mit der
                    zufallsgenerierten User-ID gespeichert, was die Auswertung
                    pseudonymer Nutzerprofile ermöglicht. Diese nutzerbezogenen
                    Daten werden automatisch nach 14 Monaten gelöscht. Sonstige
                    Daten bleiben in aggregierter Form unbefristet gespeichert.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Sollten Sie mit der Erfassung nicht einverstanden sein,
                    können Sie diese mit der einmaligen Installation des
                    Browser-Add-ons zur Deaktivierung von Google Analytics
                    unterbinden.
                </span>
                <span className="ynq-privacy__chapter-label--md">
                    Log-Dateien
                </span>
                <span className="ynq-privacy__chapter-text">
                    Beim jedem Zugriff auf die Webseiten oder Apps von Younique
                    werden Nutzungsdaten durch den jeweiligen Internetbrowser
                    übermittelt und in Protokolldateien, den sogenannten
                    Server-Logfiles, gespeichert. Die dabei gespeicherten
                    Datensätze enthalten die folgenden Daten: Datum und Uhrzeit
                    des Abrufs, Name der aufgerufenen Seite, IP-Adresse,
                    Referrer-URL (Herkunfts-URL, von der aus Sie auf die
                    Webseite gekommen sind), die übertragene Datenmenge, sowie
                    Produkt und Versions-Informationen des verwendeten Browsers.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Die IP-Adressen der Nutzer werden nach Beendigung der
                    Nutzung gelöscht oder anonymisiert. Bei einer Anonymisierung
                    werden die IP-Adressen derart geändert, dass die
                    Einzelangaben über persönliche oder sachliche Verhältnisse
                    nicht mehr oder nur mit einem unverhältnismäßig großen
                    Aufwand an Zeit, Kosten und Arbeitskraft einer bestimmten
                    oder bestimmbaren natürlichen Person zugeordnet werden
                    können.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Diese Logfile-Datensätze werten wir in anonymisierter Form
                    aus, um unser Angebot und die Webseiten und Apps weiter zu
                    verbessern und nutzerfreundlicher zu gestalten, Fehler
                    schneller zu finden und zu beheben sowie Serverkapazitäten
                    zu steuern. So kann beispielsweise nachvollzogen werden, zu
                    welcher Zeit die Nutzung der Younique Webseiten und Apps
                    besonders beliebt ist und entsprechendes Datenvolumen zur
                    Verfügung stellen, um Ihnen einen schnellstmöglichen
                    Buchungsprozess zu gewährleisten.
                </span>
                <span className="ynq-privacy__chapter-text">
                    Darüber hinaus können wir auch durch eine Analyse der
                    Protokolldateien etwaige Fehler der Webseiten oder Apps
                    schneller erkennen und beheben.
                </span>
                <span className="ynq-privacy__chapter-label--md">
                    Melden einer Schwachstelle oder eines Sicherheitsvorfalls
                </span>
                <span className="ynq-privacy__chapter-text">
                    Sie sind Security Researcher oder White Hat-Hacker und
                    entdecken schon mal eine Sicherheitslücke (bspw. bei
                    Produkten, Services, Websites und andere
                    Infrastrukturkomponenten), die Sie vom betroffenen
                    Unternehmen gerne kurzfristig geschlossen haben möchten?
                </span>
                <span className="ynq-privacy__chapter-text">
                    Oder Sie haben Hinweise auf einen Sicherheitsvorfall und
                    möchten mit der verantwortlichen Sicherheitsabteilung
                    Kontakt aufnehmen?
                </span>
                <span className="ynq-privacy__chapter-text">
                    Sollte es sich bei dem betroffenen Unternehmen um die
                    Younique AG handeln, dann kontaktieren Sie uns bitte per
                    E-Mail:{' '}
                    <a
                        href="mailto:datenschutz@younique.org"
                        className="ynq-privacy__chapter-link--highlight"
                    >
                        datenschutz@younique.org
                    </a>
                </span>
                <span className="ynq-privacy__chapter-text">
                    Wir nehmen gemeldete Schwachstellen und Sicherheitsvorfälle
                    sehr ernst. Ihre Informationen erleichtern insbesondere
                    unseren Sicherheitsexperten ihre Arbeit um weiterhin einen
                    hohen Sicherheitslevel für die Group zu gewährleisten.
                </span>
                <span className="ynq-privacy__chapter-text">
                    © Younique - August 2020
                </span>
            </>
        ),
    },
];

export default privacyItems;
