import React from 'react';
import { Link } from 'react-router-dom';

import './membership-banner.css';
import RoundedButton from './rounded-button';

function MembershipBanner(props) {
    return (
        <div className="membership-banner">
            <div className="membership-container">
                <div className="item">
                    <div className="icon"></div>
                    <div className="text">6-months free membership</div>
                </div>
                <div className="item">
                    <div className="icon"></div>
                    <div className="text">Access to Special Discounts</div>
                </div>
                <div className="item">
                    <div className="icon"></div>
                    <div className="text">Free Breakfast</div>
                </div>
                <div className="item">
                    <div className="icon"></div>
                    <div className="text">Free Room Upgrades & Much More!</div>
                </div>
            </div>
            <div className="item">
                {/* // todo: include after connecting stable API  <Link to="/get-in-the-loop"> */}
                <Link to="/contact">
                    <RoundedButton icon />
                </Link>
            </div>
        </div>
    );
}

MembershipBanner.propTypes = {};

export default MembershipBanner;
