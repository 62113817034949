import {Instagram, Twitter, Facebook} from '../icons';
import React from 'react';

export const SOCIAL_MEDIA = [
	{
		key: 'fb',
		url: 'http://facebook.com',
		icon: <Facebook/>,
	},
	{
		key: 'twitter',
		url: 'http://twitter.com',
		icon: <Twitter/>,
	},
	{
		key: 'insta',
		url: 'http://instagram.com',
		icon: <Instagram/>,
	},
];
