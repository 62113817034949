import mapMarker from '@iconify/icons-uil/map-marker';

export const jobs = [
	{
		route: 'frontend',
		title: `Front-end\nDeveloper.`,
		breadCrumbs: [
			{
				name: 'Careers',
				url: '/careers'
			},
			{
				name: 'Front-end Developer',
				url: '/careers/frontend'
			}
		],
		timeBase: 'Full-time',
		isRemote: false,
		icon: mapMarker,
		location: 'Chisinau, Moldova',
		details: [
			{
				type: 'text',
				title: 'The role.',
				value: [
					{description: 'A great opportunity to make the difference; join our team at a company which cares. As a Software Engineer, you will be directly responsible for many of the innovations we’ll be working on, collaborating with outstanding talents and on bleeding-edge technology, to change the world.'}
				]
			},
			{
				type: 'list',
				title: 'In this role, you will:',
				value: [
					{description: 'Build innovative consumer experiences, setting standards for code and performance'},
					{description: 'Ship your code in days, iterating on features in an agile manner'},
					{description: 'Decide how to best build core systems to take advantage of our microservices architecture'},
					{description: 'Collaborate with a diverse group of people, giving and receiving feedback for growth'},
					{description: 'Execute with discipline, helping YOUNIQUE to become the leader in its space'},
				]
			},
			{
				type: 'list',
				title: 'The perfect candidate has:',
				value: [
					{description: 'Solid knowledge in at least 1 of the following programming languages: Angular 2+, React and VueJs'},
					{description: 'Solid knowledge and thrived in modern Frontend layout frameworks and technologies and delivered user experience at scale'},
					{description: 'Good knowledge of Design Patterns and OOD in general'},
					{description: 'Experience in consuming SOAP & REST web services (GraphQL)'},
					{description: 'Experience with Jira, Bitbucket or Git and other version control tools'},
					{description: 'The ability to bring leading industry techniques and technologies to the table'},
					{description: 'Excelled in cross-functional teams, working fluidly with Product Managers, Data Scientists, Mobile Engineers, and other highly skilled specialists'},
					{description: 'Knowledge and passion for code quality standards, experience with code quality tools'},
					{description: 'Strong analytical and creative problem-solving skills'},
					{description: 'Focus on efficiency, user experience, and process improvement'},
					{description: 'Enthusiasm and curiosity for conducting research and answering hard questions with data'},
					{description: 'Experience with SQL and NoSQL databases would be an asset'},
					{description: 'Experience with Azure cloud platform would be an asset'},
					{description: 'Experience mentoring and leading people at different stages in their career (team leader)'},
					{description: 'Desirable knowledge about continuous Integration, continuous Delivery and DevOps tools like docker, rancher, Jenkins, kubernetes, splunk, Prometheus'},
					{description: '(Middle) At least 3 years working as a software engineer'},
					{description: '(Senior) At least 5 years working as a software engineer'},
					{description: '(Team Leader) At least 5 years working as a software engineer and 2+ years of leading a team'},
					{description: 'Communicate effectively with their colleagues in English'},
				]
			},
			{
				type: 'text',
				title: 'About the team.',
				value: [
					{description: 'Our engineering team is the essence of our mission and the baseline for the success of YOUNIQUE and tries to build and deliver the best possible experience. You will work with a winning team on an exceptional ambitious initiative, which goes beyond a simple product.'}
				]
			},
			{
				type: 'list',
				title: 'Benefits.',
				value: [
					{description: 'Competitive salaries'},
					{description: 'Modern working environment'},
					{description: 'Flexible working hours'},
					{description: 'Dynamic and entrepreneurial spirit where pushing limits is everyday business'},
				]
			}
		]
	},
	{
		route: 'backend',
		title: 'Back-end\nDeveloper.',
		breadCrumbs: [
			{
				name: 'Careers',
				url: '/careers'
			},
			{
				name: 'Back-end Developer',
				url: '/careers/backend'
			}
		],
		timeBase: 'Full-time',
		isRemote: false,
		icon: mapMarker,
		location: 'Chisinau, Moldova',
		details: [
			{
				type: 'text',
				title: 'The role.',
				value: [
					{description: 'A great opportunity to make the difference; join our team at a company which cares. As a Software Engineer, you will be directly responsible for many of the innovations we’ll be working on, collaborating with outstanding talents and on bleeding-edge technology, to change the world.'}
				]
			},
			{
				type: 'list',
				title: 'In this role, you will:',
				value: [
					{description: 'Build innovative consumer experiences, setting standards for code and performance'},
					{description: 'Ship your code in days, iterating on features in an agile manner'},
					{description: 'Decide how to best build core systems to take advantage of our microservices architecture'},
					{description: 'Collaborate with a diverse group of people, giving and receiving feedback for growth'},
					{description: 'Execute with discipline, helping YOUNIQUE to become the leader in its space'},
				]
			},
			{
				type: 'list',
				title: 'The perfect candidate has:',
				value: [
					{description: 'Solid knowledge in Core/Advanced Java and Spring Frameworks – Spring MVC, Spring Boot, Spring Data (JPA/JDBC), Spring Cloud'},
					{description: 'Solid knowledge and thrived in modern Backend technologies and delivered user experience at scale'},
					{description: 'Good knowledge of Design Patterns and OOD in general'},
					{description: 'Experience in writing and consuming SOAP & REST web services'},
					{description: 'Experience in building applications using Microservices'},
					{description: 'Experience with Jira, Bitbucket or Git and other version control tools'},
					{description: 'The ability to bring leading industry techniques and technologies to the table'},
					{description: 'Excelled in cross-functional teams, working fluidly with Product Managers, Data Scientists, Mobile Engineers, and other highly skilled specialists'},
					{description: 'Knowledge and passion for code quality standards, experience with code quality tools'},
					{description: 'Strong analytical and creative problem-solving skills'},
					{description: 'Focus on efficiency, user experience, and process improvement'},
					{description: 'Enthusiasm and curiosity for conducting research and answering hard questions with data'},
					{description: 'Good understanding and knowledge of database design and optimizing application access to backend data; experience with SQL and NoSQL databases would be an asset'},
					{description: 'Experience with Azure cloud platform would be an asset'},
					{description: 'Experience mentoring and leading people at different stages in their career (team leader)'},
					{description: 'Desirable knowledge about continuous Integration, continuous Delivery and DevOps tools like docker, rancher, Jenkins, kubernetes, splunk, Prometheus'},
					{description: '(Middle) At least 3 years working as a software engineer'},
					{description: '(Senior) At least 5 years working as a software engineer'},
					{description: '(Team Leader) At least 5 years working as a software engineer and 2+ years of leading a team'},
					{description: 'Communicate effectively with their colleagues in English'},
				]
			},
			{
				type: 'text',
				title: 'About the team.',
				value: [
					{description: 'Our engineering team is the essence of our mission and the baseline for the success of YOUNIQUE and tries to build and deliver the best possible experience. You will work with a winning team on an exceptional ambitious initiative, which goes beyond a simple product.'}
				]
			},
			{
				type: 'list',
				title: 'Benefits.',
				value: [
					{description: 'Competitive salaries'},
					{description: 'Modern working environment'},
					{description: 'Flexible working hours'},
					{description: 'Dynamic and entrepreneurial spirit where pushing limits is everyday business'},
				]
			}
		]
	},
	{
		route: 'mobile',
		title: 'Mobile\nDeveloper.',
		breadCrumbs: [
			{
				name: 'Careers',
				url: '/careers'
			},
			{
				name: 'Mobile Developer',
				url: '/careers/mobile'
			}
		],
		timeBase: 'Full-time',
		isRemote: false,
		icon: mapMarker,
		location: 'Chisinau, Moldova',
		details: [
			{
				type: 'text',
				title: 'The role.',
				value: [
					{description: 'A great opportunity to make the difference; join our team at a company which cares. As a Software Engineer, you will be directly responsible for many of the innovations we’ll be working on, collaborating with outstanding talents and on bleeding-edge technology, to change the world.'}
				]
			},
			{
				type: 'list',
				title: 'In this role, you will:',
				value: [
					{description: 'Build innovative consumer experiences, setting standards for code and performance'},
					{description: 'Ship your code in days, iterating on features in an agile manner'},
					{description: 'Decide how to best build core systems to take advantage of our microservices architecture'},
					{description: 'Collaborate with a diverse group of people, giving and receiving feedback for growth'},
					{description: 'Execute with discipline, helping YOUNIQUE to become the leader in its space'},
				]
			},
			{
				type: 'list',
				title: 'The perfect candidate has:',
				value: [
					{description: 'Good experience in mobile development (iOS and/or Android)'},
					{description: 'Solid knowledge with mobile languages / technologies, especially with: React native, Java, Objective-C and C++'},
					{description: 'Solid knowledge and thrived in modern Frontend layout frameworks and technologies and delivered user experience at scale'},
					{description: 'Good knowledge of Design & Development Paradigms spanning user experience (native & web views), data ingestion, analytics and webservices'},
					{description: 'Experience in consuming SOAP & REST web services (GraphQL)'},
					{description: 'Experience with Jira, Bitbucket or Git and other version control tools'},
					{description: 'The ability to bring leading industry techniques and technologies to the table'},
					{description: 'Excelled in cross-functional teams, working fluidly with Product Managers, Data Scientists, Mobile Engineers, and other highly skilled specialists'},
					{description: 'Knowledge and passion for code quality standards, experience with code quality tools'},
					{description: 'Strong analytical and creative problem-solving skills'},
					{description: 'Focus on efficiency, user experience, and process improvement'},
					{description: 'Enthusiasm and curiosity for conducting research and answering hard questions with data'},
					{description: 'Having published apps in app store/market, ideally with server side interaction will be an asset'},
					{description: 'Experience with SQL and NoSQL databases would be an asset'},
					{description: 'Experience mentoring and leading people at different stages in their career (team leader)'},
					{description: 'Desirable knowledge about continuous Integration, continuous Delivery and DevOps tools like docker, rancher, Jenkins, kubernetes, splunk, Prometheus'},
					{description: '(Middle) At least 3 years working as a software engineer'},
					{description: '(Senior) At least 5 years working as a software engineer'},
					{description: '(Team Leader) At least 5 years working as a software engineer and 2+ years of leading a team'},
					{description: 'Communicate effectively with their colleagues in English'},
				]
			},
			{
				type: 'text',
				title: 'About the team.',
				value: [
					{description: 'Our engineering team is the essence of our mission and the baseline for the success of YOUNIQUE and tries to build and deliver the best possible experience. You will work with a winning team on an exceptional ambitious initiative, which goes beyond a simple product.'}
				]
			},
			{
				type: 'list',
				title: 'Benefits.',
				value: [
					{description: 'Competitive salaries'},
					{description: 'Modern working environment'},
					{description: 'Flexible working hours'},
					{description: 'Dynamic and entrepreneurial spirit where pushing limits is everyday business'},
				]
			}
		]
	}
];
