import file from 'components/manifesto/manifesto.pdf';
import React from 'react';
import { Icon } from '@iconify/react';
import envelopeCheck from '@iconify/icons-uil/envelope-check';
import documentInfo from '@iconify/icons-uil/document-info';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FooterComponent from '../footer/FooterComponent';
import Header from 'components/stateless/header';
import gtag from 'utils/gtag';

import { defaultMenu } from 'components/stateless/header-items';
import { FOOTER_ALLIANCE_CATEGORIES } from 'utils/footer-categories';
import 'components/manifesto/Manifesto.css';

class Manifesto extends React.Component {
    constructor(props) {
        super(props);

        gtag.events.pageView('Manifesto');
    }

    render() {
        return (
            <>
                {/* Header */}
                <Header
                    height={600}
                    page={'general'}
                    bk={'manifesto'}
                    menu={defaultMenu}
                >
                    <div className="row mContainer justify-content-start justify-content-sm-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9 p-0">
                        <div className="manifesto-content-container col-11 col-sm-9 col-md-6 col-lg-6 col-xl-6 p-0">
                            <div className="manifesto-item-content px-2 px-sm-0">
                                <h1 className="manifesto-item-title">
                                    <span>Manifesto.</span>
                                </h1>

                                <button
                                    className="manifesto-join-the-revolution-btn"
                                    onClick={() =>
                                        this.props.history.push(
                                            // '/join-the-revolution
                                            '/contact'
                                        )
                                    }
                                >
                                    Join the Revolution{' '}
                                    <Icon
                                        className="buttonIcon"
                                        icon={envelopeCheck}
                                    />
                                </button>
                            </div>
                        </div>
                        <div className="manifesto-item-img img-8 col-11 col-sm-9 col-md-6 col-lg-5 col-xl-6 p-0 d-none d-md-block" />
                    </div>
                </Header>

                {/* Content */}
                <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                    <Col className="manifesto-container p-md-0 d-md-flex justify-content-center justify-content-md-between">
                        <Col
                            className="manifesto-chapter pb-1 p-md-0"
                            xs={10}
                            sm={9}
                            md={10}
                            lg={5}
                            xl={6}
                        >
                            <div>This is what we stand for</div>
                        </Col>
                        <Col
                            className="manifesto-text p-md-0"
                            xs={10}
                            sm={9}
                            md={10}
                            lg={5}
                            xl={6}
                        >
                            As a professional working in the hotel industry, as
                            a hotelier, you find yourself in quite a challenging
                            era. As the internet came along, some twenty years
                            ago, it was bearing prospect of endless growth and
                            abundance: it was true after all that you could
                            suddenly reach your customers in ways that weren’t
                            thinkable before. No physical intermediaries, no
                            expensive advertisement in press and magazines: here
                            was your customer, booking a room from the other
                            side of the world clicking on your website. When
                            search engines became a thing – in fact, when Google
                            became the thing – it seemed only reasonable to
                            invest in ways to be found by your customers, be it
                            SEO or Search Engine Marketing. It was an effort, it
                            took time to understand and to adapt, it took skills
                            that were new to you. Online Travel Agents popped
                            up. And the commission they asked seemed only a
                            reasonable price to pay to be relieved of all that.
                            The search optimization. The advertising to Google.
                            Things that were not part of your core business and
                            that seemed – and are –{' '}
                            <b className="keyword">troublesome and a burden</b>.
                            Yes, let them do it. Let them deal with it. It’s
                            their thing, you do what you do best:
                            hospitality.And so they did. You signed up and
                            abdicated your power to them. But it was reasonable.
                            It was indeed their core business to do online
                            marketing and to make sure you were found by
                            clients. They kept the promise in that new business
                            kept coming.The thing is, when you externalize
                            functions, in this case promotion – even with the
                            right intent to focus on what you do best – a shift
                            in power happens. And the power shifted. The
                            dependency became relevant and the competition to
                            these giants? Non-existent.Fast forward today, and
                            you find yourself in a corner squeezed between
                            impossible commissions, eroding your margins to the
                            point where you can’t even do well{' '}
                            <b className="keyword">what you do best</b>. They
                            got you. You feel like you’re not even doing
                            accommodation anymore, you are working as an
                            employee to OTA’scutting here and there in your core
                            business trying to make ends meet. Your customers?
                            You barely know them. They barely know you. You are
                            just a listing on an OTA search engine and a
                            commodity – after all, you all look the same online,
                            and the customer experience isn’t broad enough to
                            make those customers loyal, committed, affectionate
                            to your hotel.You might have tried a few
                            alternatives already: no business came through them,
                            and eventually you gave up. That’s the way it is,
                            you might as well accept it.Is it though?Here’s to a
                            lifetime of hard work to pay crazy up to 30% of
                            distribution costs. Sounds exciting. Is that why you
                            became an entrepreneur after all?What if.What if you
                            took back the power.
                            <br /> What if you poured back some fairness into
                            the game.Imagine that: a world where you’re not
                            suffering under the weight of online giants who
                            don’t have your best interest at heart.A world where
                            you are Younique again.
                            <Row className="pt-4 pt-md-5">
                                <Col xs={12} sm={10} md={11} lg={8} xl={8}>
                                    <a
                                        className="btn dwn-pdf-btn"
                                        href={file}
                                        download={'manifesto.pdf'}
                                    >
                                        Download PDF{' '}
                                        <Icon
                                            className="buttonIcon"
                                            icon={documentInfo}
                                        />
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>

                {/*<Row className="pt-4 pt-md-5 justify-content-sm-center text-center">*/}
                {/*	<Col xs={12} sm={10} md={11} lg={8} xl={8}>*/}
                {/*		<a className="btn dwn-pdf-btn" href={file} download={'manifesto.pdf'}>*/}
                {/*			Download PDF <Icon className="buttonIcon" icon={documentInfo} />*/}
                {/*		</a>*/}
                {/*	</Col>*/}
                {/*</Row>*/}

                {/* Footer */}
                <FooterComponent
                    target="alliance"
                    menu={FOOTER_ALLIANCE_CATEGORIES}
                />
            </>
        );
    }
}

export default Manifesto;
