import React from 'react';
import PropTypes from 'prop-types';

import './rounded-button.css';

function RoundedButton({
                           children = 'Join us',
                           icon = false,
                           style = {},
                           onClick = () => {
                           },
                       }) {
    return (
        <button onClick={onClick} style={style} className="rounded-button">
            {icon ? <div className="rounded-button-icon"/> : ''}
            {children}
        </button>
    );
}

RoundedButton.propTypes = {
    children: PropTypes.any,
    icon: PropTypes.bool,
    style: PropTypes.object,
    onClick: PropTypes.func,
};

export default RoundedButton;
