import React from 'react';
import PropTypes from 'prop-types';
import InputComponent from 'components/form/input';
import classNames from 'classnames';

const CommonContactDetails = ({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  setFieldValue,
                                  isDisabled,
                              }) => {
    return (
        <>
            <div className="ynq-subscription-profile__row">
                <InputComponent
                    id="contactDetails.webSiteUrl"
                    name="contactDetails.webSiteUrl"
                    type="text"
                    label={
                        values.registrationType === 'CHAIN'
                            ? 'CHAIN WEBSITE'
                            : 'HOTEL WEBSITE'
                    }
                    labelClassName="ynq-subscription-profile__label"
                    value={values.contactDetails.webSiteUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="-"
                    isRequired
                    disabled={isDisabled}
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.contactDetails?.webSiteUrl &&
                            touched.contactDetails?.webSiteUrl,
                    })}
                />
            </div>
            <div className="ynq-subscription-profile__row">
                <InputComponent
                    id="contactDetails.email"
                    name="contactDetails.email"
                    type="text"
                    label={
                        values.registrationType === 'CHAIN'
                            ? 'CHAIN EMAIL'
                            : 'HOTEL EMAIL'
                    }
                    labelClassName="ynq-subscription-profile__label"
                    value={values.contactDetails.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="-"
                    isRequired
                    disabled={isDisabled}
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.contactDetails?.email &&
                            touched.contactDetails?.email,
                    })}
                />
            </div>
            <div className="ynq-subscription-profile__row">
                <InputComponent
                    id="contactDetails.phone"
                    name="contactDetails.phone"
                    type="text"
                    label={
                        values.registrationType === 'CHAIN'
                            ? 'CHAIN PHONE'
                            : 'Hotel PHONE'
                    }
                    labelClassName="ynq-subscription-profile__label"
                    value={values.contactDetails.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="-"
                    isRequired
                    disabled={isDisabled}
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.contactDetails?.phone &&
                            touched.contactDetails?.phone,
                    })}
                />
            </div>
            <div className="ynq-subscription-profile__row">
                <InputComponent
                    id="contactDetails.tollFreeNumber"
                    name="contactDetails.tollFreeNumber"
                    type="text"
                    label={
                        values.registrationType === 'CHAIN'
                            ? 'CHAIN TOLL FREE NUMBER'
                            : 'Hotel TOLL FREE NUMBER'
                    }
                    labelClassName="ynq-subscription-profile__label"
                    value={values.contactDetails.tollFreeNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="-"
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.contactDetails?.tollFreeNumber &&
                            touched.contactDetails?.tollFreeNumber,
                    })}
                />
            </div>
        </>
    );
};

CommonContactDetails.propTypes = {
    form: PropTypes.object,
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldValue: PropTypes.func,
    isDisabled: PropTypes.bool,
};

export default CommonContactDetails;
