import React from 'react';
import { jobs } from 'components/job-details/job-details';
import FooterComponent from '../footer/FooterComponent';
import Header from 'components/stateless/header';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon } from '@iconify/react';
import fileCheckAlt from '@iconify/icons-uil/file-check-alt';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import gtag from 'utils/gtag';

import { defaultMenu } from 'components/stateless/header-items';
import { FOOTER_ALLIANCE_CATEGORIES } from 'utils/footer-categories';
import 'components/job-details/JobDetails.css';

class JobDetails extends React.Component {
    constructor() {
        super();
        this.state = {
            job: null,
            breadCrumbs: null,
        };
        gtag.events.pageView('JobDetails');
    }

    componentDidMount() {
        this.job = jobs.find(job => job.route === this.props.match.params.id);
        const array = this.getParentRoutes();
        array.push(this.job.title);
        this.setState({ job: this.job, breadCrumbs: this.job.breadCrumbs });
    }

    getParentRoutes = () => {
        const path = this.props.location.pathname.substring(
            1,
            this.props.location.pathname.length
        );
        const pathItems = path.split('/');
        const array = [];
        pathItems.forEach((item, index) => {
            if (index !== pathItems.length - 1) {
                array.push(item);
            }
        });
        return array;
    };

    getDetail = item => {
        switch (item.type) {
            case 'text':
                return (
                    <div key={item.title} className="item-content col-sm-10">
                        <span className="item-title">{item.title}</span>
                        <span className="item-text pr-0">
                            {item.value[0].description}
                        </span>
                    </div>
                );
            case 'list':
                return (
                    <div key={item.title} className="item-content col-sm-10">
                        <span className="item-title">{item.title}</span>
                        <ul>
                            {item.value.map(val => (
                                <li
                                    key={val.description}
                                    className="checked-element item-text"
                                >
                                    {val.description}
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            default:
                return null;
        }
    };

    render() {
        return (
            <>
                {/* Header */}
                <Header
                    title={this.state.job ? this.state.job.title : null}
                    crumbs={this.state.job ? this.state.job.breadCrumbs : null}
                    height={600}
                    page={'home'}
                    bk={'job'}
                    titlePosition={'start'}
                    menu={defaultMenu}
                />

                {/* Jpbb details */}
                <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                    <Col className="job-container p-0 p-lg-0 d-flex d-md-flex justify-content-center justify-content-md-between">
                        <Col className="job-chapter pb-1 p-lg-0 d-none d-md-block col-xl-2 col-lg-2 col-md-2 col-sm-2 col-10">
                            <div>Overview</div>
                        </Col>
                        <Col className="job-text p-0 p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                            {this.state.job
                                ? this.state.job.details.map(item =>
                                      this.getDetail(item)
                                  )
                                : null}
                            {this.state.job ? (
                                // <NavLink className="apply-btn mb-3" to={`/careers/${this.state.job.route}/apply`}>
                                <NavLink
                                    className="apply-btn mb-3"
                                    to={`/contact`}
                                >
                                    Apply for this role
                                    <Icon
                                        className="buttonIcon"
                                        icon={fileCheckAlt}
                                    />
                                </NavLink>
                            ) : null}
                        </Col>
                    </Col>
                </Row>

                {/* Footer */}
                <FooterComponent
                    target="alliance"
                    menu={FOOTER_ALLIANCE_CATEGORIES}
                />
            </>
        );
    }
}

export default withRouter(JobDetails);
