import 'components/contact-us/Contact-us.css';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../stateless/header';
import FooterComponent from '../footer/FooterComponent';
import FormControl from 'utils/form-control';
import { contactForm } from 'components/contact-us/contact-form';
import Validate from 'utils/validator';
import { Icon } from '@iconify/react';
import fileCheckAlt from '@iconify/icons-uil/file-check-alt';
import gtag from 'utils/gtag';
import Spinner from 'react-bootstrap/Spinner';
import AlertComponent from 'utils/alert/AlertComponent';
// import { CheckFilled, TimesCircleFilled } from 'icons';
import { defaultMenu } from 'components/stateless/header-items';
import { FOOTER_ALLIANCE_CATEGORIES } from 'utils/footer-categories';

class ContactUs extends React.Component {
    constructor() {
        super();
        gtag.events.pageView('ContactUs');
        this.env = process.env.REACT_APP_NODE_API;
        this.formDefinition = [];
        this.getFormDefinition();
        this.captchaRef = React.createRef();
        this.state = {
            asyncInProgress: false,
            formIsValid: false,
            formControls: this.formDefinition,
            formEvent: { text: '', type: '', icon: null },
        };
    }

    getFormDefinition = () => {
        contactForm.forEach(item => this.formDefinition.push(item));
        return this.formDefinition;
    };

    resetForm = () => {
        this.captchaRef.current.reset();
        contactForm.map(item => {
            item.value = '';
            item.touched = false;
            item.valid = false;
            return item;
        });
    };

    changeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;

        const updatedFormElement = this.state.formControls.find(
            item => item.name === name
        );
        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = Validate(
            value,
            updatedFormElement.validationRules
        );

        this.state.formControls
            .filter(item => item.name === name)
            .map(() => updatedFormElement);

        let formIsValid = true;
        this.state.formControls.forEach(control => {
            formIsValid = control && formIsValid;
        });

        this.setState({
            formControls: this.state.formControls,
            formIsValid: formIsValid,
        });
    };

    formSubmitHandler = e => {
        if (e) e.preventDefault();
        const formData = {};
        let formIsValid = true;

        this.state.formControls.forEach(control => {
            control.touched = true;
            control.valid = Validate(control.value, control.validationRules);
            formData[control.name] = control.value;
            formIsValid = control.valid && formIsValid;
        });
        this.setState({
            formControls: this.state.formControls,
            formIsValid: formIsValid,
        });

        if (formIsValid) {
            const form = this.bindFormData(formData);
            this.postForm(form);
        }
    };

    bindFormData = formdata => {
        return {
            name: formdata.firstName,
            last_name: formdata.lastName,
            email: formdata.email.toLowerCase(),
            description: formdata.message,
            'g-recaptcha-response': formdata['g-recaptcha-response'],
        };
    };

    postForm = formData => {
        // todo: Include after API connect
        // const options = {
        //     method: 'POST',
        //     headers: {
        //         'Content-type': 'application/json',
        //         'g-recaptcha-response': formData['g-recaptcha-response'],
        //     },
        //     body: JSON.stringify({
        //         name: formData.name,
        //         last_name: formData.last_name,
        //         email: formData.email,
        //         description: formData.description,
        //     }),
        // };
        // this.setState({ asyncInProgress: true });
        // fetch(`${this.env}/api/v1/contact/us/form`, options)
        //     .then(response => Promise.all([response.ok, response.json()]))
        //     .then(([ok, json]) => {
        //         if (ok) {
        //             this.resetForm();
        //             this.setState({
        //                 asyncInProgress: false,
        //                 formEvent: {
        //                     text: 'Message sent successfully!',
        //                     type: 'ynq-alert--success',
        //                     icon: <CheckFilled />
        //                 },
        //                 formControls: this.getFormDefinition(),
        //             });
        //         } else {
        //             this.setState({ asyncInProgress: false });
        //             throw new Error(json.errors[0].message);
        //         }
        //     })
        //     .catch(err => {
        //         return this.setState({
        //             asyncInProgress: false,
        //             formEvent: {
        //                 text: err.message,
        //                 type: 'ynq-alert--error',
        //                 icon: <TimesCircleFilled />
        //             },
        //         });
        //     });
    };

    dismiss = () => {
        this.setState({
            formEvent: { text: '', type: '', icon: null },
        });
    };

    render() {
        return (
            <>
                {/* Header */}
                <Header
                    title={`Contact us.`}
                    titlePosition={'start'}
                    height={600}
                    page={'home'}
                    bk={'contact'}
                    menu={defaultMenu}
                />

                {/* Contact info row */}
                <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                    <Col className="contact-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                        <Col className="contact-chapter pb-1 p-lg-0 d-none d-md-block col-xl-2 col-lg-2 col-md-2 col-sm-2 col-10">
                            <div>
                                Company
                                <br /> details.
                            </div>
                        </Col>
                        <Col className="contact-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                            <div className="item-content col-sm-10">
                                <span className="item-title">Address.</span>
                                <span className="item-text pr-0">
                                    YOUNIQUE AG
                                    <br /> Wielandstrasse 34
                                    <br /> 10629 Berlin
                                    <br /> Germany
                                </span>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-title">Phone.</span>
                                <a
                                    href="tel:+49 (0)30 2201 330 70"
                                    className="item-text pr-0"
                                >
                                    +49 (0)30 2201 330 70
                                </a>
                            </div>
                            <div className="item-content col-sm-10">
                                <span className="item-title">Email.</span>
                                <a
                                    href="mailto:"
                                    className="item-text email pr-0"
                                >
                                    hello@younique.org
                                </a>
                            </div>
                        </Col>
                    </Col>
                </Row>

                {/* Contact form row */}
                <form action="https://formspree.io/f/xnqyjyda" method="POST">
                    <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                        <Col className="contact-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                            <Col className="contact-chapter pb-1 p-lg-0 col-xl-2 col-lg-2 col-md-10 col-sm-10 col-10">
                                <div>Send us a message.</div>
                            </Col>
                            <Col className="contact-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                                <FormControl
                                    rf={this.captchaRef}
                                    controls={contactForm}
                                    onClick={this.changeHandler}
                                />
                            </Col>
                        </Col>
                    </Row>
                    {this.state.formEvent && this.state.formEvent.text ? (
                        <Row className="px-2 pt-4 justify-content-center px-sm-5">
                            <Col className="contact-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                                <Col className="contact-chapter pb-1 p-lg-0 col-xl-2 col-lg-2 col-md-10 col-sm-10 col-10" />
                                <Col className="contact-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                                    <AlertComponent
                                        className={this.state.formEvent.type}
                                        btnClassName={this.state.formEvent.btn}
                                        icon={this.state.formEvent.icon}
                                        text={this.state.formEvent.text}
                                        onAction={this.dismiss}
                                    />
                                </Col>
                            </Col>
                        </Row>
                    ) : null}
                    <Row className="px-2 pt-4 justify-content-center px-sm-5">
                        <Col className="contact-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                            <Col className="contact-chapter pb-1 p-lg-0 col-xl-2 col-lg-2 col-md-10 col-sm-10 col-10" />
                            <Col className="contact-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                                <button
                                    type="submit"
                                    className="contacts-submit-btn mb-3"
                                    // onClick={this.formSubmitHandler}
                                >
                                    {this.state.asyncInProgress ? (
                                        <Spinner
                                            animation="border"
                                            variant="secondary"
                                        />
                                    ) : (
                                        <span>
                                            Submit{' '}
                                            <Icon
                                                className="buttonIcon"
                                                icon={fileCheckAlt}
                                            />
                                        </span>
                                    )}
                                </button>
                            </Col>
                        </Col>
                    </Row>
                </form>

                {/* Footer */}
                <FooterComponent
                    target="alliance"
                    menu={FOOTER_ALLIANCE_CATEGORIES}
                />
            </>
        );
    }
}

export default ContactUs;
