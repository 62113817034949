import React from 'react';
import PropTypes from 'prop-types';
import elipsisH from '@iconify/icons-fe/elipsis-h';
import {Icon} from '@iconify/react';
import './menu-button.scss';

const MenuButton = ({open, setOpen}) => {
    return (
        <button className="dot-menu p-0" onClick={() => setOpen(!open)}>
            <Icon icon={elipsisH}/>
        </button>
    );
};

MenuButton.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
};

export default MenuButton;
