import React from 'react';
import Header from 'components/stateless/header';
import {defaultMenu} from 'components/stateless/header-items';
import FooterComponent from 'components/footer';
import {FOOTER_ALLIANCE_CATEGORIES} from 'utils/footer-categories';
import ButtonComponent from 'components/button';
import {DocumentLayoutLeft} from 'icons';
import welcomeOptions from 'components/welcome/options/welcome-options';
import WelcomeOptionComponent from 'components/welcome/options/WelcomeOptionComponent';
import {useHistory} from 'react-router-dom';
import './WelcomeComponent.scss';

const WelcomeComponent = () => {
    const history = useHistory();
    const shareholder = JSON.parse(localStorage.getItem('shareholder'));

    return (
        <>
            {/* Header */}
            <Header height={600} page={'home'} bk={'home'} menu={defaultMenu}>
                <div
                    className="row mContainer justify-content-start justify-content-sm-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9 p-0">
                    <div className="impressum-content-container p-0">
                        <div className="impressum-item-content px-2 px-sm-0">
                            <span className="impressum-item-title">
                                …welcome to the revolution
                            </span>
                        </div>
                    </div>
                </div>
            </Header>

            {/* About the project row */}
            <div className="container--large ynq-welcome__container">
                <span className="ynq-welcome__title">
                    {shareholder?.firstName}, welcome to Younique.
                </span>
                <span className="ynq-welcome__description">
                    As a registered hotelier, we assume you have understood the
                    aim and ambition of Younique. Nevertheless, let’s quickly
                    summarize, what Younique is all about.
                </span>
                <span className="ynq-welcome__statement">
                    The Mission of Younique is to create an alternative online
                    distribution channel, for hotels, owned and controlled by
                    hotels.
                </span>
                <span className="ynq-welcome__description">
                    With very few large OTA’s dominating the online travel
                    space, there is an urgent need to create a counterbalance,
                    controlled by the hotel industry itself. Individual
                    hoteliers, but even groups and chains of hotels do not have
                    the leverage to make this change. Only a strong industry
                    alliance, of potentially every hotel on the globe, can make
                    this revolution successful. Participation of every hotelier
                    is therefore essential. <br/> <br/> To accommodate
                    possibly every type of commitment, we have created three
                    options to join the alliance
                </span>
            </div>

            <div className="container--large ynq-welcome__container">
                <div className="ynq-welcome__option-wrapper">
                    {welcomeOptions.map((item, idx) => (
                        <WelcomeOptionComponent
                            key={idx}
                            img={item.img}
                            title={item.title}
                            to={item.to}
                            description={item.description}
                        />
                    ))}
                </div>
            </div>

            {/* Manifesto paper row */}
            <div className="container--large ynq-welcome__container">
                <div className="ynq-welcome__manifesto">
                    <div className="ynq-welcome__manifesto-text">
                        <span className="ynq-welcome__manifesto-title">
                            Manifesto.
                        </span>
                        <span className="ynq-welcome__manifesto-description">
                            Fast forward today, and you find yourself in a
                            corner squeezed between impossible commissions,
                            eroding your margins to the point where you can’t
                            even do well what you do best
                        </span>
                    </div>
                    <div className="ynq-welcome__manifesto-paper"/>
                </div>
            </div>

            <div className="container--large ynq-welcome__container">
                <div className="ynq-welcome__action">
                    <ButtonComponent
                        title="Read the Manifesto"
                        icon={<DocumentLayoutLeft/>}
                        className="ynq-button--grey-9 ynq-button--reverse"
                        onClick={() => history.push('/manifesto-b2b')}
                    >
                        Read the Manifesto
                    </ButtonComponent>
                </div>
            </div>
            {/* Footer */}
            <FooterComponent
                target="alliance"
                menu={FOOTER_ALLIANCE_CATEGORIES}
            />
        </>
    );
};

export default WelcomeComponent;
