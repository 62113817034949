import React, {useState} from 'react';
import {CheckFilled} from 'icons';
import Button from 'components/common/button/Button';
import './EmailAlert.scss';

const EmailAlertComponent = () => {
    const bannerState = JSON.parse(
        sessionStorage.getItem('registrationComplete')
    );
    const [banner, toggleBanner] = useState(bannerState || false);
    const dismiss = () => {
        sessionStorage.removeItem('registrationComplete');
        toggleBanner(false);
    };

    return (
        <>
            {banner && (
                <div className="ynq-email-alert-banner">
                    <CheckFilled/>
                    <span>
                        An email was sent to your inbox! Please check it for
                        additional details.
                    </span>
                    <Button onClick={dismiss}>Close</Button>
                </div>
            )}
        </>
    );
};

export default EmailAlertComponent;
