export const INPUT_TYPES = {
	number: 'number',
	email: 'email',
	password: 'password',
	search: 'search',
	tel: 'tel',
	text: 'text',
	checkbox: 'checkbox',
	radio: 'radio',
	calendar: 'calendar',
};

export const SIDE_LIST_TYPES = {
	hyperLinks: 'hyperLinks',
	hashLinks: 'hashLinks',
	navLinks: 'navLinks',
	tabs: 'tabs',
};

export const SIDE_LIST_DIRECTION = {
	row: 'row',
	column: 'column',
};

export const BADGE_TYPE = {
	removable: 'removable',
	outline: 'outline',
	outlineBlack: 'outline-black',
	primary: 'primary',
	success: 'success',
	alert: 'alert',
	error: 'error',
	black: 'black',
};

export const AUTH_TYPES = {
	younique: 'YOUNIQUE',
	google: 'GOOGLE',
	facebook: 'FACEBOOK',
	email: 'EMAIL',
};

export const STEPPER_TYPES = {
	increase: 'INCREASE',
	decrease: 'DECREASE',
};
