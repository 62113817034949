import React from 'react';
import adjustCircle from '@iconify/icons-uil/adjust-circle';
import buildingIcon from '@iconify/icons-uil/building';
import userCircle from '@iconify/icons-uil/user-circle';

export const formControls = [
    {
        name: 'Personal Information.',
        icon: adjustCircle,
        controls: [
            {
                type: 'element',
                name: 'top element',
                touched: true,
                valid: true,
                value: (
                    <div className="form-top">
                        <div className="list-label">
                            Enjoy special Benefits & Privileges,{' '}
                            <b>6 months for free*</b>:
                        </div>
                        <ul className="benefits-list">
                            <li>
                                <div className="benefits-icon"/>
                                <div className="benefits-text">
                                    10% discount
                                </div>
                            </li>
                            <li>
                                <div className="benefits-icon"/>
                                <div className="benefits-text">
                                    Free breakfast
                                </div>
                            </li>
                            <li>
                                <div className="benefits-icon"/>
                                <div className="benefits-text">
                                    Room upgrades
                                </div>
                            </li>
                            <li>
                                <div className="benefits-icon"/>
                                <div className="benefits-text">...and more</div>
                            </li>
                        </ul>
                        <div className="benefits-description">
                            *limited to participating hotels
                        </div>
                    </div>
                ),
                hidden: false,
            },
            {
                type: 'text',
                name: 'firstName',
                value: '',
                label: 'First Name',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: true,
                validationRules: {
                    minLength: 1,
                    maxLength: 255,
                    isRequired: true,
                },
            },
            {
                type: 'text',
                name: 'lastName',
                value: '',
                label: 'Last Name',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: true,
                validationRules: {
                    minLength: 1,
                    maxLength: 255,
                    isRequired: true,
                },
            },
            {
                type: 'text',
                name: 'email',
                value: '',
                label: 'Email Address',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: true,
                validationRules: {
                    maxLength: 318,
                    isRequired: true,
                    isEmail: true,
                },
            },
        ],
    },
    {
        name: 'Refer a Friend.',
        icon: buildingIcon,
        controls: [
            {
                type: 'element',
                name: 'refer friend',
                value: (
                    <div className="reffer-a-friend">
                        If you have some unique friends and you believe in
                        fairness, invite them to join too. If one of them joins,
                        we’ll extend your <b>free membership to one year</b>
                    </div>
                ),
                hidden: false,
            },
            {
                type: 'text',
                name: 'friend-email-1',
                value: '',
                label: 'Friend Email Address',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: false,
                validationRules: {
                    maxLength: 318,
                    isEmail: true,
                },
            },
            {
                type: 'text',
                name: 'friend-email-2',
                value: '',
                label: 'Friend Email Address',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: false,
                validationRules: {
                    maxLength: 318,
                    isEmail: true,
                },
            },
            {
                type: 'text',
                name: 'friend-email-3',
                value: '',
                label: 'Friend Email Address',
                placeholder: '',
                gridSizeClass: '12 px-0',
                valid: false,
                touched: false,
                hidden: false,
                required: false,
                validationRules: {
                    maxLength: 318,
                    isEmail: true,
                },
            },
        ],
    },
    {
        name: 'Marketing Permission.',
        icon: userCircle,
        controls: [
            {
                type: 'element',
                name: 'join-form',
                value: (
                    <div className="join-form-text">
                        Please select all the ways you would like to hear from
                        Younique:
                    </div>
                ),
                hidden: false,
            },
            {
                type: 'checkbox',
                name: 'marketingEmail',
                label: 'Email',
                gridSizeClass: '12 px-0 pt10',
                valid: true,
                touched: false,
                required: false,
                hidden: false,
                value: false,
            },
            {
                type: 'checkbox',
                name: 'marketingCustomAds',
                label: 'Customized Online Advertising',
                gridSizeClass: '12 px-0 pt10',
                valid: true,
                touched: false,
                required: false,
                hidden: false,
                value: false,
            },
            {
                type: 'element',
                name: 'unsubscribe',
                touched: true,
                valid: true,
                value: (
                    <div className="join-form-text">
                        You can unsubscribe at any time by clicking the link in
                        the footer of our emails. For information about our
                        privacy practices, please visit the{' '}
                        <a href="/" className="linked-text">
                            privacy page
                        </a>
                    </div>
                ),
                hidden: false,
            },
            {
                type: 'captcha',
                name: 'g-recaptcha-response',
                hidden: false,
                value: '',
                required: true,
                touched: false,
                validationRules: {
                    minLength: 1,
                    isRequired: true,
                },
                valid: false,
            },
        ],
    },
];
