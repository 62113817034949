export const items = [
	{
		itemNumber: "01.",
		itemTitle: "Fairness & Sustainability.",
		itemContent: "We are driven by the aim of building a fair and sustainable business to the benefit of the broadest community, including clients, shareholders, employees, partners and suppliers.",
		useImg: false,
		isOdd: true
	},
	{
		itemNumber: "02.",
		itemTitle: "Attitude & Passion.",
		itemContent: "We are positive, collaborative, passionate, self-motivated, transparent, honest and loyal.",
		useImg: false,
		isOdd: false
	},
	{
		itemNumber: "03.",
		itemTitle: "Learning & Quality.",
		itemContent: "We embrace the culture of continuous learning, to do everything we do better than everybody else in our industry.",
		useImg: false,
		isOdd: true
	},
	{
		itemNumber: "04.",
		itemTitle: "We are Doers.",
		itemContent: "We are concrete, driven by facts as well as instinct, and we reward results. We encourage communication, want to have fun, use and continuously build new technology.",
		useImg: false,
		isOdd: false
	},
	{
		itemNumber: "05.",
		itemTitle: "Be Younique.",
		itemContent: "We firmly believe in diversity and open minds, fostering a culture of respect and a solid team spirit.",
		useImg: false,
		isOdd: true
	}
];
