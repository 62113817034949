export const bindRegistrationData = (authData, registration, values) => {
    if (authData && registration) {
        const {
            legalName,
            hotelOrChainName,
            numberOfHotels,
            street,
            postCode,
            country,
            city,
            firstName,
            lastName,
            email,
            roleInCompany,
            registrationType,
        } = registration;

        values.registrationType = registrationType;
        values.legalRepresentative = {
            ...values.legalRepresentative,
            ...{firstName, lastName, roleInCompany},
            representativeEmail: email,
        };
        values.legalEntry = {
            ...values.legalEntry,
            ...{
                hotelOrChainName,
                numberOfHotels,
                street,
                postCode,
                country,
                city,
            },
            numberOfHotelsInChain: numberOfHotels || 0,
            nameOfLegalEntity: legalName,
            hotelName: hotelOrChainName,
        };
    }

    return values;
};

export const bindProfileData = (authData, profile, values) => {
    // console.log(profile);
    if (authData && profile && values) {
        const {
            registrationType,
            legalEntry,
            hotelAddress,
            shippingAddress,
            contactDetails,
            legalRepresentative,
        } = profile;

        const {
            legalRepresentativeEmail,
            legalRepresentativeFirstName,
            legalRepresentativeLastName,
            legalRepresentativeMiddleName,
            legalRepresentativeRoleInCompany,
        } = legalRepresentative;

        values.registrationType = registrationType;
        values.legalRepresentative = {
            firstName: legalRepresentativeFirstName,
            middleName: legalRepresentativeMiddleName,
            lastName: legalRepresentativeLastName,
            representativeEmail: legalRepresentativeEmail,
            roleInCompany: legalRepresentativeRoleInCompany,
        };
        values.legalEntry = {
            ...values.legalEntry,
            ...legalEntry,
        };
        values.sameShippingAddress = true;
        values.sameHotelAddress = true;
        values.shippingAddress = {
            ...values.shippingAddress,
            ...shippingAddress,
        };
        values.hotelAddress = {
            ...values.hotelAddress,
            ...hotelAddress,
        };
        values.contactDetails = {
            ...values.contactDetails,
            ...contactDetails,
        };
    }

    return values;
};

export const preSubmit = formData => {
    if (formData.legalEntry.country.name) {
        formData.legalEntry.country = formData.legalEntry.country.name;
    }
    if (
        formData.hotelAddress &&
        formData.hotelAddress.country &&
        formData.hotelAddress.country.name
    ) {
        formData.hotelAddress.country = formData.hotelAddress.country.name;
    }
    if (
        formData.shippingAddress &&
        formData.shippingAddress.country &&
        formData.shippingAddress.country.name
    ) {
        formData.shippingAddress.country =
            formData.shippingAddress.country.name;
    }
    if (
        formData.legalRepresentative.roleInCompany &&
        formData.legalRepresentative.roleInCompany.name
    ) {
        formData.legalRepresentative.roleInCompany =
            formData.legalRepresentative.roleInCompany.name;
    }
    if (
        formData.legalRepresentative.roleInCompany ===
        'Others (please specify)'
    ) {
        formData.legalRepresentative.roleInCompany =
            formData.legalRepresentative.otherRoleInCompany;
    }

    return formData;
};
