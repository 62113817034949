import * as React from 'react';

function SvgTwitter(props) {
	return (
		<svg width={24} height={24} viewBox="0 0 25 25" fill="none" {...props}>
			<path
				d="M8.276 21.74c8.576 0 13.269-7.112 13.269-13.269 0-.2 0-.4-.01-.6a9.5 9.5 0 002.329-2.42 9.451 9.451 0 01-2.683.737 4.692 4.692 0 002.055-2.583c-.9.537-1.9.919-2.965 1.128A4.655 4.655 0 0016.87 3.26a4.67 4.67 0 00-4.666 4.665c0 .364.046.719.119 1.064C8.448 8.8 5.01 6.934 2.71 4.115a4.664 4.664 0 00-.628 2.346c0 1.619.828 3.047 2.074 3.883a4.716 4.716 0 01-2.11-.582v.064a4.673 4.673 0 003.738 4.575c-.391.109-.8.163-1.228.163-.3 0-.591-.027-.873-.081a4.663 4.663 0 004.356 3.237c-1.6 1.255-3.61 2.001-5.793 2.001-.373 0-.746-.018-1.11-.064a13.266 13.266 0 007.14 2.083z"
				fill="#fff"
			/>
		</svg>
	);
}

export default SvgTwitter;
