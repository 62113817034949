import React from 'react';
import character1 from '../../assets/img/Youn-home-1.svg';
import character2 from '../../assets/img/Youn-home-2.svg';
import character3 from '../../assets/img/Youn-home-3.svg';

const slides = [
    {
        content: (
            <div className="slider-text-lg">
                A world of <span className="text-link">culture </span>awaits
                you.
            </div>
        ),
        image: character1,
    },
    {
        content: (
            <div className="slider-text-lg">
                A world of <span className="text-link">rush </span>awaits you.
            </div>
        ),
        image: character2,
    },
    {
        content: (
            <div className="slider-text-lg">
                A world of <span className="text-link">fun </span>awaits you.
            </div>
        ),
        image: character3,
    },
];

export default slides;
