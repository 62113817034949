export const COPYRIGHT_ALLIANCE_LINKS = [
    {
        key: 'ynq-impressum',
        name: 'Impressum',
        url: '/impressum',
        customClass: 'ynq-footer__copyright--link',
    },
    {
        key: 'ynq-terms',
        name: 'Terms',
        url: '/terms',
        customClass: 'ynq-footer__copyright--link',
    },
    {
        key: 'ynq-privacy',
        name: 'Privacy',
        url: '/privacy',
        customClass: 'ynq-footer__copyright--link',
    },
    {
        key: 'ynq-site',
        name: ' Cookies',
        url: '/cookies',
        customClass: 'ynq-footer__copyright--link',
    },
];

export const COPYRIGHT_CUSTOMER_LINKS = [
    {
        key: 'ynq-impressum',
        name: 'Impressum',
        url: '/impressum',
        customClass: 'ynq-footer__copyright--link',
    },
    {
        key: 'ynq-terms',
        name: 'Terms',
        url: '/terms',
        customClass: 'ynq-footer__copyright--link',
    },
    {
        key: 'ynq-privacy',
        name: 'Privacy',
        url: '/privacy',
        customClass: 'ynq-footer__copyright--link',
    },
    {
        key: 'ynq-site',
        name: ' Cookies',
        url: '/cookies',
        customClass: 'ynq-footer__copyright--link',
    },
];
