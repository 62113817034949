import * as React from 'react';

function SvgFacebook(props) {
    return (
        <svg width={24} height={24} viewBox="0 0 32 32" fill="none" {...props}>
            <path
                d="M16 1.455C7.966 1.455 1.455 7.965 1.455 16c0 7.261 5.318 13.279 12.272 14.37V20.204h-3.693V16h3.693v-3.204c0-3.645 2.17-5.66 5.495-5.66 1.59 0 3.255.284 3.255.284V11h-1.835c-1.807 0-2.37 1.122-2.37 2.273V16h4.035l-.645 4.205h-3.39v10.164C25.228 29.28 30.547 23.261 30.547 16c0-8.034-6.512-14.545-14.546-14.545z"
                fill="#1877F2"
            />
        </svg>
    );
}

export default SvgFacebook;
