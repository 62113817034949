import * as Yup from 'yup';

const profileForm = {
    schema: {
        registrationType: '',
        sameShippingAddress: true,
        sameHotelAddress: true,
        legalEntry: {
            nameOfLegalEntity: '',
            hotelName: '',
            numberOfHotelsInChain: 0,
            totalNumberOfRooms: 0,
            taxId: '',
            tradingName: '',
            street: '',
            city: '',
            postCode: '',
            country: '',
        },
        hotelAddress: {
            street: '',
            city: '',
            postalCode: '',
            country: '',
        },
        shippingAddress: {
            street: '',
            city: '',
            postalCode: '',
            country: '',
        },
        contactDetails: {
            webSiteUrl: '',
            email: '',
            phone: '',
            tollFreeNumber: '',
        },
        legalRepresentative: {
            firstName: '',
            middleName: '',
            lastName: '',
            representativeEmail: '',
            roleInCompany: '',
            otherRoleInCompany: '',
        },
    },
    validationSchema: Yup.object().shape({
        registrationType: Yup.string().required(),
        sameShippingAddress: Yup.bool(),
        sameHotelAddress: Yup.bool(),
        legalEntry: Yup.object({
            nameOfLegalEntity: Yup.string().required(),
            hotelName: Yup.string().required(),
            numberOfHotelsInChain: Yup.number(), //also validated with field validation - validateNumberOfHotels
            totalNumberOfRooms: Yup.number()
                .min(1)
                .required(),
            taxId: Yup.string().required(),
            tradingName: Yup.string(),
            street: Yup.string().required(),
            city: Yup.string()
                .matches(/^([^0-9]*)$/)
                .required(),
            postCode: Yup.string().required(),
            country: Yup.string().required(),
        }),
        hotelAddress: Yup.object({
            street: Yup.string(),
            city: Yup.string().matches(/^([^0-9]*)$/),
            postalCode: Yup.string(),
            country: Yup.string(),
        }),
        shippingAddress: Yup.object({
            street: Yup.string(),
            city: Yup.string().matches(/^([^0-9]*)$/),
            postalCode: Yup.string(),
            country: Yup.string(),
        }),
        contactDetails: Yup.object({
            webSiteUrl: Yup.string()
                // eslint-disable-next-line
                .matches(/(http:\/\/|ftp:\/\/|https:\/\/|www\.)([^\s`~!@#$%^&*()_+={}/\[\]|\\:;“’<,>.?๐฿]+)\.([^\s]{2,6})(\:\d{2,4})?((\?|\/)([^\s`~!@#$%^*()+{}\[\]|\\:;“’<,>.๐฿]+))?$/)
                .required(),
            email: Yup.string()
                .email()
                .required(),
            phone: Yup.string()
                .matches(/^(\+|00)?(\d{1,4})\d{5,15}$/)
                .required(),
            tollFreeNumber: Yup.number(),
        }),
        legalRepresentative: Yup.object({
            firstName: Yup.string().required(),
            middleName: Yup.string(),
            lastName: Yup.string().required(),
            representativeEmail: Yup.string()
                .email()
                .required(),
            roleInCompany: Yup.string().required(),
            otherRoleInCompany: Yup.string(),
        }),
    }),
};

export default profileForm;

export const validateNumberOfHotels = (value, depValue, errors) => {
    if ((value < 2 || value > 100) && depValue === 'CHAIN') {
        Object.assign(errors, {
            legalEntry: {
                numberOfHotelsInChain:
                    'Number of Hotels should be >=2 and <=100',
            },
        });
    }
};

export const validateOtherRoleInCompany = (value, depValue, errors) => {
    if (
        depValue.name === 'Others (please specify)' &&
        (!value || value === '')
    ) {
        Object.assign(errors, {
            legalRepresentative: {
                otherRoleInCompany: 'Please specify role in company!',
            },
        });
    }
};
