import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

const ReactCheckbox = props => {

	const patchedOnChange = (value) => {
		props.onChange({target: {name: props.name, value: value}});
	};

	return (
		<label className="form-checkbox">
			<Checkbox checked={props.checked}
					  onChange={(event, value) => patchedOnChange(value)}/>
			{props.label}
		</label>
	)
};

export default ReactCheckbox;
