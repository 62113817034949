import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { items } from 'components/home/revolution-collection';
import Header from 'components/stateless/header';
import FooterComponent from '../footer/FooterComponent';
import RevolutionItem from 'components/stateless/revolution-item';
import { Icon } from '@iconify/react';
import envelopeCheck from '@iconify/icons-uil/envelope-check';
import { Link, useHistory } from 'react-router-dom';
import gtag from 'utils/gtag';

import { defaultMenu } from 'components/stateless/header-items';
import { FOOTER_ALLIANCE_CATEGORIES } from 'utils/footer-categories';
import 'components/stateless/slider.css';
import 'components/home/Home.css';
import LinkComponent from 'components/link';
import { DocumentLayoutLeft } from 'icons';

class Home extends React.Component {
    constructor(props) {
        super(props);
        gtag.events.pageView('Home');
    }

    read = () => {
        useHistory().push('/manifesto');
    };

    render() {
        return (
            <>
                {/* Header */}
                <Header
                    height={600}
                    page={'home'}
                    bk={'home'}
                    menu={defaultMenu}
                >
                    <div className="row mContainer justify-content-start justify-content-sm-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9 p-0">
                        <div className="impressum-content-container p-0">
                            <div className="impressum-item-content px-2 px-sm-0">
                                <span className="impressum-item-title">
                                    a revolution from the inside
                                </span>
                                <span className="impressum-item-subtitle">
                                    ...for a fair and socially sustainable
                                    business
                                </span>
                            </div>
                            <button
                                className="join-the-revolution-btn mb-sm-0"
                                onClick={() =>
                                    this.props.history.push(
                                        // todo: include after connecting stable API  '/join-the-revolution'
                                        '/contact'
                                    )
                                }
                            >
                                Join the Revolution{' '}
                                <Icon
                                    className="buttonIcon"
                                    icon={envelopeCheck}
                                />
                            </button>
                        </div>
                    </div>
                </Header>

                {/* About the project row */}
                <Row className="pt-4 pt-sm-5 mb-5 justify-content-center about-the-project">
                    <Col
                        className="chapter p-0"
                        xs={10}
                        sm={9}
                        md={10}
                        lg={8}
                        xl={8}
                    >
                        <p>About the Project</p>
                    </Col>
                    <Col
                        className="chapter p-0"
                        xs={10}
                        sm={9}
                        md={10}
                        lg={6}
                        xl={6}
                    >
                        <span>
                            As a professional working in the hotel industry, as
                            a hotelier, you find yourself in quite a challenging
                            era. As the internet came along, some twenty years
                            ago, it was bearing prospect of endless growth and
                            abundance: it was true after all that you could
                            suddenly reach your customers in ways that weren’t
                            <br />
                            …but something went wrong;
                            <Link className="readme" to="/manifesto">
                                read full manifesto
                            </Link>
                        </span>
                    </Col>
                </Row>

                {/* Revolution item rows */}
                {items.map(item => (
                    <RevolutionItem
                        key={item.itemNumber}
                        itemNumber={item.itemNumber}
                        itemTitle={item.itemTitle}
                        isOdd={item.isOdd}
                        imgClass={item.itemImg}
                        useImg={item.useImg}
                        itemContent={item.itemContent}
                    />
                ))}

                <div className="mt-5 pt-3 pt-sm-5 pb-sm-5 justify-content-center">
                    <div className="text-sm-center conclusion-text">
                        <span>
                            Because it is, and if you’ve read this far now you
                            know too.
                        </span>
                    </div>
                </div>

                {/* Manifesto paper row */}
                <Row className="pt-5 justify-content-center">
                    <div className="row cContainer justify-content-center justify-content-md-between col-12 col-md-11 col-lg-10 col-xl-9">
                        <div className="paper-content-container col-11 col-sm-9 col-md-6 col-lg-6 col-xl-6 order-md-first order-last mb-sm-0">
                            <div className="paper-item-content">
                                <span className="paper-item-title">
                                    Manifesto
                                </span>
                                <span className="paper-item-text">
                                    Fast forward today, and you find yourself in
                                    a corner squeezed between impossible
                                    commissions, eroding your margins to the
                                    point where you can’t even do well what you
                                    do best
                                </span>
                            </div>
                        </div>
                        <div
                            onClick={() =>
                                this.props.history.push('/manifesto')
                            }
                            style={{
                                cursor: 'pointer',
                            }}
                            className="paper-item-img img-8 col-11 col-sm-9 col-md-6 col-lg-5 col-xl-6 order-md-last order-first"
                        />
                    </div>
                </Row>

                {/* Join the revolution row */}
                <Row className="pt-5 pb-5 pb-md-0 justify-content-center">
                    <div className="col-11 col-md-11 col-lg-10 col-xl-9 d-flex justify-content-center">
                        {/* <LinkComponent
                            to={'/join-the-revolution'}
                            className="ynq-link--reverse hotelier-register-btn"
                        > */}
                        <LinkComponent
                            to={'/contact'}
                            className="ynq-link--reverse hotelier-register-btn"
                        >
                            <DocumentLayoutLeft />
                            Hoteliers register here to learn more
                        </LinkComponent>
                    </div>
                </Row>
                {/* Footer */}
                <FooterComponent
                    target="alliance"
                    menu={FOOTER_ALLIANCE_CATEGORIES}
                />
            </>
        );
    }
}

export default Home;
