import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {CheckMark, RadioChecked, RadioDisabled} from 'icons';
import {INPUT_TYPES} from 'utils/types';
import './SelectionControl.scss';

const {checkbox, radio} = INPUT_TYPES;

const SelectionControlComponent = ({
                                       id,
                                       label,
                                       name,
                                       value,
                                       disabled,
                                       formId,
                                       type,
                                       checked,
                                       required,
                                       onSelect,
                                       onChange,
                                       index,
                                       labelClassName,
                                       valid,
                                       touched,
                                   }) => {
    const [isCheck, onCheck] = useState(value);

    const pseudoInput = classNames(
        'ynq-selection-control__input',
        `ynq-selection-control__${type}`,
        {
            'ynq-selection-control__input--is-checked': isCheck,
            'ynq-selection-control__input--is-disabled': disabled,
            'ynq-selection-control__input--is-invalid': touched && !valid,
        }
    );

    const labelClass = classNames({
        'ynq-selection-control__label--is-invalid': touched && !valid,
    });

    useEffect(() => {
        onCheck(checked);
    }, [checked]);

    const handleChange = e => {
        onCheck(e.target.checked);
        onChange({
            target: {
                name: e.target.name,
                value: type === checkbox ? e.target.checked : e.target.value,
            },
        });
        onSelect(index);
    };

    return (
        <div className="ynq-selection-control">
            <label htmlFor={id}>
                <span
                    className={`ynq-selection-control__label ${labelClassName} ${labelClass}`}
                >
                    {label}
                </span>
                <span className={pseudoInput}>
                    {type === checkbox && <CheckMark hidden={!isCheck}/>}
                    {type === radio && isCheck && !disabled && <RadioChecked/>}
                    {type === radio && isCheck && disabled && <RadioDisabled/>}
                </span>
            </label>
            <input
                hidden
                form={formId}
                id={id}
                value={value}
                name={name}
                checked={isCheck}
                disabled={disabled}
                type={type}
                onChange={handleChange}
                required={required}
            />
        </div>
    );
};

SelectionControlComponent.defaultProps = {
    onChange: () => {
    },
    onSelect: () => {
    },
    checked: false,
};

SelectionControlComponent.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    formId: PropTypes.string,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    required: PropTypes.bool,
    index: PropTypes.number,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
    type: PropTypes.oneOf([checkbox, radio]).isRequired,
    labelClassName: PropTypes.string,
    valid: PropTypes.string,
    touched: PropTypes.string,
};

export default SelectionControlComponent;
