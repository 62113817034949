import React from "react";
import './drag-and-drop.css';
import {Icon} from '@iconify/react';
import fileAlt from '@iconify/icons-uil/file-alt';
import Dropzone from "react-dropzone";

class DragAndDrop extends React.Component {

	render() {
		return (
			<Dropzone onDrop={this.props.onDrop}
					  accept={this.props.accept}
					  minSize={this.props.minSize}
					  maxSize={this.props.maxSize}>
				{({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles}) => {
					const isFileTypeRejected = isDragReject;
					const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > this.props.maxSize;
					return (
						<div className="dropArea" {...getRootProps()}>
							<Icon className="dnd-icon" icon={fileAlt}/>
							<div className="dnd-info">
								<input {...getInputProps()} />
								{!isDragActive || isDragActive ? <span className="label d-none d-sm-block">Drag & Drop</span> : null}
								{!isDragActive || isDragActive ? <span className="label d-block d-sm-none">click here to upload</span> : null}
								<p className="description">Resume/CV</p>
								<p className="warning-msg">
									<span>Please note that the limit for attachments is 2MB.</span>
									<span>Accepted File types are PDF and DOC.</span>
								</p>
								{isFileTypeRejected ? <span className="label danger">Only .pdf and .doc file type allowed!<br/></span> : null}
								{isFileTooLarge ? <span className="label danger">File is too large! A file of maximum 2 MB allowed<br/></span> : null}
								{(!isFileTypeRejected && !isFileTooLarge) ? this.props.file : null}
							</div>
						</div>
				)}}
			</Dropzone>
		)
	}
}

export default DragAndDrop;
