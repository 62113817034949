import {Link} from 'react-router-dom';
import React from 'react';

const faqCategories = [
    {
        name: 'Membership FAQs.',
        items: [
            {
                name: 'What is Younique?',
                content: (
                    <span>
                        Younique is an alliance between hoteliers to regain
                        control of the hotel distribution and to reduce the
                        exorbitant cost related to it. It is for hotel owners
                        and operators who are tired of the status quo. Who feel
                        the pain and want to change things, which only a strong
                        alliance can achieve.
                        <br/>
                        Technically, Younique is a joint-stock company,
                        incorporated in Berlin and under German law. Only Hotel
                        owners and operators can invest in the company, to avoid
                        investment companies, funds or even competitors, driven
                        by the aim of profits can become shareholders.
                        <br/>
                        The company is not driven by profit goals, but simply by
                        the objectives to reduce search and distribution costs
                        significantly and to increase guest experience and
                        satisfaction, by providing a more personalized and
                        younique experience.
                    </span>
                ),
            },
            {
                name: 'What does the name Younique stay for?',
                content: (
                    <span>
                        You + unique = Younique. The name should reflect the
                        ambition of Younique to bring the focus back on the
                        individual hotel guest and their experience, based on
                        their unique preferences.
                        <br/>
                        We’ll ask the guest to share its preferences with us,
                        and different to other OTA’s we’ll share this
                        information with the hotels, to enable them to deliver a
                        more personalized and tailored service.
                        <br/>
                        These can be all kind of information, related to the
                        room, the services offered, the communication channels
                        and every other aspect of a stay.
                        <br/>
                        The aim is to improve pre-stay, stay and post-stay
                        experience significantly.
                    </span>
                ),
            },
            {
                name: 'What is Younique ultimately doing?',
                content: (
                    <span>
                        Younique is a hybrid of a hotel search engine and an
                        OTA, focused on hotel rooms. Ultimately, we want to
                        assist a guest in searching and booking a hotel room,
                        with a strong focus on his preferences. The guest will
                        have the choice to book directly on Younique or on the
                        website of the hotel/brand.
                    </span>
                ),
            },
            {
                name: 'How can I become a member of the Younique alliance? ',
                content: (
                    <span>
                        The participation in Younique is pretty simple. Just
                        subscribe and become a member, and shareholder of the
                        company.
                        <br/>
                        Subscribe{' '}
                        <Link to="/join-the-revolution" className="text-linked">
                            here
                        </Link>
                        .
                    </span>
                ),
            },
            {
                name:
                    'How do I register and maintain more than one hotel under the same profile?',
                content: (
                    <span>
                        Please select if you want to register a single hotel or
                        a chain. If you select the chain option, please enter
                        the number of hotels you wish to register under your
                        profile.
                        <br/>
                        If you register 1 hotel, please enter the address of the
                        hotel.
                        <br/>
                        If you register more than 1 hotel, please enter the
                        address of the legal entity.
                        <br/>
                        The configuration of the individual hotels will be done
                        on the Extranet. Access to the Extranet will be
                        available as soon as the registration process is
                        completed.
                        <br/>
                        All details including the address data of each hotel
                        will be stored there and maintained under the same
                        profile.
                    </span>
                ),
            },
            {
                name:
                    'How much does it cost to become a member of the alliance?',
                content: (
                    <span>
                        It’s very simple and the cost is relatively low. The
                        exact amount though depends on the moment of Investment.
                        The reference is 30 Euro per room.
                        <br/>
                        This can be a bit lower or a bit higher, depending on
                        the moment of subscription. The earlier you join the
                        alliance the cheaper it will be.
                        <br/>
                        The facto, you are buying shares in the company and
                        become a shareholder. For a precise estimate, please
                        contact us directly.
                    </span>
                ),
            },
            {
                name:
                    'Can a hotel sell rooms on Younique, but not become a member of the alliance?',
                content: (
                    <span>
                        Yes, you can, but you would lose all the benefits of the
                        alliance. You would pay similar commissions as on any
                        other OTA portals, which are multiple times higher.
                    </span>
                ),
            },
            {
                name: 'How much commissions will I pay for a booking? ',
                content: (
                    <span>
                        Since the alliance has no aims to make profits, the
                        commissions will be as low as possible and can change
                        over time, as decided by the shareholders. The objective
                        is to be around 3% on average on a booking. This can
                        vary depending on the type of booking or where the
                        payment will take place. Just contact us for the most
                        recent commission structure.
                    </span>
                ),
            },
            {
                name:
                    'How do you invest the money I pay to become a member of the alliance?',
                content: (
                    <span>
                        All investments from shareholder will primarily be used
                        to develop a leading-edge platform and to market the
                        portal.
                    </span>
                ),
            },
        ],
    },
    {
        name: 'The Platform & Services.',
        items: [
            {
                name:
                    'What technology are you using and which features will be available?',
                description: '',
                content: (
                    <>
                        <span>
                            We are using a serious of leading-edge technology
                            components, which vary for front end, back end and
                            mobile applications. All components of the platform
                            have been chosen, based on the following guiding
                            principles:
                        </span>
                        <ul>
                            <li>Cloud and Tech Stack Independence</li>
                            <li>Performance</li>
                            <li>Scalability</li>
                            <li>Security</li>
                            <li>Compliance</li>
                            <li>Flexibility</li>
                            <li>Continuity</li>
                            <li>Open Architecture</li>
                            <li>Integration enabled</li>
                            <li>Modern State of the Art</li>
                        </ul>
                    </>
                ),
            },
            {
                name: 'Will Younique also offer a mobile app? ',
                content: (
                    <span>
                        Yes, of course. There will be also a mobile app, which
                        is a core component to improve the guest experience and
                        to differentiate from competition. It might be delivered
                        with some delay compared to the first release of the
                        Desktop and mobile enabled version of the platform.
                    </span>
                ),
            },
            {
                name: 'How do you treat Rate Parity?',
                content: (
                    <span>
                        Rate parity across all distribution channels is one of
                        the aims of the hotel industry and therefore intrinsic
                        to the strategy of Younique. Nevertheless, there might
                        be exception in certain circumstances, but always in
                        agreement with the member hotel.
                    </span>
                ),
            },
            {
                name: 'How about Loyalty?',
                content: (
                    <span>
                        Loyalty is a complex subject and can be answered in two
                        different scenarios.
                        <br/>
                        a) If your brand offers a loyalty program to your guests
                        and the guest has a loyalty program identifier in his
                        profile, your hotel will be enabled to show also loyalty
                        rates on Younique. This will make your hotel more
                        competitive and being the commissions for loyalty very
                        low, they will not have an impact compared to a direct
                        transaction. <br/>
                        b) In the second scenario, Younique will offer its own
                        loyalty program, which the consumer can earn or pay for,
                        and get in exchange better pricing and/or additional
                        services included in the price, from participating
                        hotels. Just for explanation purposes of the logic, you
                        could use “Amazon Prime” as a reference.
                    </span>
                ),
            },
            {
                name: 'Can I sell also corporate rates on Younique? ',
                content: (
                    <span>
                        We indeed plan for this functionality, but it will most
                        likely not be available at the launch of the product but
                        will come with a subsequent release.
                    </span>
                ),
            },
            {
                name: 'Can a guest see rate comparison on Younique? ',
                content: (
                    <span>
                        Rate comparison is a fundamental element of the decision
                        process of a guest. Therefore, we’ll offer this feature,
                        but in a different way from Metasearch engines or OTA’s.
                        The aim is to check rates from other leading OTA’s and
                        the hotel, in real time on request of the guest. We will
                        not search for the lowest rate on the Internet. We´ll
                        display those rates for comparison purposes, but not
                        link the user to those websites. The user will only have
                        the option to book on Younique or the hotel website.
                    </span>
                ),
            },
            {
                name:
                    'How can I update my availability and rates in Younique? ',
                content: (
                    <span>
                        Providing real time information is essential in this
                        market space. To provide real time data we are
                        developing integrations with different technology
                        providers, which can be Channel Managers, Property
                        Management System, Central Reservation Systems or
                        inventory aggregators. There will be also the option to
                        update rates and availability manually, via the
                        Extranet, for those hotels, which do not have an
                        integrated system. The manual process might not be
                        available at the launch of the platform, but
                        subsequently only.
                    </span>
                ),
            },
            {
                name:
                    'Which information does Younique share with the hotel, if it gets booked? ',
                content: (
                    <span>
                        Sharing information is the foundation and one of the
                        strategic objectives of Younique. Which information will
                        be shared is driven and controlled by the guest, however
                        to make sure the hotel is ultimately enabled to provide
                        a better guest experience, Younique will share ALL guest
                        information and preferences available, which the guest
                        has approved for sharing.
                    </span>
                ),
            },
            {
                name: 'How about user generated content like on TripAdvisor? ',
                content: (
                    <span>
                        First of all, we´ll provide guest reviews and to provide
                        feedback on the experience. We are further planning to
                        allow guests to share its own content, like images and
                        experiences. This feature might be available already at
                        the launch of the product or subsequently.
                    </span>
                ),
            },
        ],
    },
];

export default faqCategories;
