import React from 'react';
import PropTypes from 'prop-types';
import InputComponent from 'components/form/input';
import SelectComponent from 'components/form/select';
import classNames from 'classnames';
import {validateOtherRoleInCompany} from 'components/subscription/company-profile/profile-form';

const CommonLegalRepresentative = ({
                                       values,
                                       errors,
                                       touched,
                                       handleChange,
                                       handleBlur,
                                       roles,
                                       isDisabled,
                                   }) => {
    return (
        <>
            <div className="ynq-subscription-profile__row">
                <InputComponent
                    id="legalRepresentative.firstName"
                    name="legalRepresentative.firstName"
                    type="text"
                    label="FIRST NAME"
                    labelClassName="ynq-subscription-profile__label"
                    value={values.legalRepresentative.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="-"
                    isRequired
                    disabled={isDisabled}
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.legalRepresentative?.firstName &&
                            touched.legalRepresentative?.firstName,
                    })}
                />
            </div>
            <div className="ynq-subscription-profile__row">
                <InputComponent
                    id="legalRepresentative.middleName"
                    name="legalRepresentative.middleName"
                    type="text"
                    label="Middle NAME"
                    labelClassName="ynq-subscription-profile__label"
                    value={values.legalRepresentative.middleName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="-"
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.legalRepresentative?.middleName &&
                            touched.legalRepresentative?.middleName,
                    })}
                />
            </div>
            <div className="ynq-subscription-profile__row">
                <InputComponent
                    id="legalRepresentative.lastName"
                    name="legalRepresentative.lastName"
                    type="text"
                    label="LAST NAME"
                    labelClassName="ynq-subscription-profile__label"
                    value={values.legalRepresentative.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="-"
                    isRequired
                    disabled={isDisabled}
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.legalRepresentative?.lastName &&
                            touched.legalRepresentative?.lastName,
                    })}
                />
            </div>
            <div className="ynq-subscription-profile__row">
                <InputComponent
                    id="legalRepresentative.representativeEmail"
                    name="legalRepresentative.representativeEmail"
                    type="text"
                    label="Email address"
                    labelClassName="ynq-subscription-profile__label"
                    value={values.legalRepresentative.representativeEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="-"
                    isRequired
                    disabled={isDisabled}
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.legalRepresentative?.representativeEmail &&
                            touched.legalRepresentative?.representativeEmail,
                    })}
                />
            </div>
            <div className="ynq-subscription-profile__row">
                <SelectComponent
                    id="legalRepresentative.roleInCompany"
                    name="legalRepresentative.roleInCompany"
                    type="text"
                    label="Role in the company"
                    labelClassName="ynq-subscription-profile__label"
                    options={roles}
                    value={values.legalRepresentative.roleInCompany}
                    optionLabel="name"
                    optionValue="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isMulti={false}
                    placeholder="-"
                    isRequired
                    isDisabled={isDisabled}
                    maxLength={255}
                    className={classNames({
                        'ynq-input--error':
                            errors.legalRepresentative?.roleInCompany &&
                            touched.legalRepresentative?.roleInCompany,
                    })}
                />
            </div>
            {values.legalRepresentative.roleInCompany?.name ===
            'Others (please specify)' && (
                <div className="ynq-subscription-profile__row">
                    <InputComponent
                        id="legalRepresentative.otherRoleInCompany"
                        name="legalRepresentative.otherRoleInCompany"
                        type="text"
                        label="Your Role in the Company"
                        labelClassName="ynq-subscription-profile__label"
                        value={values.legalRepresentative.otherRoleInCompany}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="-"
                        isRequired={
                            values.legalRepresentative.roleInCompany?.name ===
                            'Others (please specify)'
                        }
                        disabled={isDisabled}
                        validate={validateOtherRoleInCompany(
                            values.legalRepresentative.otherRoleInCompany,
                            values.legalRepresentative.roleInCompany,
                            errors
                        )}
                        maxLength={255}
                        className={classNames({
                            'ynq-input--error':
                                errors.legalRepresentative
                                    ?.otherRoleInCompany &&
                                touched.legalRepresentative?.otherRoleInCompany,
                        })}
                    />
                </div>
            )}
        </>
    );
};

CommonLegalRepresentative.propTypes = {
    form: PropTypes.object,
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    roles: PropTypes.array,
    isDisabled: PropTypes.bool,
};

export default CommonLegalRepresentative;
