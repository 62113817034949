import * as React from 'react';

function SvgCheckMark(props) {
	return (
		<svg width={24} height={24} viewBox="0 0 16 12" fill="none" {...props}>
			<path
				d="M14.71 1.21a1 1 0 00-1.42 0L5.84 8.67 2.71 5.53A1.022 1.022 0 101.29 7l3.84 3.84a1 1 0 001.42 0l8.16-8.16a1 1 0 000-1.47z"
				fill="#fff"
			/>
		</svg>
	);
}

export default SvgCheckMark;
