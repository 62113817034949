import * as React from 'react';

function SvgMapMarker(props) {
	return (
		<svg width={24} height={24} viewBox="0 -1 18 22" fill="none" {...props}>
			<path
				d="M15 3.48a8.485 8.485 0 00-12 12l5.27 5.28a1 1 0 001.42 0L15 15.43a8.45 8.45 0 000-11.95zM13.57 14L9 18.59 4.43 14a6.46 6.46 0 119.14 0zM6 6.41a4.32 4.32 0 000 6.1 4.311 4.311 0 007.36-3 4.24 4.24 0 00-1.26-3.05A4.3 4.3 0 006 6.41zm4.69 4.68a2.33 2.33 0 11.67-1.63 2.33 2.33 0 01-.72 1.63h.05z"
				fill="#8487A6"
			/>
		</svg>
	);
}

export default SvgMapMarker;
