import './App.css';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import RegistrationForm from './components/registration/RegistrationForm';
import Home from './components/home/Home';
import Alliance from './components/alliance/Alliance';
import Manifesto from './components/manifesto/Manifesto';
import Careers from './components/careers/Careers';
import JobDetails from './components/job-details/JobDetails';
import JobApply from './components/job-apply/JobApply';
import ContactUs from './components/contact-us/Contact-Us';
import Impressum from './components/impressum/Impressum';
import ManifestoB2B from './components/manifesto-b2b/ManifestoB2B';
import Faq from './components/faq-company/Faq';
import Cookies from './components/stateless/cookies';
import ScrollToTop from 'react-router-scroll-top';
import GetInTheLoop from './components/get-in-the-loop/get-in-the-loop';
import PrivacyComponent from './components/privacy';
import Login from 'components/login/Login';
import Error from 'components/error/Error';
import ResetPassword from 'components/reset-pasword/ResetPassword';
// import AuthContext from './context/auth-context';
import PrivateRoute from 'utils/private-route';
import SubscriptionComponent from 'components/subscription';
import PasswordSetupComponent from 'components/password-setup/PasswordSetupComponent';
import WelcomeComponent from 'components/welcome/WelcomeComponent';
import ConcreteOptionComponent from 'components/welcome/options/ConcreteOptionComponent';
import TryOptionComponent from 'components/welcome/options/TryOptionComponent';
import LiteOptionComponent from 'components/welcome/options/LiteOptionComponent';
import LandingComponent from 'components/landing';
import TermsComponent from 'components/terms';
// import Callback from 'components/login/callback';
import ForgotPasswordComponent from 'components/password-forgot';
import SetForgottenPasswordComponent from 'components/password-forgot/SetForgottenPasswordComponent';
// import {AuthProvider, AuthService} from 'react-oauth2-pkce';

// const authService = new AuthService({
//     clientId: process.env.REACT_APP_CLIENT_ID,
//     location: window.location,
//     provider: process.env.REACT_APP_PROVIDER,
//     redirectUri: process.env.REACT_APP_REDIRECT_URI,
//     scopes: ['openid', 'profile'],
// });

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                {/* <AuthContext> */}
                {/*<AuthProvider authService={authService}>*/}
                <ScrollToTop>
                    <main>
                        <Switch>
                            <Route exact path="/" component={Alliance} />
                            {/* <Route path="/callback" component={Callback} /> */}
                            <Route path="/home" component={Alliance} />
                            <PrivateRoute
                                path="/4-steps-to-go"
                                component={LandingComponent}
                            />
                            <Route path="/alliance" component={Home} />
                            <Route
                                path="/join-the-revolution"
                                component={RegistrationForm}
                            />
                            <Route
                                path="/get-in-the-loop"
                                component={GetInTheLoop}
                            />
                            <Route path="/manifesto" component={Manifesto} />
                            <Route
                                path="/manifesto-b2b"
                                component={ManifestoB2B}
                            />
                            <Route
                                path="/login/reset"
                                component={ResetPassword}
                            />
                            <Route
                                path="/login/forgot"
                                component={ForgotPasswordComponent}
                            />
                            <Route
                                path="/login/set-forgotten"
                                component={SetForgottenPasswordComponent}
                            />
                            <Route path="/login/error" component={Error} />
                            <Route path="/login" component={Login} />
                            <Route
                                path="/register/password"
                                component={PasswordSetupComponent}
                            />

                            <Route path="/contact" component={ContactUs} />
                            <Route exact path="/careers" component={Careers} />
                            <Route
                                exact
                                path="/careers/:id"
                                component={JobDetails}
                            />
                            <Route
                                exact
                                path="/careers/:id/:apply"
                                component={JobApply}
                            />
                            <Route path="/impressum" component={Impressum} />
                            <Route path="/cookies" component={Cookies} />
                            <Route
                                path="/privacy"
                                component={PrivacyComponent}
                            />
                            <Route path="/terms" component={TermsComponent} />
                            <PrivateRoute
                                path="/subscription"
                                component={SubscriptionComponent}
                            />
                            <Route path="/faq" component={Faq} />
                            <PrivateRoute
                                exact
                                path="/welcome"
                                component={WelcomeComponent}
                            />
                            <PrivateRoute
                                exact
                                path="/welcome/concrete"
                                component={ConcreteOptionComponent}
                            />
                            <PrivateRoute
                                exact
                                path="/welcome/try"
                                component={TryOptionComponent}
                            />
                            <PrivateRoute
                                exact
                                path="/welcome/lite"
                                component={LiteOptionComponent}
                            />
                        </Switch>
                    </main>
                </ScrollToTop>
                {/*</AuthProvider>*/}
                {/* </AuthContext> */}
            </BrowserRouter>
        </div>
    );
}

export default App;
