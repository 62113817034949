import * as Yup from 'yup';

export const forgotPasswordForm = {
    schema: {
        email: '',
    },
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .email()
            .required(),
    }),
};

export const setForgottenPassword = {
    schema: {
        token: '',
        password: '',
        newPassword: '',
    },
    validationSchema: Yup.object().shape({
        password: Yup.string().required(),
        newPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required(),
    }),
};
