import React from 'react';
import {applyForm} from 'components/job-apply/job-apply-form';
import {crumbs} from 'components/job-apply/crumbs';
import Validate from 'utils/validator';
import Header from '../stateless/header';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControl from 'utils/form-control';
import {Icon} from '@iconify/react';
import fileCheckAlt from '@iconify/icons-uil/file-check-alt';
import {withRouter} from 'react-router';
import gtag from 'utils/gtag';
import Spinner from 'react-bootstrap/Spinner';
import AlertComponent from 'utils/alert/AlertComponent';
import {CheckFilled, TimesCircleFilled} from 'icons';
import FooterComponent from '../footer/FooterComponent';

import {defaultMenu} from 'components/stateless/header-items';
import {FOOTER_ALLIANCE_CATEGORIES} from 'utils/footer-categories';
import 'components/job-apply/JobApply.css';

class JobApply extends React.Component {
    constructor(props) {
        super(props);
        this.env = process.env.REACT_APP_NODE_API;
        this.formDefinition = [];
        this.getFormDefinition();
        this.captchaRef = React.createRef();
        this.state = {
            asyncInProgress: false,
            jobPosition: null,
            formIsValid: false,
            formControls: this.formDefinition,
            formEvent: { text: '', type: '', icon: null },
            breadCrumbs: null,
            files: null,
            file: null,
        };
        gtag.events.pageView('JobApply');
    }

    resetForm = () => {
        this.captchaRef.current.reset();
        applyForm.map(item => {
            item.value = '';
            item.touched = false;
            item.valid = false;
            return item;
        });
    };

    componentDidMount() {
        const id = this.props.match.params['id'];
        const route = crumbs.find(item => item.route === id);
        this.setState({ breadCrumbs: route.values, jobPosition: id });
    }

    getFormDefinition = () => {
        applyForm.forEach(item => this.formDefinition.push(item));
        return this.formDefinition;
    };

    changeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;

        const updatedFormElement = this.state.formControls.find(
            item => item.name === name
        );
        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = Validate(
            value,
            updatedFormElement.validationRules
        );

        this.state.formControls
            .filter(item => item.name === name)
            .map(() => updatedFormElement);

        let formIsValid = true;
        this.state.formControls.forEach(control => {
            formIsValid =
                control.valid && formIsValid && this.state.files !== null;
        });

        this.setState({
            formControls: this.state.formControls,
            formIsValid: formIsValid,
        });
    };

    formSubmitHandler = e => {
        if (e) e.preventDefault();
        const formData = {};
        let formIsValid = true;

        this.state.formControls.forEach(control => {
            control.touched = true;
            control.valid = Validate(control.value, control.validationRules);
            formData[control.name] = control.value;
            formIsValid = control.valid && formIsValid;
        });
        this.setState({
            formControls: this.state.formControls,
            formIsValid: formIsValid,
        });

        if (formIsValid) {
            const form = this.bindFormData(formData);
            this.postForm(form);
        }
    };

    bindFormData = formData => {
		formData.email = formData.email.toLowerCase();
        return Object.assign(formData, { position: this.state.jobPosition });
    };

    postForm = formData => {
        const file = new File([JSON.stringify(formData)], 'foo.json', {
            type: 'application/json',
        });
        const data = new FormData();
        data.append('form', file);
        data.append('cv', this.state.files);
        const options = {
            method: 'POST',
            body: data,
            headers: {
                'g-recaptcha-response': formData['g-recaptcha-response'],
            },
        };

        this.setState({ asyncInProgress: true });

        fetch(`${this.env}/api/v1/contact/career/form`, options)
            .then(response => Promise.all([response.ok, response.json()]))
            .then(([ok, json]) => {
                if (ok) {
                    this.resetForm();
                    this.setState({
                        asyncInProgress: false,
                        formEvent: {
                            text: 'Application data sent successfully!',
                            type: 'ynq-alert--success mt-4',
                            icon: <CheckFilled />
                        },
                        formIsValid: false,
                        files: null,
                        file: null,
                        formControls: this.getFormDefinition(),
                    });
                } else {
                    this.setState({ asyncInProgress: false });
                    throw new Error(json.errors[0].message);
                }
            })
            .catch(err => {
                this.captchaRef.current.reset();
                return this.setState({
                    asyncInProgress: false,
                    formEvent: {
                        text: err.message,
                        type: 'ynq-alert--error mt-4',
                        icon: <TimesCircleFilled />
                    },
                });
            });
    };

    onDrop = (accepted, rejected) => {
        if (Object.keys(rejected).length === 0) {
            this.setState({ files: accepted[0], file: accepted[0].name });
        }
    };

    dismiss = () => {
        this.setState({
            formEvent: { text: '', type: '', icon: null },
        });
    };

    render() {
        return (
            <>
                {/* Header */}
                <Header
                    title={`Tell us who you are.`}
                    titlePosition={'start'}
                    height={600}
                    page={'home'}
                    bk={'apply'}
                    menu={defaultMenu}
                    crumbs={
                        this.state.breadCrumbs ? this.state.breadCrumbs : null
                    }
                />

                {/* Contact form row */}
                <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                    <Col className="apply-container p-0 p-lg-0 d-flex d-md-flex justify-content-center justify-content-md-between">
                        <Col className="apply-chapter pb-1 p-lg-0 d-none d-md-block col-xl-2 col-lg-2 col-md-2 col-sm-2 col-10">
                            <div>Application form.</div>
                        </Col>
                        <Col className="apply-text p-0 p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                            <div className="item-content col-sm-10">
                                <span className="item-title">
                                    Personal info.
                                </span>
                                <span className="item-text pr-0">
                                    Fill the form below to apply for a position.
                                </span>
                            </div>
                            <div className="justify-content-end d-none d-md-flex">
                                <span className="required-sign">
                                    <span className="r-asterisk">*</span>{' '}
                                    Required
                                </span>
                            </div>
                            <form>
                                <FormControl
                                    rf={this.captchaRef}
                                    controls={applyForm}
                                    onDrop={this.onDrop}
                                    file={this.state.file}
                                    onClick={this.changeHandler}
                                />
                            </form>
                        </Col>
                    </Col>
                </Row>
                <Row className="px-2 justify-content-center px-sm-5">
                    <Col className="apply-container p-0 p-lg-0 d-flex d-md-flex justify-content-center justify-content-md-between">
                        <Col className="apply-chapter pb-1 p-lg-0 d-none d-md-block col-xl-2 col-lg-2 col-md-2 col-sm-2 col-10" />
                        <Col className="apply-text p-0 p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                            <div>
                                {this.state.formEvent &&
                                this.state.formEvent.text ? (
                                    <AlertComponent
                                        className={this.state.formEvent.type}
                                        btnClassName={this.state.formEvent.btn}
                                        icon={this.state.formEvent.icon}
                                        text={this.state.formEvent.text}
                                        onAction={this.dismiss}
                                    />
                                ) : null}
                            </div>
                            <button
                                className="apply-role-btn mb-3"
                                onClick={this.formSubmitHandler}
                            >
                                {this.state.asyncInProgress ? (
                                    <Spinner
                                        animation="border"
                                        variant="secondary"
                                    />
                                ) : (
                                    <span>
                                        Apply for this role
                                        <Icon
                                            className="buttonIcon"
                                            icon={fileCheckAlt}
                                        />
                                    </span>
                                )}
                            </button>
                        </Col>
                    </Col>
                </Row>

                {/* Footer */}
                <FooterComponent target="alliance" menu={FOOTER_ALLIANCE_CATEGORIES}/>
            </>
        );
    }
}

export default withRouter(JobApply);
