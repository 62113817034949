import * as React from 'react';

function SvgSelectArrow(props) {
	return (
		<svg width={8} height={6} fill="none" {...props}>
			<path
				d="M.561 1.816l2.87 3.348a.75.75 0 001.139 0l2.869-3.348a.75.75 0 00-.57-1.238H1.132a.75.75 0 00-.57 1.238z"
				fill="#504F66"
			/>
		</svg>
	);
}

export default SvgSelectArrow;
