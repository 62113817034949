import React, {useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import Header from 'components/stateless/header';
import {defaultMenu} from 'components/stateless/header-items';
import {useFormik} from 'formik';
import loginForm from 'components/login/login-form';
import classNames from 'classnames';
import InputComponent from 'components/form/input';
import ButtonComponent from 'components/button';
import LinkComponent from 'components/link';
import './Login.css';

export default function Login() {
    let history = useHistory();
    let location = useLocation();
    const query = new URLSearchParams(location.search);

    useEffect(() => {
        const error = query.get('authFailureMsg');

        if (error) {
            history.push('/login/error');
        }
    });

    const env = process.env.REACT_APP_COMMON_AUTH_URL;
    const {handleChange, handleBlur, values, errors, touched} = useFormik({
        initialValues: loginForm.schema,
        validationSchema: loginForm.validationSchema,
        onSubmit: () => {
        },
    });

    return (
        <div className="ynq-password-setup__page">
            {/* Header */}
            <Header bk="login" menu={defaultMenu}/>

            <div className="ynq-password-setup__container">
                <form
                    id="signinForm"
                    className="ynq-password-setup__form"
                    method="POST"
                    action={env}
                >
                    <div className="ynq-password-setup__title">Sign in</div>
                    <div className="ynq-password-setup__subtitle">
                        Please sign in using your Younique account to access our
                        services.
                    </div>

                    <div className="ynq-password-setup__row">
                        <InputComponent
                            id="email"
                            name="email"
                            type="text"
                            label="Email Address"
                            labelClassName="ynq-subscription-profile__label"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            className={classNames({
                                'ynq-input--error':
                                    errors.email && touched.email,
                            })}
                        />
                    </div>
                    <div className="ynq-password-setup__row">
                        <InputComponent
                            id="password"
                            name="password"
                            type="password"
                            label="Password"
                            labelClassName="ynq-subscription-profile__label"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="-"
                            className={classNames({
                                'ynq-input--error':
                                    errors.password && touched.password,
                            })}
                        />
                    </div>

                    <div className="ynq-password-setup__actions">
                        <input
                            id="username"
                            name="username"
                            type="hidden"
                            value={values.email + '@younique.org'}
                        />
                        <input
                            id="sessionDataKey"
                            name="sessionDataKey"
                            type="hidden"
                            value={query.get('sessionDataKey')}
                        />
                        <ButtonComponent
                            type="submit"
                            name="submitForm"
                            className="ynq-button--primary"
                        >
                            Log in
                        </ButtonComponent>
                        <div className="ynq-password-setup__link">
                            <LinkComponent to="/login/forgot">
                                Forgot Password?
                            </LinkComponent>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
