import React from 'react';
import PropTypes from 'prop-types';
import './Alert.scss';

const AlertComponent = ({
                            text,
                            icon,
                            className,
                            btnClassName,
                            onAction,
                            btnDisplay
                        }) => {
    return (
        <div className={`ynq-alert ${className}`}>
            <div className="ynq-alert__content">
                <div className="ynq-alert__shape">{icon}</div>
                <div className="ynq-alert__message">{text}</div>
                {btnDisplay === true && (
                    <button
                        className="ynq-alert__action-button"
                        onClick={onAction}
                    >
                        Close
                    </button>
                )}
            </div>
        </div>
    );
};

AlertComponent.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.any,
    className: PropTypes.string,
    btnClassName: PropTypes.string,
    onAction: PropTypes.func,
    btnPosition: PropTypes.string,
};

export default AlertComponent;
