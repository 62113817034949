import * as React from 'react';

function SvgPhone(props) {
	return (
		<svg
			width={24}
			height={24}
			viewBox="-5 -3 24 24"
			fill="none"
			{...props}
		>
			<path
				d="M7.71 14.29l-.15-.12a.762.762 0 00-.18-.09L7.2 14a1 1 0 00-.91.27 1.15 1.15 0 00-.21.33 1 1 0 001.3 1.31c.12-.058.23-.132.33-.22a1 1 0 00.21-1.09 1 1 0 00-.21-.31zM11 0H3a3 3 0 00-3 3v14a3 3 0 003 3h8a3 3 0 003-3V3a3 3 0 00-3-3zm1 17a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h8a1 1 0 011 1v14z"
				fill="#504F66"
			/>
		</svg>
	);
}

export default SvgPhone;
