import * as Yup from 'yup';

const resetForm = {
	schema: {
		oldPassword: '',
		newPassword: '',
		confirmNewPassword: '',
	},
	validationSchema: Yup.object().shape({
		oldPassword: Yup.string().required(),
		newPassword: Yup.string().required(),
		confirmNewPassword: Yup.string()
			.oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
			.required(),
	}),
};

export default resetForm;
