import React from 'react';
import PropTypes from 'prop-types';
import faqCategories from 'components/faq-company/faq-categories';
import Accordion from 'components/stateless/accordion';
import Modal from 'components/modal';
import './FaqComponent.scss';

const FaqComponent = ({isActive, onClose}) => {
    const setModalState = () => {
        onClose(!isActive);
    };

    const renderCategories = () => {
        return faqCategories.map((category, idx) => {
            return (
                <div key={idx} className="ynq-faq-container">
                    <p className="ynq-faq-category-title">{category.name}</p>
                    <Accordion data={category.items}/>
                </div>
            );
        });
    };

    const renderContactInfo = () => (
        <div className="ynq-faq-container">
            <div className="ynq-faq-contact">
                <span className="ynq-faq-contact-heading">
                    Did we miss your question?
                </span>
                <span className="ynq-faq-contact-text">
                    Please call or send us an email at{' '}
                    <a href="mailto:">hello@younique.org</a>. We{`'`}re happy to
                    answer any question you may have about partnering with{' '}
                    <span className="ynq-faq-contact-highlight">Younique</span>.
                </span>
            </div>
        </div>
    );

    return (
        <>
            {isActive && (
                <Modal closeModal={setModalState}>
                    {renderCategories()}
                    {renderContactInfo()}
                </Modal>
            )}
        </>
    );
};

FaqComponent.propTypes = {
    isActive: PropTypes.bool,
    onClose: PropTypes.func,
};

export default FaqComponent;
