import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from 'components/stateless/header';
import FooterComponent from '../footer/FooterComponent';
import ComingSoon from 'components/stateless/coming-soon';
import {Link, Redirect} from 'react-router-dom';
import Slider from 'components/stateless/slider';
import MembershipBanner from 'components/stateless/membership-banner';
import gtag from 'utils/gtag';

import {subscriptionMenu} from 'components/stateless/header-items';
import {FOOTER_CUSTOMER_CATEGORIES} from 'utils/footer-categories';
import 'components/stateless/slider.css';
import 'components/home/Home.css';
import {useAuthDataContext} from 'context/auth-context';
// import axios from 'axios';
import EmailAlertComponent from 'components/email-alert/EmailAlertComponent';

const Alliance = () => {
    const {authData} = useAuthDataContext();

    gtag.events.pageView('Alliance');

    // useEffect(() => {
    //     getEsbHotelDescriptiveInfo();
    // }, []);
    //
    // const getEsbHotelDescriptiveInfo = () => {
    //     const https = require('https');
    //     const agent = new https.Agent({
    //         rejectUnauthorized: false,
    //     });
    //
    //     const data =
    //         '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:sec="http://xml.amadeus.com/2010/06/Security_v1" xmlns:typ="http://xml.amadeus.com/2010/06/Types_v1" xmlns:iat="http://www.iata.org/IATA/2007/00/IATA2010.1" xmlns:app="http://xml.amadeus.com/2010/06/AppMdw_CommonTypes_v3" xmlns:link="http://wsdl.amadeus.com/2010/06/ws/Link_v1" xmlns:ses="http://xml.amadeus.com/2010/06/Session_v3">\n' +
    //         '<soapenv:Header/>\n' +
    //         '<soapenv:Body>\n' +
    //         '<ota:OTA_HotelDescriptiveInfoRQ EchoToken="withParsing" Version="6.001" PrimaryLangID="en" xmlns:ota="http://www.opentravel.org/OTA/2003/05">\n' +
    //         '<ota:HotelDescriptiveInfos>\n' +
    //         '<ota:HotelDescriptiveInfo HotelCode="ICBUHICA">\n' +
    //         '<ota:HotelInfo SendData="true"/>\n' +
    //         '<ota:FacilityInfo SendGuestRooms="true" SendMeetingRooms="true" SendRestaurants="true"/>\n' +
    //         '<ota:Policies SendPolicies="true"/>\n' +
    //         '<ota:AreaInfo SendAttractions="true" SendRefPoints="true" SendRecreations="true"/>\n' +
    //         '<ota:AffiliationInfo SendAwards="true" SendLoyalPrograms="false"/>\n' +
    //         '<ota:ContactInfo SendData="true"/>\n' +
    //         '<ota:MultimediaObjects SendData="true"/>\n' +
    //         '<ota:ContentInfos>\n' +
    //         '<ota:ContentInfo Name="SecureMultimediaURLs"/>\n' +
    //         '</ota:ContentInfos>\n' +
    //         '</ota:HotelDescriptiveInfo>\n' +
    //         '</ota:HotelDescriptiveInfos>\n' +
    //         '</ota:OTA_HotelDescriptiveInfoRQ>\n' +
    //         '</soapenv:Body>\n' +
    //         '</soapenv:Envelope>';
    //     const data1 = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/">
    //                     <soapenv:Header>
    //                        <awsse:Session TransactionStatusCode="Start" xmlns:awsse="http://xml.amadeus.com/2010/06/Session_v3"/>
    //                     </soapenv:Header>
    //                     <soapenv:Body>
    //                           <ns:OTA_HotelAvailRQ xmlns:ns="http://www.opentravel.org/OTA/2003/05" SearchCacheLevel="Live" EchoToken="MultiSingle" SummaryOnly="1" RateRangeOnly="1" ExactMatchOnly="1" RateDetailsInd="1" Version="4.000">
    //                              <ns:AvailRequestSegments>
    //                                 <ns:AvailRequestSegment InfoSource="Distribution">
    //                                    <ns:HotelSearchCriteria AvailableOnlyIndicator="true">
    //                                       <ns:Criterion>
    //                                          <ns:StayDateRange Start="2020-12-22" End="2020-12-25"/>
    //                                          <ns:RoomStayCandidates>
    //                                             <ns:RoomStayCandidate RoomID="1" Quantity="1">
    //                                                <ns:GuestCounts>
    //                                                   <ns:GuestCount AgeQualifyingCode="10" Count="2"/>
    //                                                </ns:GuestCounts>
    //                                             </ns:RoomStayCandidate>
    //                                          </ns:RoomStayCandidates>
    //                                       </ns:Criterion>
    //                                       <ns:Criterion ExactMatch="true">
    //                                          <ns:HotelRef HotelCode="RTPARFRA"/>
    //                                       </ns:Criterion>
    //                                    </ns:HotelSearchCriteria>
    //                                 </ns:AvailRequestSegment>
    //                              </ns:AvailRequestSegments>
    //                           </ns:OTA_HotelAvailRQ>
    //                        </soapenv:Body>
    //                     </soapenv:Envelope>`;
    //     const headers = {
    //         Accept: 'text/xml',
    //         Origin: 'http://localhost:3001',
    //         'Content-Type': 'text/xml',
    //         SOAPAction:
    //             'http://webservices.amadeus.com/Hotel_MultiSingleAvailability_10.0',
    //     };
    //
    //     axios
    //         .post(
    //             'https://io-dev.younique.intra/services/AmadeusAvailability',
    //             data1,
    //             { httpsAgent: agent, headers: headers }
    //         )
    //         .then(res => {
    //             console.log(res);
    //         });
    // };

    return (
        <>
            {authData ? (
                <Redirect to={'/4-steps-to-go'}/>
            ) : (
                <>
                    <ComingSoon/>
                    <Header
                        height={668}
                        page={'general'}
                        bk={'home-page'}
                        alliance
                        menu={subscriptionMenu}
                    >
                        <Slider/>
                    </Header>

                    <Row className="px-2 pt-5 pt-md-5 justify-content-center px-sm-5">
                        <Col
                            className="contact-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                            <Col
                                className="contact-chapter pb-1 p-lg-0 d-none d-md-block col-xl-2 col-lg-2 col-md-2 col-sm-2 col-10">
                                <div>Introduction.</div>
                            </Col>
                            <Col className="contact-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                                <div className="item-content col-sm-10">
                                    <span className="item-title">
                                        Our two dreams.
                                    </span>
                                    <span className="item-text pr-0">
                                        We are all different. We are all unique.
                                        And so are You. Our aim is to help you
                                        finding and booking hotels, which
                                        perfectly fit your unique needs and
                                        preferences. We also want to be fair
                                        …and share. You might not know, but
                                        large technology companies take a large
                                        share out of almost every online hotel
                                        booking. We want to change this and
                                        share the savings. If you want to know
                                        more, read our{' '}
                                        <Link
                                            className="linked-text"
                                            to="/manifesto-b2b"
                                        >
                                            manifesto
                                        </Link>
                                        .
                                    </span>
                                </div>
                            </Col>
                        </Col>
                    </Row>

                    <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                        <Col
                            className="contact-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                            <Col
                                className="contact-chapter pb-1 p-lg-0 d-none d-md-block col-xl-2 col-lg-2 col-md-2 col-sm-2 col-10">
                                <div>Be Fair.</div>
                            </Col>
                            <Col className="contact-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                                <div className="item-content col-sm-10">
                                    <span className="item-title">
                                        Be Younique.
                                    </span>
                                    <span className="item-text pr-0">
                                        Leave us your email and we let you when
                                        we are ready. In exchange we offer you a
                                        six months free membership (from
                                        launch), which will give you access to
                                        special discounts, free breakfast and
                                        many other amenities, from selected
                                        hotels.
                                    </span>
                                </div>
                            </Col>
                        </Col>
                    </Row>

                    {/* Footer */}
                    <FooterComponent
                        target="customer"
                        menu={FOOTER_CUSTOMER_CATEGORIES}
                    >
                        <MembershipBanner/>
                        <EmailAlertComponent/>
                    </FooterComponent>
                </>
            )}
        </>
    );
};

export default Alliance;
