import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import SelectionControlComponent from 'components/form/selection-control';
import './RadioGroup.scss';

const RadioGroupComponent = ({
                                 options,
                                 id,
                                 name,
                                 formId,
                                 className,
                                 onChange,
                                 value,
                                 isRequired,
                                 label,
                                 labelClassName,
                                 disabled,
                                 touched,
                                 valid,
                             }) => {
    const [selected, setSelected] = useState(value);

    const handleChange = e => {
        setSelected(e.target.value);
        onChange(e);
    };

    useEffect(() => {
        setSelected(value);
    }, [value]);

    const allOptions = options.map((option, idx) => (
        <SelectionControlComponent
            type="radio"
            key={idx}
            index={idx + 1}
            id={option.value + idx + 1}
            value={option.value}
            label={option.label}
            checked={selected === option.value}
            name={name}
            onChange={handleChange}
            formId={formId}
            disabled={disabled}
            touched={touched}
            valid={valid}
        />
    ));

    return (
        <div className={`ynq-radio-group ${className}`}>
            <label
                htmlFor={id}
                className={labelClassName || 'ynq-radio-group__label'}
            >
                {label}{' '}
                {isRequired && (
                    <span className="ynq-radio-group__required-asterisk">
                        *
                    </span>
                )}
            </label>
            <div className="ynq-radio-group__options">{allOptions}</div>
        </div>
    );
};

RadioGroupComponent.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })
    ).isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    formId: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    disabled: PropTypes.bool,
    touched: PropTypes.string,
    valid: PropTypes.string,
};

export default RadioGroupComponent;
