import React from 'react';
import PropTypes from 'prop-types';
import {DocumentLayoutLeft} from 'icons';
import LinkComponent from 'components/link';

const WelcomeOptionComponent = ({img, title, description, to}) => {
    return (
        <>
            <div className="ynq-welcome__option">
                <div className="ynq-welcome__option-content">
                    {img}
                    <span className="ynq-welcome__option-title">{title}</span>
                    <span className="ynq-welcome__option-description">
                        {description}
                    </span>
                </div>
                <div className="ynq-welcome__option-action">
                    <LinkComponent
                        to={to}
                        className="ynq-button ynq-button--grey-9 ynq-button--reverse"
                    >
                        <DocumentLayoutLeft/>
                        See the details
                    </LinkComponent>
                </div>
            </div>
        </>
    );
};

WelcomeOptionComponent.propTypes = {
    img: PropTypes.any,
    title: PropTypes.string,
    description: PropTypes.any,
    to: PropTypes.string,
};

export default WelcomeOptionComponent;
