import React from 'react';
import {Icon} from '@iconify/react';
import fileCheckAlt from '@iconify/icons-uil/file-check-alt';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Validate from 'utils/validator';
import Header from '../stateless/header';
import {formControls} from 'components/registration/form-controls';
import FormControl from 'utils/form-control';
import gtag from 'utils/gtag';
import Spinner from 'react-bootstrap/Spinner';
import AlertComponent from 'utils/alert/AlertComponent';
import {CheckFilled, TimesCircleFilled} from 'icons';

import FooterComponent from '../footer/FooterComponent';
import {registrationMenu} from 'components/stateless/header-items';
import {FOOTER_SUBSCRIBER_CATEGORIES} from 'utils/footer-categories';
import './RegistrationForm.css';

class RegistrationForm extends React.Component {
    constructor() {
        super();
        gtag.events.pageView('RegistrationForm');
        this.env = process.env.REACT_APP_NODE_API;
        this.formDefinition = [];
        this.getFormDefinition();
        this.fetchCountries();
		this.fetchRoles();
        this.captchaRef = React.createRef();
        this.state = {
            asyncInProgress: false,
            formIsValid: false,
            formControls: this.formDefinition,
            dataset: {
                countryList: [],
                roleList: [],
            },
            formEvent: { text: '', type: '', icon: null },
        };
    }

    getFormDefinition = () => {
        formControls.forEach(item =>
            item.controls.forEach(i => this.formDefinition.push(i))
        );
        const nrOfHotels = this.formDefinition.find(item => item.name === 'numberOfHotels');
        this.formDefinition.forEach(field => {
            if (field.name === 'registrationType' && field.value === 'CHAIN') {
                nrOfHotels.hidden = false;
            }
        })
        return this.formDefinition;
    };

    resetForm = () => {
        this.captchaRef.current.reset();
        formControls.map(item =>
            item.controls.map(i => {
                i.value = i.defaultValue || '';
                i.touched = false;
                i.valid = false;
                if (i.type === 'radio') {
                    i.defaultValue = 0;
                }
                return i;
            })
        );
    };

    fetchCountries = () => {
        fetch(`${this.env}/api/v1/meta/countries`)
            .then(response => response.json())
            .then(json => {
                this.setState(
                    Object.assign(this.state.dataset, { countryList: json })
                );
            });
    };

    fetchRoles = () => {
        fetch(`${this.env}/api/v1/registration/form/company/roles`)
            .then(response => response.json())
            .then(json => {
                this.setState(
                    Object.assign(this.state.dataset, { roleList: json })
                );
            });
    };

    changeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;

        const updatedFormElement = this.state.formControls.find(
            item => item.name === name
        );
        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = Validate(
            value,
            updatedFormElement.validationRules
        );

        this.state.formControls
            .filter(item => item.name === name)
            .map(() => updatedFormElement);

        let formIsValid = true;
        const specificRole = this.state.formControls.find(
            item => item.name === 'other'
        );
        specificRole.hidden = true;

        const nrOfHotels = this.state.formControls.find(item => item.name === 'numberOfHotels');
        nrOfHotels.hidden = true;

        this.state.formControls.forEach(control => {
            if (control && control.name === 'role' && control.value && control.value.name === 'Others (please specify)') {
                specificRole.hidden = false;
            }
            if (control && control.name === 'registrationType' && control.value && control.value === 'CHAIN') {
                nrOfHotels.hidden = false;
            }
            formIsValid = control && formIsValid;
        });

        this.setState({
            formControls: this.state.formControls,
            formIsValid: formIsValid,
        });
    };

    formSubmitHandler = e => {
        e.preventDefault();
        const formData = {};
        let formIsValid = true;

        const role = this.formDefinition.find(item => item.name === 'role');
        const type = this.formDefinition.find(item => item.name === 'registrationType');
        this.formDefinition.forEach(control => {
            control.touched = true;
            if (
                (control.name === 'other' && role && role.value.name !== 'Others (please specify)') ||
                (control.name === 'numberOfHotels' && type && type.value !== 'CHAIN')
            ) {
                control.valid = true;
                control.value = null;
            } else {
                control.valid = Validate(
                    control.value,
                    control.validationRules
                );
            }
            formData[control.name] = control.value;
            formIsValid = control.valid && formIsValid;
        });
        this.setState({
            formControls: this.formDefinition,
            formIsValid: formIsValid,
        });

        if (formIsValid) {
            const form = this.bindFormData(formData);
            this.postForm(form);
        }
    };

    bindFormData = data => {
        return {
            registrationType: data.registrationType,
            legalName: data.legalName,
            hotelOrChainName: data.hotelOrChainName,
            numberOfHotels: data.numberOfHotels,
            country: data.country.name,
            city: data.city,
            street: data.street,
            postCode: data.postCode,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email.toLowerCase(),
            roleInCompany: this.bindRoleInCompany(data.role.name, data.other),
            'g-recaptcha-response': data['g-recaptcha-response'],
        };
    };

    bindRoleInCompany = (role, otherRole) => {
        return role === 'Others (please specify)' ? otherRole : role;
    };

    postForm = formData => {
        const options = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'g-recaptcha-response': formData['g-recaptcha-response'],
            },
            body: JSON.stringify(formData),
        };

        this.setState({ asyncInProgress: true });

        fetch(`${this.env}/api/v2/registration/form/register`, options)
            .then(response => Promise.all([response.ok, response.json()]))
            .then(([ok, json]) => {
                if (ok) {
                    this.resetForm();
                    this.setState({
                        asyncInProgress: false,
                        formEvent: {
                            text: 'Message sent successfully!',
                            type: 'ynq-alert--success',
                            icon: <CheckFilled/>
                        },
                        formIsValid: false,
                        formControls: this.getFormDefinition(),
                    });
                    sessionStorage.setItem('firstname', formData.firstName);
                    sessionStorage.setItem('email', formData.email);
                    this.props.history.push('/register/password');
                } else {
                    this.resetForm();
                    this.setState({asyncInProgress: false});
                    throw new Error(json.errors[0].message);
                }
            })
            .catch(err => {
                this.captchaRef.current.reset();
                return this.setState({
                    asyncInProgress: false,
                    formEvent: {
                        text: err.message,
                        type: 'ynq-alert--error',
                        icon: <TimesCircleFilled />
                    },
                });
            });
    };

    dismiss = () => {
        this.setState({
            formEvent: { text: '', type: '', icon: null },
        });
    };

    render() {
        return (
            <>
                {/* Header */}
                <Header
                    title={'Registration Form.'}
                    height={600}
                    page={'general'}
                    bk={'register'}
                    titlePosition={'start'}
                    menu={registrationMenu}
                />

                <form>
                    <Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
                        <Col className="registration-container p-lg-0 d-none d-md-flex justify-content-end">
                            <span className="required-sign">
                                <span className="r-asterisk">*</span> Required
                            </span>
                        </Col>
                    </Row>
                    {formControls.map((control, i) => (
                        <Row
                            key={control.name}
                            className={`px-2 pt-4 ${
                                i === 0 ? 'pt-md-0' : 'pt-md-5'
                            } justify-content-center px-sm-5`}
                        >
                            <Col className="registration-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                                <Col className="registration-chapter pb-1 p-lg-0 col-xl-2 col-lg-2 col-md-10 col-sm-10 col-10">
                                    <div>{control.name}</div>
                                </Col>
                                <Col className="registration-text p-lg-0 p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                                    <FormControl
                                        rf={this.captchaRef}
                                        controls={control.controls}
                                        onClick={this.changeHandler}
                                        dataset={this.state.dataset}
                                    />
                                </Col>
                            </Col>
                        </Row>
                    ))}

                    {this.state.formEvent && this.state.formEvent.text ? (
                        <Row className="px-2 pt-4 justify-content-center px-sm-5">
                            <Col className="contact-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                                <Col className="contact-chapter pb-1 p-lg-0 col-xl-2 col-lg-2 col-md-10 col-sm-10 col-10" />
                                <Col className="contact-text p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                                    <AlertComponent
                                        className={this.state.formEvent.type}
                                        btnClassName={this.state.formEvent.btn}
                                        icon={this.state.formEvent.icon}
                                        text={this.state.formEvent.text}
                                        onAction={this.dismiss}
                                    />
                                </Col>
                            </Col>
                        </Row>
                    ) : null}

                    <Row className="px-2 pt-4 pt-md-0 justify-content-center px-sm-5">
                        <Col className="registration-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
                            <Col className="registration-chapter pb-1 p-lg-0 col-xl-2 col-lg-2 col-md-10 col-sm-10 col-10" />
                            <Col className="registration-text p-lg-0 p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
                                <button
                                    className="register-btn"
                                    onClick={this.formSubmitHandler}
                                >
                                    {this.state.asyncInProgress ? (
                                        <Spinner
                                            animation="border"
                                            variant="secondary"
                                        />
                                    ) : (
                                        <span>
                                            Submit{' '}
                                            <Icon
                                                className="buttonIcon"
                                                icon={fileCheckAlt}
                                            />
                                        </span>
                                    )}
                                </button>
                            </Col>
                        </Col>
                    </Row>
                </form>

                {/* Footer */}
                <FooterComponent target="alliance" menu={FOOTER_SUBSCRIBER_CATEGORIES}/>
            </>
        );
    }
}

export default RegistrationForm;
