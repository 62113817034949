import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LinkComponent from '../link/Link.component';
import {Logomark, LogomarkTransparent} from '../../icons';
import {SOCIAL_MEDIA} from '../../utils/footer-social-media';
import {
    COPYRIGHT_ALLIANCE_LINKS,
    COPYRIGHT_CUSTOMER_LINKS,
} from '../../utils/footer-copyright-links';

import './Footer.scss';
import FaqComponent from 'components/faq-modal/FaqComponent';

const FooterComponent = ({target, children, menu}) => {
    const [activeModal, setModalActive] = useState(false);
    const setModalStatus = state => {
        setModalActive(state);
    };

    const isAlliance = target === 'alliance';
    const isCustomer = target === 'customer';

    const copyrightLinks = isAlliance
        ? COPYRIGHT_ALLIANCE_LINKS
        : COPYRIGHT_CUSTOMER_LINKS;
    const footerContainer = classNames({'ynq-footer-membership': isCustomer});
    const logoMark = classNames({
        'ynq-footer__mark-alliance': isAlliance,
        'ynq-footer__mark-customer': isCustomer,
    });
    const copyrightContainer = classNames({
        'ynq-footer__copyright-alliance-container': isAlliance,
        'ynq-footer__copyright-customer-container': isCustomer,
    });

    const renderCategories = items => {
        return items.map((item, idx) => {
            return (
                <div
                    key={idx}
                    className={`${item.className} ynq-footer__menu-column`}
                >
                    <p className="ynq-footer__menu-title">{item.name}</p>
                    {renderCategoryItems(item.child)}
                </div>
            );
        });
    };

    const renderCategoryItems = items => {
        return items.map((item, idx) =>
            item.target === 'modal' ? (
                <span
                    key={idx}
                    className="ynq-footer__menu-item"
                    role="button"
                    tabIndex={0}
                    onClick={() => setModalStatus(true)}
                >
                    {item.name}
                </span>
            ) : (
                <LinkComponent
                    key={idx}
                    to={item.url}
                    icon={item.icon}
                    className="ynq-footer__menu-item"
                >
                    {item.name}
                </LinkComponent>
            )
        );
    };

    const renderLinks = (links, className) =>
        links.map((item, idx) => (
            <li key={`${idx}-social-link`} className={className}>
                <LinkComponent
                    to={item.url}
                    className={className}
                    icon={item.icon}
                >
                    {item.name}
                </LinkComponent>
            </li>
        ));

    return (
        <div className={`ynq-footer ${footerContainer}`}>
            <div className="ynq-footer__menu">
                <div className="col-lg-3 col-xl-3 ynq-footer__menu-logo-container">
                    <span className="ynq-footer__menu-logo">
                        Join the Revolution
                    </span>
                    {isCustomer ? <LogomarkTransparent/> : null}
                </div>
                {renderCategories(menu)}
            </div>

            {isAlliance ? (
                <div className="ynq-footer__separator-container">
                    <hr className="ynq-footer__separator"/>
                </div>
            ) : null}

            <div className={copyrightContainer}>
                <div className="ynq-footer__copyright">
                    <div className="ynq-footer__rights">
                        <div className="ynq-footer__mark">
                            <Logomark className={logoMark}/>
                            <span>© 2020 Younique. All rights reserved.</span>
                        </div>
                    </div>
                    <div className="ynq-footer__terms">
                        <ul className="ynq-footer__terms-link">
                            {renderLinks(
                                copyrightLinks,
                                'ynq-footer__copyright-link'
                            )}
                        </ul>
                        <ul className="ynq-footer__social-link">
                            {renderLinks(
                                SOCIAL_MEDIA,
                                'ynq-footer__social-icon'
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            {children}
            <FaqComponent isActive={activeModal} onClose={setModalStatus}/>
        </div>
    );
};

FooterComponent.propTypes = {
    target: PropTypes.string,
    children: PropTypes.any,
    menu: PropTypes.array,
};

export default FooterComponent;
