import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Link.scss';

const LinkComponent = ({
                           children,
                           icon,
                           title,
                           to,
                           className,
                           ariaCurrent,
                       }) => {
    const linkClasses = classNames('ynq-link', className);

    return (
        <a
            href={to}
            className={linkClasses}
            title={title}
            aria-label={ariaCurrent}
        >
            {icon}
            {children}
        </a>
    );
};

LinkComponent.propTypes = {
    children: PropTypes.any,
    icon: PropTypes.any,
    to: PropTypes.string.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
    ariaCurrent: PropTypes.string,
};

export default LinkComponent;
