import 'components/get-in-the-loop/get-in-the-loop.css';
import React from 'react';
import {Icon} from '@iconify/react';
import fileCheckAlt from '@iconify/icons-uil/file-check-alt';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner'
import Validate from 'utils/validator';
import FooterComponent from '../footer/FooterComponent';
import Header from '../stateless/header';
import {formControls} from 'components/get-in-the-loop/form-controls';
import FormControl from 'utils/form-control';
import gtag from 'utils/gtag';
import {NavLink} from 'react-router-dom';
import AlertComponent from 'utils/alert/AlertComponent';
import {CheckFilled, TimesCircleFilled} from 'icons';
import MembershipBanner from 'components/stateless/membership-banner';
import {subscriptionMenu} from 'components/stateless/header-items';
import {FOOTER_CUSTOMER_CATEGORIES} from 'utils/footer-categories';

class GetInTheLoop extends React.Component {

	constructor() {
		super();
		gtag.events.pageView('GetInTheLoop');
		this.env = process.env.REACT_APP_NODE_API;
		this.formDefinition = [];
		this.getFormDefinition();
		this.captchaRef = React.createRef();
		this.state = {
			asyncInProgress: false,
			formIsValid: false,
			formControls: this.formDefinition,
			formEvent: { text: '', type: '', icon: null },
		};
	};

	getFormDefinition = () => {
		formControls.forEach(item => item.controls.forEach(i => this.formDefinition.push(i)));
		return this.formDefinition;
	};

	resetForm = () => {
		this.captchaRef.current.reset();
		formControls.map(item => {
			return this.resetFields(item.controls);
		});
	};

	resetFields = (controls) => {
		controls.map(item => {
			if (item.type !== 'element') {
				item.type === 'checkbox' ? item.value = false : item.value = '';
				item.touched = false;
				item.valid = false;
			}
			return item;
		})
	};

	changeHandler = event => {
		const name = event.target.name;
		const value = event.target.value;

		const updatedFormElement = this.state.formControls.find(item => item.name === name);
		updatedFormElement.value = value;
		updatedFormElement.touched = true;
		updatedFormElement.valid = Validate(value, updatedFormElement.validationRules);

		this.state.formControls
			.filter(item => item.name === name)
			.map(() => updatedFormElement);

		let formIsValid = true;
		this.state.formControls.forEach(control => {
			formIsValid = control && formIsValid;
		});

		this.setState({
			formControls: this.state.formControls,
			formIsValid: formIsValid
		});
	};

	formSubmitHandler = (e) => {
		if (e) e.preventDefault();
		const formData = {};
		let formIsValid = true;

		this.state.formControls.forEach(control => {
			control.touched = true;
			control.valid = Validate(control.value, control.validationRules);
			formData[control.name] = control.value;
			formIsValid = control.valid && formIsValid;
		});
		
		this.setState({formControls: this.state.formControls, formIsValid: formIsValid});
		
		
		if (formIsValid) {
			const form = this.bindFormData(formData);
			this.postForm(form);
		}

	};

	bindFormData = (formdata) => {
		return  {
			firstName: formdata.firstName,
			lastName: formdata.lastName,
			email: formdata.email.toLowerCase(),
			'friend-email-1': formdata['friend-email-1'].toLowerCase(),
			'friend-email-2': formdata['friend-email-2'].toLowerCase(),
			'friend-email-3': formdata['friend-email-3'].toLowerCase(),
			marketingEmail: formdata.marketingEmail || false,
			marketingCustomAds: formdata.marketingCustomAds || false,
			'g-recaptcha-response': formdata['g-recaptcha-response']
		}
	};

	postForm = (formData) => {
		const friendsEmails = [];
		if (formData['friend-email-1'] && formData['friend-email-1'].length) friendsEmails.push(formData['friend-email-1']);
		if (formData['friend-email-2'] && formData['friend-email-2'].length) friendsEmails.push(formData['friend-email-2']);
		if (formData['friend-email-3'] && formData['friend-email-3'].length) friendsEmails.push(formData['friend-email-3']);

		const options = {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				'g-recaptcha-response': formData['g-recaptcha-response']
			},
			body: JSON.stringify({
				lastName: formData.lastName,
				firstName: formData.firstName,
				email: formData.email,
				friendsEmails,
				marketingEmail: formData.marketingEmail,
				marketingCustomAds: formData.marketingCustomAds
			})
		};
		this.setState({asyncInProgress: true});
		fetch(`${this.env}/api/v1/join/us/register`, options)
			.then(response => Promise.all([response.ok, response.json()]))
			.then(([ok, json]) => {
				if (ok) {
					this.resetForm();
					this.setState({
						asyncInProgress: false,
						formEvent: {
							text: "Registration form submitted successfully!",
							type: "ynq-alert--success mt-4",
							icon: <CheckFilled />},
						formControls: this.getFormDefinition()
					});
				} else {
					this.setState({asyncInProgress: false});
					throw new Error(json.errors[0].message);
				}
			})
			.catch((err) => {
				this.captchaRef.current.reset();
				return this.setState({
					formEvent: {
						text: err.message,
						type:"ynq-alert--error mt-4",
						icon: <TimesCircleFilled />},
					asyncInProgress: false
				})
			})
	};

	dismiss = () => {
		this.setState({formEvent: {text: '', type: '', icon: null}});
	}

	render() {
		const customNav = (
			<Col className="top-menu col-xl-4 col-lg-5 col-md-6 col-sm-9 col-12 text-right d-none d-sm-block">
				<NavLink className="top-link" to="/manifesto-b2b" activeClassName="active">Manifesto</NavLink>
			</Col>
		);
		return (
			<>
				{/* Header */}
				<Header
					customNav={customNav}
					title={'Get in the loop.'}
					height={600}
					page={'general'}
					bk={'get-in-the-loop'}
					titlePosition={'start'}
					menu={subscriptionMenu}
				/>

				{/* Body */}
				<form>
					<Row className="px-2 pt-4 pt-md-5 justify-content-center px-sm-5">
						<Col className="registration-container p-lg-0 d-none d-md-flex justify-content-end">
							<span className="required-sign"><span className="r-asterisk">*</span> Required</span>
						</Col>
					</Row>
					{formControls.map((control, i) =>
						<Row key={control.name}
							 className={`px-2 pt-4 ${i === 0 ? 'pt-md-0' : 'pt-md-5'} justify-content-center px-sm-5`}>
							<Col className="registration-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
								<Col className="registration-chapter pb-1 p-lg-0 col-xl-2 col-lg-2 col-md-10 col-sm-10 col-10">
									<div>{control.name}</div>
								</Col>
								<Col className="registration-text p-lg-0 p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
									<FormControl
										rf={this.captchaRef}
										controls={control.controls}
										onClick={this.changeHandler}/>
								</Col>
							</Col>
						</Row>
					)}

					<Row className="px-2 pt-4 pt-md-0 justify-content-center px-sm-5">
						<Col className="registration-container p-lg-0 d-block d-md-flex justify-content-center justify-content-md-between">
							<Col className="registration-chapter pb-1 p-lg-0 col-xl-2 col-lg-2 col-md-10 col-sm-10 col-10"/>
							<Col className="registration-text p-lg-0 p-lg-0 col-xl-6 col-lg-5 col-md-10 col-sm-9 col-10">
								{this.state.formEvent && this.state.formEvent.text ?
									<AlertComponent
										className={this.state.formEvent.type}
										btnClassName={this.state.formEvent.btn}
										icon={this.state.formEvent.icon}
										text={this.state.formEvent.text}
										onAction={this.dismiss}
									/>
								: null}
								<button className="register-btn"
										disabled={this.state.asyncInProgress}
										onClick={this.formSubmitHandler}>
									{this.state.asyncInProgress
										? <Spinner animation="border" variant="secondary"/>
										: <span>Submit<Icon className="buttonIcon" icon={fileCheckAlt}/></span>
									}
								</button>
							</Col>
						</Col>
					</Row>
				</form>

				{/* Footer */}
				<FooterComponent target="customer" menu={FOOTER_CUSTOMER_CATEGORIES}>
					<MembershipBanner/>
				</FooterComponent>
			</>
		)
	}
}

export default GetInTheLoop;
