import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const DELAY = 1500;

class Captcha extends React.Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      callback: "not fired",
      value: "[empty]",
      load: false,
      expired: "false"
    };
    this._reCaptchaRef = props.rf;
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true });
    }, DELAY);
  }

  handleChange = value => {
		this.setState({ value });
		this.props.onChange({
			target: {
				name: this.props.name,
				value
			}
		});
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: "true" });
  };

  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
  };

  render() {
    const { load } = this.state || {};
    return (
      // <div className="App">
		<>
        {load && (
          <ReCAPTCHA
            onErrored={() => this._reCaptchaRef.current.reset()}
            onExpired={() => this._reCaptchaRef.current.reset()}
            theme="light"
            ref={this._reCaptchaRef}
            sitekey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY}
            onChange={this.handleChange}
            asyncScriptOnLoad={this.asyncScriptOnLoad}
          />
        )}
        </>
      // </div>
    );
  }
}
export default Captcha;
