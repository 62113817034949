export const contactForm = [
    {
        type: 'text',
        name: 'firstName',
        value: '',
        label: 'First Name',
        placeholder: '',
        gridSizeClass: '12 px-0',
        valid: false,
        touched: false,
        hidden: false,
        required: true,
        validationRules: {
            minLength: 1,
            maxLength: 255,
            isRequired: true,
        },
    },
    {
        type: 'text',
        name: 'lastName',
        value: '',
        label: 'Last Name',
        placeholder: '',
        gridSizeClass: '12 px-0',
        valid: false,
        touched: false,
        hidden: false,
        required: true,
        validationRules: {
            minLength: 1,
            maxLength: 255,
            isRequired: true,
        },
    },
    {
        type: 'text',
        name: 'email',
        value: '',
        label: 'Email Address',
        placeholder: '',
        gridSizeClass: '12 px-0',
        valid: false,
        touched: false,
        hidden: false,
        required: true,
        validationRules: {
            maxLength: 318,
            isRequired: true,
            isEmail: true,
        },
    },
    {
        type: 'text-area',
        name: 'message',
        value: '',
        label: 'Message',
        placeholder: '',
        rows: '10',
        gridSizeClass: '12 px-0',
        valid: false,
        touched: false,
        hidden: false,
        required: true,
        validationRules: {
            minLength: 1,
            maxLength: 4000,
            isRequired: true,
        },
    },
    {
        type: 'captcha',
        name: 'g-recaptcha-response',
        hidden: false,
        value: '',
        required: true,
        validationRules: {
            minLength: 1,
            isRequired: true,
        },
        valid: false,
    },
];
