import React from 'react';
import Header from '../stateless/header';
import Stepper from './stepper/StepperComponent';
import FooterComponent from 'components/footer';
import {FOOTER_SUBSCRIBER_CATEGORIES} from 'utils/footer-categories';
import {subscriptionMenu} from 'components/stateless/header-items';
import './SubscriptionComponent.scss';

const SubscriptionComponent = () => {
    const progressSteps = [
        {
            name: 'Company Profile',
        },
    ];

    return (
        <>
            <Header
                height={128}
                page={'subscription'}
                bk={'subscription'}
                alliance
                menu={subscriptionMenu}
            />

            <Stepper steps={progressSteps}/>

            <FooterComponent
                target="alliance"
                menu={FOOTER_SUBSCRIBER_CATEGORIES}
            />
        </>
    );
};

export default SubscriptionComponent;
