export const applyForm = [
    {
        type: 'text',
        name: 'firstName',
        value: '',
        label: 'First Name',
        placeholder: '',
        gridSizeClass: '12 px-0',
        valid: false,
        touched: false,
        hidden: false,
        required: true,
        validationRules: {
            minLength: 1,
            maxLength: 255,
            isRequired: true,
        },
    },
    {
        type: 'text',
        name: 'lastName',
        value: '',
        label: 'Last Name',
        placeholder: '',
        gridSizeClass: '12 px-0',
        valid: false,
        touched: false,
        hidden: false,
        required: true,
        validationRules: {
            minLength: 1,
            maxLength: 255,
            isRequired: true,
        },
    },
    {
        type: 'text',
        name: 'email',
        value: '',
        label: 'Email Address',
        placeholder: '',
        gridSizeClass: '12 px-0',
        valid: false,
        touched: false,
        hidden: false,
        required: true,
        validationRules: {
            maxLength: 318,
            isRequired: true,
            isEmail: true,
        },
    },
    {
        type: 'text',
        name: 'phone',
        value: '',
        label: 'Phone',
        placeholder: '',
        gridSizeClass: '12 px-0',
        valid: false,
        touched: false,
        hidden: false,
        required: true,
        validationRules: {
            minLength: 1,
            maxLength: 255,
            isRequired: true,
            isPhone: true,
        },
    },
    {
        type: 'file',
        name: 'cv',
        value: '',
        gridSizeClass: '12 px-0',
        valid: false,
        touched: false,
        hidden: false,
        accept:
            'application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        minSize: 0,
        maxSize: 2097152,
    },
    {
        type: 'text',
        name: 'linkedin',
        value: '',
        label: 'LinkedIn',
        placeholder: '',
        gridSizeClass: '12 px-0',
        valid: false,
        touched: false,
        hidden: false,
        validationRules: {
            maxLength: 255,
        },
    },
    {
        type: 'text',
        name: 'site',
        value: '',
        label: 'Website',
        placeholder: '',
        gridSizeClass: '12 px-0',
        valid: false,
        touched: false,
        hidden: false,
        validationRules: {
            maxLength: 255,
            isWebsite: true,
        },
    },
    {
        type: 'captcha',
        name: 'g-recaptcha-response',
        hidden: false,
        value: '',
        required: true,
        validationRules: {
            minLength: 1,
            isRequired: true,
        },
        valid: false,
    },
];
