import * as React from 'react';

function SvgTimes(props) {
	return (
		<svg width={24} height={24} viewBox="0 0 32 32" fill="none" {...props}>
			<path
				d="M17.88 16l5.733-5.72a1.339 1.339 0 10-1.893-1.893L16 14.12l-5.72-5.733a1.339 1.339 0 10-1.893 1.893L14.12 16l-5.733 5.72a1.332 1.332 0 000 1.893 1.331 1.331 0 001.893 0L16 17.88l5.72 5.733a1.332 1.332 0 001.893 0 1.333 1.333 0 000-1.893L17.88 16z"
				fill="#504F66"
			/>
		</svg>
	);
}

export default SvgTimes;
