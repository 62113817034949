import React from 'react';

export const termsItems = [
    {
        title: 'Code of Good Practices',
        content: (
            <>
                <span className="ynq-terms__chapter-text">
                    Our mission is to empower people to experience the world, by
                    offering the world{`'`}s best places to stay and greatest
                    places and attractions to visit in the most convenient way.
                    In order to achieve this goal, we will live up to the
                    following good practices:
                </span>
                <span className="ynq-terms__chapter-text">
                    We care about you: and therefore offer our Platform and
                    customer service in 40+ languages <br/> We bring and allow
                    you to experience: 1.5m+ properties from high (high) end to
                    whatever serves your needs for your next stay in a hotel,
                    motel, hostel, B&B, etc. wherever on the planet <br/> We
                    bring and allow you to experience attractions and other Trip
                    Providers We can facilitate the payment of any (entrance)
                    fee, purchase or hire of any Trip product and service which
                    uses our payment service <br/> We help you (24/7): our
                    customer service centers are here to help you 24-7-365-40+
                    We listen to you: our Platform is the product of what YOU
                    (the users) prefer and find most convenient when using our
                    service <br/> We hear you: we show uncensored reviews (of
                    customers who have actually stayed) We promise you an
                    informative, user-friendly website that guarantees the best
                    available prices. <br/> We Price Match
                </span>
            </>
        ),
    },
    {
        title: 'Introduction TCs',
        content: (
            <>
                <span className="ynq-terms__chapter-text">
                    These terms and conditions, as may be amended from time to
                    time, apply to all our services directly or indirectly
                    (through distributors) made available online, through any
                    mobile device, by email or by telephone. By accessing,
                    browsing and using our (mobile) website or any of our
                    applications through whatever platform (hereafter
                    collectively referred to as the {`"`}Platform{`"`}) and/or
                    by completing a reservation, you acknowledge and agree to
                    have read, understood and agreed to the terms and conditions
                    set out below (including the privacy statement).
                </span>
                <span className="ynq-terms__chapter-text">
                    These pages, the content and infrastructure of these pages,
                    and the online reservation service (including the
                    facilitation of payment service) provided by us on these
                    pages and through the website are owned, operated and
                    provided by Booking.com B.V. and are provided for your
                    personal, non-commercial (B2C) use only, subject to the
                    terms and conditions set out below. The relationship that we
                    have with the Trip Providers are governed by separate terms
                    and conditions which govern the (B2B) commercial
                    relationship we have with each of these Trip Providers. Each
                    Trip Provider acts in a professional manner vis-à-vis
                    Booking.com when making its product and/or service available
                    on or through Booking.com (both for its business-to-business
                    ({`"`}B2B{`"`}) and/or business-to-consumer ({`"`}B2C{`"`})
                    relationship). Please note that Trip Providers may have,
                    declare applicable and/or require (acceptance of) – in
                    addition to the policies and fine print as disclosed on the
                    website, their own (delivery / shipping / carriage / usage)
                    terms and conditions and house rules for the use, access and
                    consummation of the Trip (which may include certain
                    disclaimers and limitations of liability).
                </span>
            </>
        ),
    },
];

export default termsItems;
