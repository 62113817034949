import * as React from 'react';

function SvgCheckCircle(props) {
    return (
        <svg width={20} height={20} viewBox="0 0 32 32" {...props}>
            <path
                d="M19.626 11.72l-5.72 5.733-2.2-2.2a1.332 1.332 0 10-1.88 1.88l3.133 3.147a1.333 1.333 0 001.88 0l6.667-6.667a1.332 1.332 0 00-.94-2.28c-.352 0-.69.139-.94.387zm-3.627-9.053a13.333 13.333 0 100 26.666 13.333 13.333 0 000-26.666zm0 24a10.666 10.666 0 110-21.333 10.666 10.666 0 010 21.333z"
                fill="#504F66"
            />
        </svg>
    );
}

export default SvgCheckCircle;
