import {getSessionParameter} from 'components/login/session';

const env = process.env.REACT_APP_NODE_API;

export const chooseProgram = (program, setAsyncInProgress, sendMessage) => {
    const token = getSessionParameter('ACCESS_TOKEN');
    const data = {
        member_program_type: program,
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    };

    fetch(`${env}/api/alliance/email/hotel_subscriber_internal`, options)
        .then(response => Promise.all([response.ok, response.json()]))
        .then(([ok, json]) => {
            if (ok) {
                setAsyncInProgress(false);
                sendMessage(true);
            } else {
                setAsyncInProgress(false);
                throw new Error(json.errors[0].message);
            }
        })
        .catch(() => {
            setAsyncInProgress(false);
        });
};
