import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

const ButtonComponent = ({
							 onClick,
							 children,
							 title,
							 type,
							 disabled,
							 icon,
							 className,
						 }) => {
	const iconText = children ? 'ynq-button--icon-text' : '';

	return (
		<button
			title={title}
			type={type}
			disabled={disabled}
			onClick={onClick}
			className={`ynq-button ${iconText} ${className}`}
		>
			{icon}
			{children}
		</button>
	);
};

ButtonComponent.defaultProps = {
	type: 'button',
	disabled: false,
};

ButtonComponent.propTypes = {
	title: PropTypes.string,
	onClick: PropTypes.func,
	type: PropTypes.oneOf(['submit', 'button', 'reset']),
	className: PropTypes.string,
	disabled: PropTypes.bool,
	icon: PropTypes.any,
	children: PropTypes.any,
};

export default ButtonComponent;
