import React from 'react';

import './coming-soon.css';

function ComingSoon(props) {
  return (
    <div className="coming-soon">
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
      <div className="coming-soon-text">COMING SOON</div>
    </div>
  )
}

ComingSoon.propTypes = {

}

export default ComingSoon;
